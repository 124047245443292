import React from 'react';
import { Link } from 'react-router-dom';
import logo from "../../assets/GLOBE_LOCKUP_LOGO-R.png";
import jacket from "../../assets/G-Xtreme_Jacket.jpg";
import gExtreme from "../../assets/g_extreme.png";
import Home from "../../toolkit/Controllers/Home";
import "./homePage.css";

const Homepage = () => {
    return (
        <Home />
    );
  };
  
  export default Homepage;