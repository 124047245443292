import React, { useContext, useState } from 'react';
import { Wrapper } from './accordion2.styles';
import { PlusOutlined, MinusOutlined,RightCircleOutlined,CaretDownFilled,CaretUpFilled } from '@ant-design/icons';
import { ArrowRightOutlined } from '@ant-design/icons';
import InputComponents from '../../../InputComponents';
import NavigationsATX from '../../../../config/athletix/navigation';
import NavigationsGX from '../../../../config/gXtreme/navigation';
import { AppContext } from '../../../../provider/provider';


const Accordion2 = ({ sections, configuration, handleSetAttribute }) => {
    let Navigations = window.location.pathname == "/gXtreme" ? NavigationsGX : NavigationsATX;
    const [selectedSection, setSelectedSection] = useState(null);

    const handleClickSelect = idx => {
      setSelectedSection(selectedSection === idx ? null : idx);
    };
      const { state, stater } = useContext(AppContext);

    const handleClickNext = () => {
      let mainMenuItem = document.getElementById('g-menu-option');
      if(mainMenuItem) {
        mainMenuItem.id = 'athletix-menu-option';
      } 
      let selected = localStorage.getItem('menuSelected');
      let gearSelected = localStorage.getItem('gearSelected');
      let gearSelectedVisited = gearSelected;
      let subMenu = state.subMenus
      if(subMenu !== 'CONFIGURATION' && subMenu !== 'LETTERING' && subMenu !== "SHARE VIA EMAIL"){
        if (gearSelectedVisited == "G Xtreme" || state.product == 'GX3' && !gearSelectedVisited) { gearSelectedVisited = 'GX3' }
        if (gearSelectedVisited == 'athletix ™'|| state.product == 'Athletix' && !gearSelectedVisited) { gearSelectedVisited = 'Athletix' }
        if (gearSelectedVisited == 'GX3 Coat' || gearSelectedVisited == "GPS Pant") { gearSelectedVisited = 'GX3' }
        if (gearSelectedVisited === 'Jacket' || gearSelectedVisited === 'Pants') { gearSelectedVisited = 'Athletix' }
        state.subMenuSelections[gearSelectedVisited][subMenu] = true;
        document.getElementById(selected).classList.add('visited');
      }
      localStorage.setItem('clickOnPrimary', 'false');
      let nextSubmenu = Navigations[selected].next;
      let nextMenu = Navigations[selected].menuOption;

      if ((gearSelected === 'Pants' && selected === 'trim-0') || (gearSelected === 'GPS Pant' && selected === 'trim-0') || (gearSelected === 'Jacket' && selected === 'other-1') || (gearSelected === 'GX3 Coat' && selected === 'other-1')) {
        nextMenu = Navigations[selected].menuOption2;
        nextSubmenu = Navigations[selected].next2;
      }
      
      document.getElementById(nextMenu).click();
      setTimeout(function () {
        document.getElementById(nextSubmenu).click();
        localStorage.setItem('menuSelected', nextSubmenu);        
      }, 0);
    };
    if (!sections || sections.length === 0) return null;
    return (
      <>
        {sections.map(
          (
            {
              label,
              selected,
              heading,
              description,
              component: Component,
              inputs,
              cssClass
            },
            i,
          ) => (
              <Wrapper key={i} selected={ selectedSection === i} >
                <div id={cssClass && cssClass} className='section-wrapper'>
                  <div className={'section-label'}><b>{label}</b></div>
                  <div>{heading}</div>
                  <div>{description}</div>
                  <div > 
                      {Component ? (
                        <Component />
                      ) : inputs && inputs.length > 0 ? (
                        inputs.map((input, j) => (
                          <InputComponents
                            key={j}
                            {...input}
                            tooltipContent={input ? input.tooltipContent ? input.tooltipContent : "" : ""}
                            inputLabel={input ? input.label : ''}
                            value={configuration?.[input ? input.attribute : '']}
                            onChange={val =>
                              handleSetAttribute({ [input ? input.attribute : '']: val })
                            }
                            cssClass={[input ? input.cssClass : '']}
                          />
                        ))
                      ) : null}
                    </div>
                </div>
              </Wrapper>
            )
        )}
        <div className='next-btn' onClick={() => handleClickNext()}><b>Next <ArrowRightOutlined /></b></div>
      </>
    );
  };
  
  export default Accordion2;