import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Label = styled.div`
    color: #00A94E;
    font-weight: bolder;
`;

export const Description = styled.div``;

export const InputWrapper = styled.div``;
