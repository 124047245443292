import React, {useEffect} from 'react';
import { Wrapper } from './textInput.styles';
import { Input } from 'antd';

const TextInput = ({ inputLabel, placeholder, value, onChange }) => {
  useEffect(() => {
    if(window.location.pathname == "/gXtreme"){
      if(inputLabel == "A Letters Text" && !value && document.querySelector(".a-letters-text") && document.querySelector(".a-letters-size") && document.querySelector(".a-letters-color")){
        document.querySelector(".a-letters-text").style.display = "block";
        document.querySelector(".a-letters-size").style.display = "none";
        document.querySelector(".a-letters-color").style.display = "none";
        document.querySelector(".row-a-archeting").style.display = "none";
      }
      else if(inputLabel == "A Letters Text" && value && document.querySelector(".a-letters-text") && document.querySelector(".a-letters-size") && document.querySelector(".a-letters-color")){
        document.querySelector(".a-letters-size").style.display = "block";
        document.querySelector(".a-letters-color").style.display = "block";
        document.querySelector(".row-a-archeting").style.display = "block";
      }
      if(inputLabel == "Letters Text" && !value){
        document.querySelector(".letters-size").style.display = "none";
        document.querySelector(".letters-color").style.display = "none";
      }
      else if(inputLabel == "Letters Text" && value){
        document.querySelector(".letters-size").style.display = "block";
        document.querySelector(".letters-color").style.display = "block";
      }
      if(inputLabel == "B Letters Text" && !value && document.querySelector(".b-letters-text") && document.querySelector(".b-letters-size") && document.querySelector(".b-letters-color")){
        document.querySelector(".b-letters-text").style.display = "block";
        document.querySelector(".b-letters-size").style.display = "none";
        document.querySelector(".b-letters-color").style.display = "none";
        document.querySelector(".row-b-archeting").style.display = "none";
      }
      else if(inputLabel == "B Letters Text" && value && document.querySelector(".b-letters-text") && document.querySelector(".b-letters-size") && document.querySelector(".b-letters-color")){
        document.querySelector(".b-letters-size").style.display = "block";
        document.querySelector(".b-letters-color").style.display = "block";
        let rowBArcheting = window.threekitApi.configurator.getConfiguration();
        if(rowBArcheting["COAT TRIM TYPE"] == "NYC" || rowBArcheting["COAT TRIM TYPE"] == "NFPA B"){
          document.querySelector(".row-b-archeting").style.display = "block";
        }else if(rowBArcheting["COAT TRIM TYPE"] !== "NYC" || rowBArcheting["COAT TRIM TYPE"] !== "NFPA B"){
          document.querySelector(".row-b-archeting").style.display = "none";
        }
      }
      if(inputLabel == "C Letters Text" && !value && document.querySelector(".c-letters-text") && document.querySelector(".c-letters-size") && document.querySelector(".c-letters-color")){
        document.querySelector(".c-letters-text").style.display = "block";
        document.querySelector(".c-letters-size").style.display = "none";
        document.querySelector(".c-letters-color").style.display = "none";
      }
      else if(inputLabel == "C Letters Text" && value && document.querySelector(".c-letters-text") && document.querySelector(".c-letters-size") && document.querySelector(".c-letters-color")){
        document.querySelector(".c-letters-size").style.display = "block";
        document.querySelector(".c-letters-color").style.display = "block";
      }

      let rowDShow= window.threekitApi.configurator.getConfiguration();
      if(rowDShow['COAT TRIM TYPE'] && rowDShow['COAT TRIM TYPE'] == 'NFPA VERT'){
        if(inputLabel == "D Letters Text" && !value && document.querySelector(".d-letters-text") && document.querySelector(".d-letters-size") && document.querySelector(".d-letters-color")){
          document.querySelector(".d-letters-text").style.display = "block";
          document.querySelector(".d-letters-size").style.display = "none";
          document.querySelector(".d-letters-color").style.display = "none";
        }
        else if(inputLabel == "D Letters Text" && value && document.querySelector(".d-letters-text") && document.querySelector(".d-letters-size") && document.querySelector(".d-letters-color")){
          document.querySelector(".d-letters-size").style.display = "block";
          document.querySelector(".d-letters-color").style.display = "block";
        }
      }else if(document.querySelector(".back-lettering") && rowDShow['COAT TRIM TYPE'] && rowDShow['COAT TRIM TYPE'] !== 'NFPA VERT'){
        document.querySelector("#d-letters-text").classList.add('show-d-not-available')
        document.querySelector("#d-letters-size").style.display = "none";
        document.querySelector("#d-letters-color").style.display = "none";

      }
      if(inputLabel == "E Letters Text" && !value && document.querySelector(".e-letters-text") && document.querySelector(".e-letters-size") && document.querySelector(".e-letters-color")){
        document.querySelector(".e-letters-text").style.display = "block";
        document.querySelector(".e-letters-size").style.display = "none";
        document.querySelector(".e-letters-color").style.display = "none";
        document.querySelector(".row-e-archeting").style.display = "none";

      }
      else if(inputLabel == "E Letters Text" && value && document.querySelector(".e-letters-text") && document.querySelector(".e-letters-size") && document.querySelector(".e-letters-color")){
        document.querySelector(".e-letters-size").style.display = "block";
        document.querySelector(".e-letters-color").style.display = "block";
        let rowEArcheting = window.threekitApi.configurator.getConfiguration();
        if(rowEArcheting["COAT TRIM TYPE"] == "NYC" || rowEArcheting["COAT TRIM TYPE"] == "NFPA B"){
          document.querySelector(".row-e-archeting").style.display = "block";
        }else if(rowEArcheting["COAT TRIM TYPE"] !== "NYC" || rowEArcheting["COAT TRIM TYPE"] !== "NFPA B"){
          document.querySelector(".row-e-archeting").style.display = "none";
        }
      }
      if(inputLabel == "F Letters Text" && !value && document.querySelector(".f-letters-text") && document.querySelector(".f-letters-size") && document.querySelector(".f-letters-color")){
        document.querySelector(".f-letters-text").style.display = "block";
        document.querySelector(".f-letters-size").style.display = "none";
        document.querySelector(".f-letters-color").style.display = "none";
      }
      else if(inputLabel == "F Letters Text" && value && document.querySelector(".f-letters-text") && document.querySelector(".f-letters-size") && document.querySelector(".f-letters-color")){
        document.querySelector(".f-letters-size").style.display = "block";
        document.querySelector(".f-letters-color").style.display = "block";
      }
    }
  })
  return (
    <Wrapper>
      <div>
        <Input
          placeholder={placeholder}
          defaultValue={value}
          value={value}
          onChange={(e) => onChange(e.target.value.toUpperCase())}
        />
      </div>
    </Wrapper>
  );
};

export default TextInput;
