export default [
    {
        label: "G Xtreme",
        sections: [
            {
                label: "Materials",
                sections: [
                    {
                        inputs: ["GEAR_VISIBILITY"]
                    },
                    {
                        inputs: ["materials"]
                    },
                    {
                        inputs: ["color"]
                    },
                    {
                        inputs: ["thermalLiner"]
                    },
                    {
                        inputs: ["moistureBarrier"]
                    }
                ]
            },
            {
                label: "Trim",
                sections: [
                    {
                        inputs: ["coat_trim_type", "coat_trim_color", "coat_trim_material"]
                    },
                    {
                        inputs: ["pants_trim_type", "pants_trim_color", "pants_trim_material"]
                    }
                ]
            }
        ]
    },
    {
        label: "jacket",
        sections: [
            {
                label: "STORMFLAP",
                sections: [
                    {
                        inputs: [
                            "JACKET_FRONT_STORMFLAP_CLOSURE",
                            "JACKET_FRONT_STORMFLAP_ZIPPER_PULL",
                            "JACKET_FRONT_HOLDER_STORMFLAP",
                            "JACKET_FRONT_HOLDER_ABOVE_TRIM"
                        ]
                    }
                ]
            },
            {
                label: "SIDE POCKETS",
                sections: [
                    {
                        inputs: [
                            "sizeAndType",
                            "outsideReinforcement",
                            "insideReinforcement"
                        ]
                    }
                ]
            },
            {
                label: "COLLAR",
                sections: [
                    {
                        inputs: ["rightTopCollar", "leftTopCollar"]
                    }
                ]
            },
            {
                label: "CHEST",
                sections: [
                    {
                        inputs: [
                            "rightChestSFPockets",
                            "rightChestSFAntennaNotch",
                            "rightChestSFMic",
                            "rightChestSFHoldersStormFlap"
                        ]
                    },
                    {
                        inputs: [
                            "leftChestSFPockets",
                            "leftChestSFAntennaNotch",
                            "leftChestSFMic",
                            "leftChestSFHoldersStormFlap"
                        ]
                    }
                  
                ]
            },
            {
                label: "FRONT",
                sections: [
                    {
                        inputs: ["rightFrontNextSFHolders"]
                    },
                    {
                        inputs: ["leftFrontNextSFHolders"]
                    }
                ]
            },
            {
                label: "SLEEVES",
                sections: [
                    {
                        inputs: ["rightFlags", "rightEmbroidery", "rightEmbroideryPadding"],
                        cssClass: ["right-patch-padding"]
                    },
                    {
                        inputs: ["leftFlags", "leftEmbroidery", "leftEmbroideryPadding"],
                        cssClass: ['left-patch-padding']
                    }
                ]
            },
            {
                label: "BACK LETTERING",
                sections: [
                    {
                        inputs: ["lettersAText", "letterASize", "lettersAColor", "rowAArcheting"]
                    },
                    {
                        inputs: ["lettersBText", "letterBSize", "lettersBColor", "rowBArcheting"]
                    },
                    {
                        inputs: ["lettersCText", "letterCSize", "lettersCColor"]
                    },
                    {
                        inputs: ["lettersDText", "letterDSize", "lettersDColor"]
                    },
                    {
                        inputs: ["lettersEText", "letterESize", "lettersEColor", , "rowEArcheting"]
                    },
                    {
                        inputs: ["lettersFText", "letterFSize", "lettersFColor"]
                    },
                    {
                        inputs: ["letterPatch"]
                    },
                    {
                        inputs: ["lettersText", "letterSize", "lettersColor"]
                    }
                ]
            },
            {
                label: "OTHER",
                sections: [
                    {
                        inputs: ["sleeveWellConfiguration"]
                    },
                    {
                        inputs: ["wristerLiner"]
                    },
                    // {
                    //     label: "Wrister (Liner) Thumb Hole Loop",
                    //     inputs: ["wristerLinerThumbHoleLoop"]
                    // },
                    {
                        inputs: ["sleeveCuffs"]
                    },
                    {
                        inputs: ["globeguard"]
                    },
                    {
                        inputs: ["neckHangerLoop"]
                    },
                    {
                        inputs: ["thermalLinerPocket"]
                    },
                    {
                        inputs: ["linerAttachmentShell"]
                    },
                    {
                        inputs: ["linerAttachmentLiner"]
                    },
                    {
                        inputs: ["drdStrap"]
                    },
                ]
            }
        ]
    },
    {
        label: "pants",
        sections: [
            {
                label: "FRONT",
                sections: [
                    {
                        inputs: [
                            
                            "PANT_FRONT_FRONTCLOSURE_BELT_LOOPS",
                            "PANT_FRONT_FRONTCLOSURE_BELT",
                            "PANT_FRONT_FRONTCLOSURE_CARABINER"
                        ]
                    }
                ]
            },
            {
                label: "SIDE POCKETS",
                sections: [
                    {
                        inputs: [
                            "PANT_SIDE_POCKETS_RIGHT_SIZE_TYPE",
                            "PANT_SIDE_POCKETS_RIGHT_INSIDE_REINFORCEMENT"
                        ]
                    },
                    {
                        inputs: [
                            "PANT_SIDE_POCKETS_LEFT_SIZE_TYPE",
                            "PANT_SIDE_POCKETS_LEFT_INSIDE_REINFORCEMENT"
                        ]
                    }
                ]
            },
            {
                label: "OTHER",
                sections: [
                    {
                        inputs: ["PANT_OTHER_PANTS_CUFF"]
                    },
                    {
                        inputs: ["PANT_OTHER_KNEES_REINFORCEMENT"]
                    },
                    {
                        inputs: ["PANT_OTHER_KNEES_PADDING_LINING"]
                    },
                    {
                        inputs: ["PANT_OTHER_GLOBEGUARD"]
                    },
                    {
                        inputs: ["PANT_OTHER_SUSPENDERS"]
                    },
                    {
                        inputs: ["PANT_OTHER_MANDATORY_CCHR"]
                    }
                ]
            }
        ]
    },
    {
        label: "summary",
        sections: [
            {
                label: "CONFIGURATION",
                sections: [
                    {
                        //label: 'Chest',
                        //inputs: ['holders','pockets','closures','reinforcements'],
                    }
                ]
            },
            {
                label: "LETTERING",
                sections: [
                    {
                        //label: 'Chest',
                        //inputs: ['holders','pockets','closures','reinforcements'],
                    }
                ]
            },
            {
                label: "SHARE VIA EMAIL",
                sections: [
                    {
                        //label: 'Chest',
                        //inputs: ['holders','pockets','closures','reinforcements'],
                    }
                ]
            },
            {
                label: "SAVE CONFIGURATION",
                sections: [
                    {
                        //label: 'Chest',
                        //inputs: ['holders','pockets','closures','reinforcements'],
                    }
                ]
            }
        ]
    }
];