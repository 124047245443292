import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;

  & > div {
    width: max-content;
    padding: 10px 25px;
    border-radius: 10px;
    position: relative;
  }
  & > div span{
    position: relative;
    left: -7px;
  }
`;

export const ButtonWrapper = styled.div`

    padding: 5px 10px;
    border-radius: 0;
    box-shadow: none;
    border: ${ props => props.disabled ? '1px solid #0075375' : 'none' };
    text-transform: uppercase;
    margin: 10px 20px 10px 0;
    font-size: 16px;
    letter-spacing: 1px; 
 
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};

    transition: all 0.3s;
    
    background: ${props =>
      props.disabled ? '#f5f5f5' : props.selected ? '#00a94e' : ''};
    color: ${props =>
      props.disabled ? 'rgba(0,0,0,.25)' : props.selected ? 'white' : 'grey'};

    ${props => props.disabled && `border-color: #d9d9d9;`}

    box-shadow: ${props => props.selected ? `none` : `0px 3px 6px 2px rgba(0, 0, 0, 0.1)`};
    transform: ${props =>
      props.selected ? 'translateY(-4px)' : 'translateY(0px)'};
`;
