import React, { useState, useEffect } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import Homepage from "./containers/homePage/homePage"
import AthletixHome from './containers/homePage/athletixHome';
import Provider from './provider/provider';
import Gxtreme from './containers/gXtreme/gXtreme';
import axios from 'axios';

const App = () => {
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState('');
  useEffect(() => {
    axios({
      method: 'GET',
      url: `${process.env.REACT_APP_BASE_URL}/whoami` || 'http://localhost:5000/whoami',
      withCredentials: true
    })
    .then(response => {
      if(response) {
          localStorage.setItem('user',JSON.stringify(response.data));
          setLoading(false);
      }
      else {
        RedirectToLogin();
      }
    })
    .catch(error => {
      console.log(error, 'Saml Error'); 
      // redirect to login
      RedirectToLogin();
    })
  })

  const RedirectToLogin = () => {
    window.location.replace(`${process.env.REACT_APP_BASE_URL}/login` || 'http://localhost:5000/login');
  }

  if (loading)
    return <p>Loading... </p>

  return (
    <Provider>
      <Router>
        <Switch>
          <Route path="/" exact={true} component={Homepage} />
          <Route exact path="/athletix" component={AthletixHome} />
          <Route exact path="/gXtreme" component={Gxtreme} />
        </Switch>
      </Router>
    </Provider>
  );
};

export default App;
