export default [
    {
        label: "athletix ™",
        sections: [
            {
                label: "Materials",
                sections: [
                    {
                        inputs: ["GEAR_VISIBILITY"]
                    },
                    {
                        inputs: ["materials"]
                    },
                    {
                        inputs: ["color"]
                    },
                    {
                        inputs: ["thermalLiner"]
                    },
                    {
                        inputs: ["moistureBarrier"]
                    }
                ]
            },
            {
                label: "Trim",
                sections: [
                    {
                        inputs: ["coat_trim_type", "coat_trim_color"]
                    },
                    {
                        inputs: ["pants_trim_type", "pants_trim_color"]
                    }
                ]
            }
        ]
    },
    {
        label: "jacket",
        sections: [
            {
                label: "STORMFLAP",
                sections: [
                    {
                        inputs: [
                            "JACKET_FRONT_STORMFLAP_CLOSURE",
                            "JACKET_FRONT_STORMFLAP_ZIPPER_PULL",
                            "JACKET_FRONT_HOLDER_STORMFLAP",
                            "JACKET_FRONT_HOLDER_ABOVE_TRIM"
                        ]
                    }
                ]
            },
            {
                label: "SIDE POCKETS",
                sections: [
                    {
                        inputs: [
                            "sizeAndType",
                            "outsideReinforcement",
                            "insideReinforcement",
                            "pullTab",
                            "rightSidePocketDivider",
                            "leftSidePocketDivider"
                        ]
                    }
                ]
            },
            {
                label: "COLLAR",
                sections: [
                    {
                        inputs: ["rightTopCollar", "leftTopCollar"]
                    }
                ]
            },
            {
                label: "CHEST",
                sections: [
                    {
                        inputs: [
                            "rightChestSFPockets",
                            "rightChestSFTrimOverPocket",
                            "rightChestSFAntennaNotch",
                            "rightChestSFMic",
                            "rightChestSFHoldersStormFlap"
                        ]
                    },
                    {
                        inputs: [
                            "leftChestSFPockets",
                            "leftChestSFTrimOverPocket",
                            "leftChestSFAntennaNotch",
                            "leftChestSFMic",
                            "leftChestSFHoldersStormFlap"
                        ]
                    }
                    // {
                    //   label: 'TWD SLEEVE',
                    //   inputs: ['rightChestSleeveHolders'],
                    // },
                ]
            },
            /*
                  {
                    label: 'LEFT CHEST',
                    sections: [
                      {
                        label: 'NEXT TO STORMFLAP',
                        inputs: ['leftChestSFPockets',
                        'leftChestSFTrimOverPocket',
                        'leftChestSFAntennaNotch', 
                        'leftChestSFMic', 
                        'leftChestSFHoldersStormFlap'],
                      },
                      // {
                      //   label: 'TWD SLEEVE',
                      //   inputs: ['leftChestSleeveHolders'],
                      // }
                    ],
                  },
                  */
            /*
                  {
                    label: 'LEFT SLEEVE',
                    sections: [
                      {
                        label: 'FLAG / CUSTOM PRINTED PATCH',
                        inputs: ['leftFlags', 'leftEmbroidery']
                      },
                      {
                        label: 'CUSTOM PRINTED PATCH PADDING',
                        inputs: ['leftEmbroideryPadding'],
                        cssClass: ['left-patch-padding']
                      }
                    ],
                  },*/
            {
                label: "FRONT",
                sections: [
                    {
                        inputs: ["rightFrontNextSFHolders"]
                    },
                    {
                        inputs: ["leftFrontNextSFHolders"]
                    }
                    // {
                    //   label: 'Other Feature (Nehind Base Pocket)',
                    //   inputs: ['rightFrontBehindBasePocketHolders'],
                    // }
                ]
            },
            {
                label: "SLEEVES",
                sections: [
                    {
                        inputs: ["rightFlags", "rightEmbroidery", "rightEmbroideryPadding"],
                        cssClass: ["right-patch-padding"]
                    },
                    {
                        inputs: ["leftFlags", "leftEmbroidery", "leftEmbroideryPadding"],
                        cssClass: ['left-patch-padding']
                    }
                ]
            },
            /*
                  {
                    label: 'LEFT FRONT',
                    sections: [
                      {
                        label: 'Other Feature (Next to Stormflap)',
                        inputs: ['leftFrontNextSFHolders'],
                      },
                      // {
                      //   label: 'Other Feature (Nehind Base Pocket)',
                      //   inputs: ['leftFrontBehindBasePocketHolders'],
                      // }
                    ],
                  },*/
            {
                label: "BACK LETTERING",
                sections: [
                    {
                        inputs: ["lettersAText", "letterASize", "lettersAColor"]
                    },
                    {
                        inputs: ["lettersBText", "letterBSize", "lettersBColor"]
                    },
                    {
                        inputs: ["lettersCText", "letterCSize", "lettersCColor"]
                    },
                    {
                        label: "ROW D LETTERING (NOT AVAILABLE)",
                        inputs: ["lettersDText"]
                    },
                    {
                        inputs: ["lettersEText", "letterESize", "lettersEColor"]
                    },
                    {
                        inputs: ["lettersFText", "letterFSize", "lettersFColor"]
                    },
                    {
                        inputs: ["letterPatch"]
                    },
                    {
                        inputs: ["lettersText", "letterSize", "lettersColor"]
                    }
                ]
            },
            {
                label: "OTHER",
                sections: [
                    {
                        inputs: ["sleeveWellConfiguration"]
                    },
                    {
                        inputs: ["wristerLiner"]
                    },
                    {
                        inputs: ["wristerLinerThumbHoleLoop"]
                    },
                    {
                        inputs: ["sleeveCuffs"]
                    },
                    {
                        inputs: ["shoulderPadding"]
                    },
                    {
                        inputs: ["globeguard"]
                    },
                    {
                        inputs: ["neckHangerLoop"]
                    },
                    {
                        inputs: ["hemSnapTabsSell"]
                    },
                    {
                        inputs: ["hemSnapTabsLiner"]
                    },
                    {
                        inputs: ["thermalLinerPocket"]
                    },
                    {
                        inputs: ["linerAttachmentShell"]
                    },
                    {
                        inputs: ["linerAttachmentLiner"]
                    },
                    {
                        inputs: ["drdStrap"]
                    },
                    {
                        inputs: ["deletePiping"]
                    }
                ]
            }
        ]
    },
    {
        label: "pants",
        sections: [
            {
                label: "FRONT",
                sections: [
                    {
                        inputs: [
                            "PANT_FRONT_FRONTCLOSURE_CLOSURE",
                            "PANT_FRONT_FRONTCLOSURE_BELT_LOOPS",
                            "PANT_FRONT_FRONTCLOSURE_BELT",
                            "PANT_FRONT_FRONTCLOSURE_CARABINER",
                            "PANT_FRONT_FRONTCLOSURE_RAPPELLING"
                        ]
                    }
                ]
            },
            {
                label: "SIDE POCKETS",
                sections: [
                    {
                        inputs: [
                            "PANT_SIDE_POCKETS_RIGHT_SIZE_TYPE",
                            "PANT_SIDE_POCKETS_RIGHT_OUTSITE_REINFORCEMENT",
                            "PANT_SIDE_POCKETS_RIGHT_INSIDE_REINFORCEMENT",
                            "PANT_SIDE_POCKETS_RIGHT_PULL_TAB",
                            "PANT_SIDE_POCKETS_RIGHT_POCKET_DIVIDER",
                            "PANT_SIDE_POCKETS_RIGHT_TOOL_COMPARTMENT"
                        ]
                    },
                    {
                        inputs: [
                            "PANT_SIDE_POCKETS_LEFT_SIZE_TYPE",
                            "PANT_SIDE_POCKETS_LEFT_OUTSIDE_REINFORCEMENT",
                            "PANT_SIDE_POCKETS_LEFT_INSIDE_REINFORCEMENT",
                            "PANT_SIDE_POCKETS_LEFT_PULL_TAB",
                            "PANT_SIDE_POCKETS_LEFT_POCKET_DIVIDER",
                            "PANT_SIDE_POCKETS_LEFT_TOOL_COMPARTMENT"
                        ]
                    }
                ]
            },
            {
                label: "OTHER",
                sections: [
                    {
                        label: "PANT CUFFS",
                        inputs: ["PANT_OTHER_PANTS_CUFF"]
                    },
                    {
                        label: "KNEES REINFORCEMENT",
                        inputs: ["PANT_OTHER_KNEES_REINFORCEMENT"]
                    },
                    {
                        label: "KNEES PADDING - LINING",
                        inputs: ["PANT_OTHER_KNEES_PADDING_LINING"]
                    },
                    {
                        label: "GLOBE GUARD",
                        inputs: ["PANT_OTHER_GLOBEGUARD"]
                    },
                    {
                        label: "SUSPENDERS",
                        inputs: ["PANT_OTHER_SUSPENDERS"]
                    },
                    {
                        label: "MANDATORY CCHR",
                        inputs: ["PANT_OTHER_MANDATORY_CCHR"]
                    }
                ]
            }
        ]
    },
    {
        label: "summary",
        sections: [
            {
                label: "CONFIGURATION",
                sections: [
                    {
                        //label: 'Chest',
                        //inputs: ['holders','pockets','closures','reinforcements'],
                    }
                ]
            },
            {
                label: "LETTERING",
                sections: [
                    {
                        //label: 'Chest',
                        //inputs: ['holders','pockets','closures','reinforcements'],
                    }
                ]
            },
            {
                label: "SHARE VIA EMAIL",
                sections: [
                    {
                        //label: 'Chest',
                        //inputs: ['holders','pockets','closures','reinforcements'],
                    }
                ]
            },
            {
                label: "SAVE CONFIGURATION",
                sections: [
                    {
                        //label: 'Chest',
                        //inputs: ['holders','pockets','closures','reinforcements'],
                    }
                ]
            }
        ]
    }
];