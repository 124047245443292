import { object } from "joi";
import React, { useState, useEffect, useContext } from "react";
import { handleAddToCart, handleInputSelect } from "../../config/eventListeners";
import toolkit from "../toolkit";
import { AppContext } from "../../provider/provider";
import { saveConfiguration, addNewConfigurationInDataTable } from '../toolkit/customServices.js';

const TemplateContainer = ({ template: Template, title, subtitle, sections, inputs, threekitConfig }) => {
    const [variantConfiguration, setVariantConfiguration] = useState(null);
    const [controller, setController] = useState([]);
    const [configuration, setConfiguration] = useState(null);
    const { state, setState } = useContext(AppContext);

    useEffect(() => {
        (() => {
            if (document.getElementById('zoom-wrapper'))
                document.getElementById('zoom-wrapper').style.display = 'none';
            toolkit.player.initializePlayer({
                el: document.getElementById("player-root"),
                ...threekitConfig,
                onPreload: async () => {
                    const templateData = await toolkit.template.prepTemplate(sections, inputs);

                    setController(templateData);
                    resetMenuItemsStyle();
                    document.getElementById('zoom-wrapper').style.display = 'block';
                }
            });
        })();
    }, []);
    const runElements = (attributeSelected, element) => {
        for (var i = 0; i < element.length; i++) {
            if (attributeSelected && element[i].innerText === attributeSelected.split(" ").join("-")) return i;
        }
        return "not found";
    };
    const resetMenuItemsStyle = () => {
        for (const menuKey in state.subMenuSelections) {
            if (state.subMenuSelections.hasOwnProperty(menuKey)) {
                let index = 0;
                for (const subMenuKey in state.subMenuSelections[menuKey]) {
                    if (state.subMenuSelections[menuKey].hasOwnProperty(subMenuKey)) {
                        if (index > 0)
                            state.subMenuSelections[menuKey][subMenuKey] = false;
                        index++;
                    }
                }
            }
        }
    }
    const handleSetAttribute = async (config, section, label) => {
        if (window.location.pathname === "/gXtreme") {
            if(config['Coat Trim Material'] == 'Brilliance w/Stripe'){
                window.threekitApi.configurator.setConfiguration({'COAT TRIM COLOR': 'Lime/Yellow'})
                state.jacketBrillianceMaterialSelected = true;
                document.querySelector('#red-orange').style.display = 'none';
            }
            else if(config['Coat Trim Material'] == 'Triple Trim' || config['Coat Trim Material'] == 'Comfort Trim'){
                state.jacketBrillianceMaterialSelected = false;
                document.querySelector('#red-orange').style.display = 'block';
            }
            if(config['Pant Trim Material'] == 'Brilliance w/Stripe'){
                state.pantBrillianceMaterialSelected = true;
                window.threekitApi.configurator.setConfiguration({'PANTS TRIM COLOR': 'Lime/Yellow'})
                document.querySelector('.pants-trim-color .inputs-container #red-orange').style.display = 'none';
            }
            else if(config['Pant Trim Material'] == 'Triple Trim' || config['Pant Trim Material'] == 'Comfort Trim'){
                state.pantBrillianceMaterialSelected = false;
                document.querySelector('.pants-trim-color .inputs-container #red-orange').style.display = 'block';
            }
            let configuration = window.threekitApi.configurator.getConfiguration()
            state.primaryMenus = label;
            if(config['THERMAL LINER'] && config['THERMAL LINER'] == 'Parallon X1M2'){
                state.thermaLinerParallonClicked = true;
                state.moistureBarrierParallonClicked = true;
                state.moistureBarrierOpt = 'Parallon B2';
                state.thermalLinerOpt = 'Parallon X1M2';
            }
            else if(config['THERMAL LINER'] && config['THERMAL LINER'] !== 'Parallon X1M2'){
                state.thermaLinerParallonClicked = false;
                state.thermalLinerOpt = config['THERMAL LINER'];
                state.moistureBarrierOpt = configuration['MOISTURE BARRIER']
    
                if(state.thermalLinerOpt == 'Parallon B2'){
                    state.thermaLinerParallonClicked = true;
                    state.moistureBarrierOpt = 'Crosstech Black';
                }
                else{
                    state.moistureBarrierParallonClicked = false;
                }
            }
            if(config['MOISTURE BARRIER'] && config['MOISTURE BARRIER'] == 'Parallon B2'){
                state.thermaLinerParallonClicked = true;
                state.moistureBarrierParallonClicked = true;
                state.thermalLinerOpt = 'Parallon X1M2';
                state.moistureBarrierOpt = 'Parallon B2';
            }
            else if(config['MOISTURE BARRIER']  && config['MOISTURE BARRIER'] !== 'Parallon X1M2'){
                if(state.thermalLinerOpt == 'Parallon X1M2'){
                    state.moistureBarrierParallonClicked = true;
                    state.thermalLinerOpt = 'Caldura Elite';
                }
                else{
                    state.moistureBarrierParallonClicked = false;
                }
                state.moistureBarrierOpt = config['MOISTURE BARRIER'];
            }
        }
        if (section !== "header") {
            document.getElementById(`${localStorage.getItem('menuSelected')}`).classList.add("selected")
        }
        var checkConfigIfObject = Object.keys(config)[0];


        if (typeof config[checkConfigIfObject] !== "string") {
            var key = Object.entries(checkConfigIfObject);
            config = { key: config[checkConfigIfObject].value };
        }

        if (!localStorage.getItem("gearSelected")) {
            localStorage.setItem("gearSelected", window.location.pathname == "/athletix" ? "Athletix" : "GX3");
        }

        if (config["GEAR_VISIBILITY"] && localStorage.getItem("isClickEvent") === "false") {
            if (window.location.pathname == "/gXtreme") {
                let gX3_Gear =
                    config["GEAR_VISIBILITY"] === "G Xtreme" ? "GX3"
                        : config["GEAR_VISIBILITY"] === "jacket" ? "GX3 Coat"
                            : config["GEAR_VISIBILITY"] === "pants" ?
                                "GPS Pant" : config["GEAR_VISIBILITY"];
                config["GEAR_VISIBILITY"] = gX3_Gear;
            } else {
                config["GEAR_VISIBILITY"] =
                    config["GEAR_VISIBILITY"].charAt(0).toUpperCase() + config["GEAR_VISIBILITY"].slice(1).split(" ")[0];
            }
            if (
                config["GEAR_VISIBILITY"].toLowerCase() === "athletix" ||
                config["GEAR_VISIBILITY"].toLowerCase() === "summary" ||
                config["GEAR_VISIBILITY"].toLowerCase() === "gx3") {
                config["GEAR_VISIBILITY"] = localStorage.getItem("gearSelected");
            }
        } else if (config["GEAR_VISIBILITY"]) {
            config["GEAR_VISIBILITY"] = localStorage.getItem("gearSelected");
        }


        if (config["RIGHT FLAGS"] && config["RIGHT FLAGS"] !== "None") {
            await toolkit.player.setConfiguration({ "RIGHT EMBROIDERY PATCHES": "None" });
            await toolkit.player.setConfiguration({ "LEFT FLAGS": "None" });
            await toolkit.player.setConfiguration({ "RIGHT EMBROIDERY PADDING": "None" });
            setLeftFlag();
            setRightPatch();
        }

        if (config["LEFT FLAGS"] && config["LEFT FLAGS"] !== "None") {
            await toolkit.player.setConfiguration({ "LEFT EMBROIDERY PATCHES": "None" });
            await toolkit.player.setConfiguration({ "RIGHT FLAGS": "None" });
            await toolkit.player.setConfiguration({ "LEFT EMBROIDERY PADDING": "None" });
            setRightFlag();
            setLeftPatch();
        }

        if (config["RIGHT EMBROIDERY PATCHES"] && config["RIGHT EMBROIDERY PATCHES"] !== "None") {
            await toolkit.player.setConfiguration({ "RIGHT FLAGS": "None" });
            await toolkit.player.setConfiguration({ "LEFT EMBROIDERY PATCHES": "None" });

            await toolkit.player.setConfiguration({ "RIGHT EMBROIDERY PADDING": "RSLV CPPS" });
            await toolkit.player.setConfiguration({ "LEFT EMBROIDERY PADDING": "None" });
            setRightFlag();
            setLeftPatch();
        }

        if (config["LEFT EMBROIDERY PATCHES"] && config["LEFT EMBROIDERY PATCHES"] !== "None") {
            await toolkit.player.setConfiguration({ "LEFT FLAGS": "None" });
            await toolkit.player.setConfiguration({ "RIGHT EMBROIDERY PATCHES": "None" });

            await toolkit.player.setConfiguration({ "LEFT EMBROIDERY PADDING": "LSLV CPPS" });
            await toolkit.player.setConfiguration({ "RIGHT EMBROIDERY PADDING": "None" });
            setLeftFlag();
            setRightPatch();
        }
        if (window.location.pathname !== "/gXtreme" && config["PANT_SIDE_POCKETS_RIGHT_SIZE_TYPE"] && config["PANT_SIDE_POCKETS_RIGHT_SIZE_TYPE"] === "SLNT EXPANSION POCKET 2x10x8/11") {
            await toolkit.player.setConfiguration({ "PANT_SIDE_POCKETS_RIGHT_OUTSITE_REINFORCEMENT": "NONE" });
            await toolkit.player.setConfiguration({ "PANT_SIDE_POCKETS_RIGHT_INSIDE_REINFORCEMENT": "KEV POUCH EXPANSION SLANT POCKET" });
            await toolkit.player.setConfiguration({ "PANT_SIDE_POCKETS_RIGHT_PULL_TAB": "BLK ARASHIELD POCKET PULL TAB" });
        }
        if (window.location.pathname !== "/gXtreme" && config["PANT_SIDE_POCKETS_LEFT_SIZE_TYPE"] && config["PANT_SIDE_POCKETS_LEFT_SIZE_TYPE"] === "SLNT EXPANSION POCKET 2x10x8/11") {
            await toolkit.player.setConfiguration({ "PANT_SIDE_POCKETS_LEFT_OUTSIDE_REINFORCEMENT": "NONE" });
            await toolkit.player.setConfiguration({ "PANT_SIDE_POCKETS_LEFT_INSIDE_REINFORCEMENT": "KEV POUCH EXPANSION SLANT POCKET" });
            await toolkit.player.setConfiguration({ "PANT_SIDE_POCKETS_LEFT_PULL_TAB": "BLK ARASHIELD POCKET PULL TAB" });
        }

        if (window.location.pathname !== "/gXtreme" && config["PANT_SIDE_POCKETS_RIGHT_SIZE_TYPE"] && config["PANT_SIDE_POCKETS_RIGHT_SIZE_TYPE"] === "ESCAPE SYSTEM POCKET CMC 2x8x9 RPKT") {
            await toolkit.player.setConfiguration({ "PANT_SIDE_POCKETS_RIGHT_OUTSITE_REINFORCEMENT": "NONE" });
            await toolkit.player.setConfiguration({ "PANT_SIDE_POCKETS_RIGHT_INSIDE_REINFORCEMENT": "KEV POUCH 2x8x9" });
            await toolkit.player.setConfiguration({ "PANT_SIDE_POCKETS_RIGHT_POCKET_DIVIDER": "NONE" });
            await toolkit.player.setConfiguration({ "PANT_SIDE_POCKETS_RIGHT_TOOL_COMPARTMENT": "NONE" });
            await toolkit.player.setConfiguration({ "PANT_SIDE_POCKETS_RIGHT_PULL_TAB": "NONE" });
        }

        if (window.location.pathname !== "/gXtreme" && config["PANT_SIDE_POCKETS_LEFT_SIZE_TYPE"] && config["PANT_SIDE_POCKETS_LEFT_SIZE_TYPE"] === "ESCAPE SYSTEM POCKET CMC 2X8X9 LPKT") {
            await toolkit.player.setConfiguration({ "PANT_SIDE_POCKETS_LEFT_OUTSIDE_REINFORCEMENT": "NONE" });
            await toolkit.player.setConfiguration({ "PANT_SIDE_POCKETS_LEFT_INSIDE_REINFORCEMENT": "KEV POUCH 2x8x9" });
            await toolkit.player.setConfiguration({ "PANT_SIDE_POCKETS_LEFT_POCKET_DIVIDER": "NONE" });
            await toolkit.player.setConfiguration({ "PANT_SIDE_POCKETS_LEFT_TOOL_COMPARTMENT": "NONE" });
            await toolkit.player.setConfiguration({ "PANT_SIDE_POCKETS_LEFT_PULL_TAB": "NONE" });
        }

        if (window.location.pathname !== "/gXtreme" && config["PANT_SIDE_POCKETS_RIGHT_SIZE_TYPE"] && config["PANT_SIDE_POCKETS_RIGHT_SIZE_TYPE"] === "ESCAPE SYSTEM POCKET CROSBY 2X7X8 RPKT") {
            await toolkit.player.setConfiguration({ "PANT_SIDE_POCKETS_RIGHT_OUTSITE_REINFORCEMENT": "NONE" });
            await toolkit.player.setConfiguration({ "PANT_SIDE_POCKETS_RIGHT_INSIDE_REINFORCEMENT": "KEV POUCH 2x7x8" });
        }

        if (window.location.pathname !== "/gXtreme" && config["PANT_SIDE_POCKETS_LEFT_SIZE_TYPE"] && config["PANT_SIDE_POCKETS_LEFT_SIZE_TYPE"] === "ESCAPE SYSTEM POCKET CROSBY 2X7X8 LPKT") {
            await toolkit.player.setConfiguration({ "PANT_SIDE_POCKETS_LEFT_OUTSIDE_REINFORCEMENT": "NONE" });
            await toolkit.player.setConfiguration({ "PANT_SIDE_POCKETS_LEFT_INSIDE_REINFORCEMENT": "KEV POUCH 2x7x8" });
        }

        if (config["PANT_SIDE_POCKETS_RIGHT_OUTSITE_REINFORCEMENT"] &&
            config["PANT_SIDE_POCKETS_RIGHT_OUTSITE_REINFORCEMENT"] === "DRAGONHIDE FRONT EXPANSION 2X10X8/11") {
            if (document.querySelector(".pants .outside-reinforcement .inputs-container")) {
                document.querySelector(".pants .outside-reinforcement .inputs-container").children[0].classList.remove("eRMacW");
                document.querySelector(".pants .outside-reinforcement .inputs-container").children[0].classList.add("boFTdt");

                document.querySelector(".pants .outside-reinforcement .inputs-container").children[0].classList.remove("bhrtsi");
                document.querySelector(".pants .outside-reinforcement .inputs-container").children[0].classList.add("bRtpvt");
            }
        }

        if (config["PANT_SIDE_POCKETS_LEFT_OUTSIDE_REINFORCEMENT"] &&
            config["PANT_SIDE_POCKETS_LEFT_OUTSIDE_REINFORCEMENT"] === "DRAGONHIDE FRONT EXPANSION 2X10X8/11") {
            if (document.querySelectorAll(".pants .outside-reinforcement .inputs-container")[0]) {
                document.querySelectorAll(".pants .outside-reinforcement .inputs-container")[0].children[0].classList.remove("eRMacW");
                document.querySelectorAll(".pants .outside-reinforcement .inputs-container")[0].children[0].classList.add("boFTdt");
            }
        }

        if (config["HEM SNAP TABS (SHELL)"] && config["HEM SNAP TABS (SHELL)"] === "HEM SNAP TABS ATX (2)") {
            //eRMacW => bhrtsi
            //boFTdt => bRtpvt
            document.querySelector(".hem-snap-tabs-shell .inputs-container #hem-snap-tabs-atx-2").classList.add('eRMacW');
            document.querySelector(".hem-snap-tabs-shell .inputs-container #hem-snap-tabs-atx-2").classList.remove('boFTdt');
            document.querySelector(".hem-snap-tabs-shell .inputs-container #hem-snap-tabs-3-shell").classList.add("boFTdt")
            document.querySelector(".hem-snap-tabs-shell .inputs-container #hem-snap-tabs-3-shell").classList.remove('eRMacW');

            document.querySelector(".hem-snap-tabs-liner .inputs-container #hem-snap-tabs-atx-2-liner").classList.add('eRMacW');
            document.querySelector(".hem-snap-tabs-liner .inputs-container #hem-snap-tabs-atx-2-liner").classList.remove('boFTdt');
            document.querySelector(".hem-snap-tabs-liner .inputs-container #hem-snap-tabs-3-liner").classList.add("boFTdt")
            document.querySelector(".hem-snap-tabs-liner .inputs-container #hem-snap-tabs-3-liner").classList.remove('eRMacW');


            document.querySelector(".hem-snap-tabs-shell .inputs-container #hem-snap-tabs-atx-2").classList.add('bhrtsi');
            document.querySelector(".hem-snap-tabs-shell .inputs-container #hem-snap-tabs-atx-2").classList.remove('bRtpvt');
            document.querySelector(".hem-snap-tabs-shell .inputs-container #hem-snap-tabs-3-shell").classList.add("bRtpvt")
            document.querySelector(".hem-snap-tabs-shell .inputs-container #hem-snap-tabs-3-shell").classList.remove('bhrtsi');

            document.querySelector(".hem-snap-tabs-liner .inputs-container #hem-snap-tabs-atx-2-liner").classList.add('bhrtsi');
            document.querySelector(".hem-snap-tabs-liner .inputs-container #hem-snap-tabs-atx-2-liner").classList.remove('bRtpvt');
            document.querySelector(".hem-snap-tabs-liner .inputs-container #hem-snap-tabs-3-liner").classList.add("bRtpvt")
            document.querySelector(".hem-snap-tabs-liner .inputs-container #hem-snap-tabs-3-liner").classList.remove('bhrtsi');

            await toolkit.player.setConfiguration({ "HEM SNAP TABS (LINER)": "HEM SNAP TABS ATX (2) LINER" });
        }

        if (config["HEM SNAP TABS (SHELL)"] && config["HEM SNAP TABS (SHELL)"] === "HEM SNAP TABS (3) SHELL") {
            //eRMacW => bhrtsi
            //boFTdt => bRtpvt
            document.querySelector(".hem-snap-tabs-shell .inputs-container #hem-snap-tabs-atx-2").classList.add('boFTdt');
            document.querySelector(".hem-snap-tabs-shell .inputs-container #hem-snap-tabs-atx-2").classList.remove('eRMacW');
            document.querySelector(".hem-snap-tabs-shell .inputs-container #hem-snap-tabs-3-shell").classList.add("eRMacW")
            document.querySelector(".hem-snap-tabs-shell .inputs-container #hem-snap-tabs-3-shell").classList.remove('boFTdt');

            document.querySelector(".hem-snap-tabs-liner .inputs-container #hem-snap-tabs-atx-2-liner").classList.add('boFTdt');
            document.querySelector(".hem-snap-tabs-liner .inputs-container #hem-snap-tabs-atx-2-liner").classList.remove('eRMacW');
            document.querySelector(".hem-snap-tabs-liner .inputs-container #hem-snap-tabs-3-liner").classList.add("eRMacW")
            document.querySelector(".hem-snap-tabs-liner .inputs-container #hem-snap-tabs-3-liner").classList.remove('boFTdt');


            document.querySelector(".hem-snap-tabs-shell .inputs-container #hem-snap-tabs-atx-2").classList.add('bRtpvt');
            document.querySelector(".hem-snap-tabs-shell .inputs-container #hem-snap-tabs-atx-2").classList.remove('bhrtsi');
            document.querySelector(".hem-snap-tabs-shell .inputs-container #hem-snap-tabs-3-shell").classList.add("bhrtsi")
            document.querySelector(".hem-snap-tabs-shell .inputs-container #hem-snap-tabs-3-shell").classList.remove('bRtpvt');

            document.querySelector(".hem-snap-tabs-liner .inputs-container #hem-snap-tabs-atx-2-liner").classList.add('bRtpvt');
            document.querySelector(".hem-snap-tabs-liner .inputs-container #hem-snap-tabs-atx-2-liner").classList.remove('bhrtsi');
            document.querySelector(".hem-snap-tabs-liner .inputs-container #hem-snap-tabs-3-liner").classList.add("bhrtsi")
            document.querySelector(".hem-snap-tabs-liner .inputs-container #hem-snap-tabs-3-liner").classList.remove('bRtpvt');
            await toolkit.player.setConfiguration({ "HEM SNAP TABS (LINER)": "HEM SNAP TABS (3) LINER" });
        }

        if (config["HEM SNAP TABS (LINER)"] && config["HEM SNAP TABS (LINER)"] === "HEM SNAP TABS ATX (2) LINER") {
            //eRMacW => bhrtsi
            //boFTdt => bRtpvt
            document.querySelector(".hem-snap-tabs-shell .inputs-container #hem-snap-tabs-atx-2").classList.add('eRMacW');
            document.querySelector(".hem-snap-tabs-shell .inputs-container #hem-snap-tabs-atx-2").classList.remove('boFTdt');
            document.querySelector(".hem-snap-tabs-shell .inputs-container #hem-snap-tabs-3-shell").classList.add("boFTdt")
            document.querySelector(".hem-snap-tabs-shell .inputs-container #hem-snap-tabs-3-shell").classList.remove('eRMacW');

            document.querySelector(".hem-snap-tabs-liner .inputs-container #hem-snap-tabs-atx-2-liner").classList.add('eRMacW');
            document.querySelector(".hem-snap-tabs-liner .inputs-container #hem-snap-tabs-atx-2-liner").classList.remove('boFTdt');
            document.querySelector(".hem-snap-tabs-liner .inputs-container #hem-snap-tabs-3-liner").classList.add("boFTdt")
            document.querySelector(".hem-snap-tabs-liner .inputs-container #hem-snap-tabs-3-liner").classList.remove('eRMacW');


            document.querySelector(".hem-snap-tabs-shell .inputs-container #hem-snap-tabs-atx-2").classList.add('bhrtsi');
            document.querySelector(".hem-snap-tabs-shell .inputs-container #hem-snap-tabs-atx-2").classList.remove('bRtpvt');
            document.querySelector(".hem-snap-tabs-shell .inputs-container #hem-snap-tabs-3-shell").classList.add("bRtpvt")
            document.querySelector(".hem-snap-tabs-shell .inputs-container #hem-snap-tabs-3-shell").classList.remove('bhrtsi');

            document.querySelector(".hem-snap-tabs-liner .inputs-container #hem-snap-tabs-atx-2-liner").classList.add('bhrtsi');
            document.querySelector(".hem-snap-tabs-liner .inputs-container #hem-snap-tabs-atx-2-liner").classList.remove('bRtpvt');
            document.querySelector(".hem-snap-tabs-liner .inputs-container #hem-snap-tabs-3-liner").classList.add("bRtpvt")
            document.querySelector(".hem-snap-tabs-liner .inputs-container #hem-snap-tabs-3-liner").classList.remove('bhrtsi');

            await toolkit.player.setConfiguration({ "HEM SNAP TABS (SHELL)": "HEM SNAP TABS ATX (2)" });
        }

        if (config["HEM SNAP TABS (LINER)"] && config["HEM SNAP TABS (LINER)"] === "HEM SNAP TABS (3) LINER") {
            //eRMacW => bhrtsi
            //boFTdt => bRtpvt
            document.querySelector(".hem-snap-tabs-shell .inputs-container #hem-snap-tabs-atx-2").classList.add('boFTdt');
            document.querySelector(".hem-snap-tabs-shell .inputs-container #hem-snap-tabs-atx-2").classList.remove('eRMacW');
            document.querySelector(".hem-snap-tabs-shell .inputs-container #hem-snap-tabs-3-shell").classList.add("eRMacW")
            document.querySelector(".hem-snap-tabs-shell .inputs-container #hem-snap-tabs-3-shell").classList.remove('boFTdt');

            document.querySelector(".hem-snap-tabs-liner .inputs-container #hem-snap-tabs-atx-2-liner").classList.add('boFTdt');
            document.querySelector(".hem-snap-tabs-liner .inputs-container #hem-snap-tabs-atx-2-liner").classList.remove('eRMacW');
            document.querySelector(".hem-snap-tabs-liner .inputs-container #hem-snap-tabs-3-liner").classList.add("eRMacW")
            document.querySelector(".hem-snap-tabs-liner .inputs-container #hem-snap-tabs-3-liner").classList.remove('boFTdt');


            document.querySelector(".hem-snap-tabs-shell .inputs-container #hem-snap-tabs-atx-2").classList.add('bRtpvt');
            document.querySelector(".hem-snap-tabs-shell .inputs-container #hem-snap-tabs-atx-2").classList.remove('bhrtsi');
            document.querySelector(".hem-snap-tabs-shell .inputs-container #hem-snap-tabs-3-shell").classList.add("bhrtsi")
            document.querySelector(".hem-snap-tabs-shell .inputs-container #hem-snap-tabs-3-shell").classList.remove('bRtpvt');

            document.querySelector(".hem-snap-tabs-liner .inputs-container #hem-snap-tabs-atx-2-liner").classList.add('bRtpvt');
            document.querySelector(".hem-snap-tabs-liner .inputs-container #hem-snap-tabs-atx-2-liner").classList.remove('bhrtsi');
            document.querySelector(".hem-snap-tabs-liner .inputs-container #hem-snap-tabs-3-liner").classList.add("bhrtsi")
            document.querySelector(".hem-snap-tabs-liner .inputs-container #hem-snap-tabs-3-liner").classList.remove('bRtpvt');

            await toolkit.player.setConfiguration({ "HEM SNAP TABS (SHELL)": "HEM SNAP TABS (3) SHELL" });
        }

        if (config["PANT_SIDE_POCKETS_RIGHT_POCKET_DIVIDER"] && config["PANT_SIDE_POCKETS_RIGHT_POCKET_DIVIDER"] === 'DIVIDER KEVLAR EXP/SEMI 9" HIGH') {
            document.querySelector(".pocket-divider-right .inputs-container #none").classList.add('boFTdt');
            document.querySelector(".pocket-divider-right .inputs-container #none").classList.remove('eRMacW');
            document.querySelector('.pocket-divider-right .inputs-container #divider-kevlar-exp-semi-9-high').classList.add('eRMacW');
            document.querySelector('.pocket-divider-right .inputs-container #divider-kevlar-exp-semi-9-high').classList.remove('boFTdt');

            document.querySelector(".pocket-divider-right .inputs-container #none").classList.add('bRtpvt');
            document.querySelector(".pocket-divider-right .inputs-container #none").classList.remove('bhrtsi');
            document.querySelector('.pocket-divider-right .inputs-container #divider-kevlar-exp-semi-9-high').classList.add('bhrtsi');
            document.querySelector('.pocket-divider-right .inputs-container #divider-kevlar-exp-semi-9-high').classList.remove('bRtpvt');

            document.querySelector(".tool-compartment-right .inputs-container #none").classList.add('eRMacW');
            document.querySelector(".tool-compartment-right .inputs-container #none").classList.remove('boFTdt');
            document.querySelector(".tool-compartment-right .inputs-container #arashield-tool-6-compartment-2x10x10").classList.add('boFTdt');
            document.querySelector(".tool-compartment-right .inputs-container #arashield-tool-6-compartment-2x10x10").classList.remove('eRMacW');

            document.querySelector(".tool-compartment-right .inputs-container #none").classList.add('bhrtsi');
            document.querySelector(".tool-compartment-right .inputs-container #none").classList.remove('bRtpvt');
            document.querySelector(".tool-compartment-right .inputs-container #arashield-tool-6-compartment-2x10x10").classList.add('bRtpvt');
            document.querySelector(".tool-compartment-right .inputs-container #arashield-tool-6-compartment-2x10x10").classList.remove('bhrtsi');

            await toolkit.player.setConfiguration({ "PANT_SIDE_POCKETS_RIGHT_TOOL_COMPARTMENT": "NONE" });
        }

        if (config["PANT_SIDE_POCKETS_RIGHT_TOOL_COMPARTMENT"] && config["PANT_SIDE_POCKETS_RIGHT_TOOL_COMPARTMENT"] === "ARASHIELD TOOL 6 COMPARTMENT 2X10X10") {
            document.querySelector(".tool-compartment-right .inputs-container #none").classList.add('boFTdt');
            document.querySelector(".tool-compartment-right .inputs-container #none").classList.remove('eRMacW');
            document.querySelector(".tool-compartment-right .inputs-container #arashield-tool-6-compartment-2x10x10").classList.add('eRMacW');
            document.querySelector(".tool-compartment-right .inputs-container #arashield-tool-6-compartment-2x10x10").classList.remove('boFTdt');

            document.querySelector(".tool-compartment-right .inputs-container #none").classList.add('bRtpvt');
            document.querySelector(".tool-compartment-right .inputs-container #none").classList.remove('bhrtsi');
            document.querySelector(".tool-compartment-right .inputs-container #arashield-tool-6-compartment-2x10x10").classList.add('bhrtsi');
            document.querySelector(".tool-compartment-right .inputs-container #arashield-tool-6-compartment-2x10x10").classList.remove('bRtpvt');

            document.querySelector(".pocket-divider-right .inputs-container #none").classList.add('eRMacW');
            document.querySelector(".pocket-divider-right .inputs-container #none").classList.remove('boFTdt');
            document.querySelector('.pocket-divider-right .inputs-container #divider-kevlar-exp-semi-9-high').classList.add('boFTdt');
            document.querySelector('.pocket-divider-right .inputs-container #divider-kevlar-exp-semi-9-high').classList.remove('eRMacW');

            document.querySelector(".pocket-divider-right .inputs-container #none").classList.add('bhrtsi');
            document.querySelector(".pocket-divider-right .inputs-container #none").classList.remove('bRtpvt');
            document.querySelector('.pocket-divider-right .inputs-container #divider-kevlar-exp-semi-9-high').classList.add('bRtpvt');
            document.querySelector('.pocket-divider-right .inputs-container #divider-kevlar-exp-semi-9-high').classList.remove('bhrtsi');

            await toolkit.player.setConfiguration({ "PANT_SIDE_POCKETS_RIGHT_POCKET_DIVIDER": "NONE" });
        }

        if (config["PANT_SIDE_POCKETS_LEFT_POCKET_DIVIDER"] && config["PANT_SIDE_POCKETS_LEFT_POCKET_DIVIDER"] === 'DIVIDER KEVLAR EXP/SEMI 9" HIGH') {
            document.querySelector(".pocket-divider-left .inputs-container #none").classList.add('boFTdt');
            document.querySelector(".pocket-divider-left .inputs-container #none").classList.remove('eRMacW');
            document.querySelector('.pocket-divider-left .inputs-container #divider-kevlar-exp-semi-9-high').classList.add('eRMacW');
            document.querySelector('.pocket-divider-left .inputs-container #divider-kevlar-exp-semi-9-high').classList.remove('boFTdt');

            document.querySelector(".pocket-divider-left .inputs-container #none").classList.add('bRtpvt');
            document.querySelector(".pocket-divider-left .inputs-container #none").classList.remove('bhrtsi');
            document.querySelector('.pocket-divider-left .inputs-container #divider-kevlar-exp-semi-9-high').classList.add('bhrtsi');
            document.querySelector('.pocket-divider-left .inputs-container #divider-kevlar-exp-semi-9-high').classList.remove('bRtpvt');

            document.querySelector(".tool-compartment-left .inputs-container #none").classList.add('eRMacW');
            document.querySelector(".tool-compartment-left .inputs-container #none").classList.remove('boFTdt');
            document.querySelector(".tool-compartment-left .inputs-container #arashield-tool-6-compartment-2x10x10").classList.add('boFTdt');
            document.querySelector(".tool-compartment-left .inputs-container #arashield-tool-6-compartment-2x10x10").classList.remove('eRMacW');

            document.querySelector(".tool-compartment-left .inputs-container #none").classList.add('bhrtsi');
            document.querySelector(".tool-compartment-left .inputs-container #none").classList.remove('bRtpvt');
            document.querySelector(".tool-compartment-left .inputs-container #arashield-tool-6-compartment-2x10x10").classList.add('bRtpvt');
            document.querySelector(".tool-compartment-left .inputs-container #arashield-tool-6-compartment-2x10x10").classList.remove('bhrtsi');

            await toolkit.player.setConfiguration({ "PANT_SIDE_POCKETS_LEFT_TOOL_COMPARTMENT": "NONE" });
        }

        if (config["PANT_SIDE_POCKETS_LEFT_TOOL_COMPARTMENT"] && config["PANT_SIDE_POCKETS_LEFT_TOOL_COMPARTMENT"] === "ARASHIELD TOOL 6 COMPARTMENT 2X10X10") {
            document.querySelector(".tool-compartment-left .inputs-container #none").classList.add('boFTdt');
            document.querySelector(".tool-compartment-left .inputs-container #none").classList.remove('eRMacW');
            document.querySelector(".tool-compartment-left .inputs-container #arashield-tool-6-compartment-2x10x10").classList.add('eRMacW');
            document.querySelector(".tool-compartment-left .inputs-container #arashield-tool-6-compartment-2x10x10").classList.remove('boFTdt');

            document.querySelector(".tool-compartment-left .inputs-container #none").classList.add('bRtpvt');
            document.querySelector(".tool-compartment-left .inputs-container #none").classList.remove('bhrtsi');
            document.querySelector(".tool-compartment-left .inputs-container #arashield-tool-6-compartment-2x10x10").classList.add('bhrtsi');
            document.querySelector(".tool-compartment-left .inputs-container #arashield-tool-6-compartment-2x10x10").classList.remove('bRtpvt');

            document.querySelector(".pocket-divider-left .inputs-container #none").classList.add('eRMacW');
            document.querySelector(".pocket-divider-left .inputs-container #none").classList.remove('boFTdt');
            document.querySelector('.pocket-divider-left .inputs-container #divider-kevlar-exp-semi-9-high').classList.add('boFTdt');
            document.querySelector('.pocket-divider-left .inputs-container #divider-kevlar-exp-semi-9-high').classList.remove('eRMacW');

            document.querySelector(".pocket-divider-left .inputs-container #none").classList.add('bhrtsi');
            document.querySelector(".pocket-divider-left .inputs-container #none").classList.remove('bRtpvt');
            document.querySelector('.pocket-divider-left .inputs-container #divider-kevlar-exp-semi-9-high').classList.add('bRtpvt');
            document.querySelector('.pocket-divider-left .inputs-container #divider-kevlar-exp-semi-9-high').classList.remove('bhrtsi');

            await toolkit.player.setConfiguration({ "PANT_SIDE_POCKETS_LEFT_POCKET_DIVIDER": "NONE" });
        }

        if (config["RIGHT POCKETS"] && config["RIGHT POCKETS"] === "None" || config["RIGHT POCKETS"] && config["RIGHT POCKETS"] === "NONE") {
            await toolkit.player.setConfiguration({ "ANTENNA NOTCH": "None" });
            await toolkit.player.setConfiguration({ "ANTENNA NOTCH": "NONE" });
        }

        if (config["LEFT POCKETS"] && config["LEFT POCKETS"] === "None" || config["LEFT POCKETS"] && config["LEFT POCKETS"] === "NONE") {
            await toolkit.player.setConfiguration({ "LEFT ANTENNA NOTCH": "None" });
            await toolkit.player.setConfiguration({ "LEFT ANTENNA NOTCH": "NONE" });
        }

        await toolkit.player.setConfiguration(config);

        const updatedConfiguration = await toolkit.player.getConfiguration();
        const updatedController = await toolkit.template.prepTemplate(sections, inputs);

        if (config["GEAR_VISIBILITY"]) {
            updatedConfiguration['GEAR_VISIBILITY'] = config["GEAR_VISIBILITY"];
        }

        setConfiguration(JSON.parse(JSON.stringify(updatedConfiguration)));
        setController(updatedController);

        const configOutput = await toolkit.player.getConfigurationOutput();

        handleInputSelect(config, configOutput);

        if (window.location.pathname !== "/gXtreme" && config["PANT_SIDE_POCKETS_RIGHT_SIZE_TYPE"] && config["PANT_SIDE_POCKETS_RIGHT_SIZE_TYPE"] === "ESCAPE SYSTEM POCKET CMC 2x8x9 RPKT") {
            document.querySelector(".pants .inside-reinforcement-right .inputs-container").children[0].style.display = "none"
            document.querySelector(".pants .inside-reinforcement-right .inputs-container").children[1].style.display = "none"
            document.querySelector(".pants .inside-reinforcement-right .inputs-container").children[2].style.display = 'block';
            document.querySelector(".pants .inside-reinforcement-right .inputs-container").children[2].classList.add("eRMacW");
            document.querySelector(".pants .inside-reinforcement-right .inputs-container").children[2].classList.remove("boFTdt");
        }

        if (window.location.pathname !== "/gXtreme" && config["PANT_SIDE_POCKETS_LEFT_SIZE_TYPE"] && config["PANT_SIDE_POCKETS_LEFT_SIZE_TYPE"] === "ESCAPE SYSTEM POCKET CMC 2X8X9 LPKT") {
            document.querySelector(".pants .inside-reinforcement-left .inputs-container").children[0].style.display = "none"
            document.querySelector(".pants .inside-reinforcement-left .inputs-container").children[1].style.display = "none"
            document.querySelector(".pants .inside-reinforcement-left .inputs-container").children[2].style.display = 'block';
            document.querySelector(".pants .inside-reinforcement-left .inputs-container").children[2].classList.add("eRMacW");
            document.querySelector(".pants .inside-reinforcement-left .inputs-container").children[2].classList.remove("boFTdt");
        }

        if (config[window.location.pathname !== "/gXtreme" && "PANT_SIDE_POCKETS_RIGHT_SIZE_TYPE"] && config["PANT_SIDE_POCKETS_RIGHT_SIZE_TYPE"] === "ESCAPE SYSTEM POCKET CROSBY 2X7X8 RPKT") {
            document.querySelector(".pants .inside-reinforcement-right .inputs-container").children[0].style.display = "none"
            document.querySelector(".pants .inside-reinforcement-right .inputs-container").children[1].style.display = 'block';
            document.querySelector(".pants .inside-reinforcement-right .inputs-container").children[2].style.display = "none"
            document.querySelector(".pants .inside-reinforcement-right .inputs-container").children[1].classList.add("eRMacW");
            document.querySelector(".pants .inside-reinforcement-right .inputs-container").children[1].classList.remove("boFTdt");
        }

        if (window.location.pathname !== "/gXtreme" && config["PANT_SIDE_POCKETS_LEFT_SIZE_TYPE"] && config["PANT_SIDE_POCKETS_LEFT_SIZE_TYPE"] === "ESCAPE SYSTEM POCKET CROSBY 2X7X8 LPKT") {
            document.querySelector(".pants .inside-reinforcement-left .inputs-container").children[0].style.display = "none"
            document.querySelector(".pants .inside-reinforcement-left .inputs-container").children[1].style.display = 'block';
            document.querySelector(".pants .inside-reinforcement-left .inputs-container").children[2].style.display = "none"
            document.querySelector(".pants .inside-reinforcement-left .inputs-container").children[1].classList.add("eRMacW");
            document.querySelector(".pants .inside-reinforcement-left .inputs-container").children[1].classList.remove("boFTdt");
        }

        if (window.location.pathname !== "/gXtreme" && config["PANT_SIDE_POCKETS_RIGHT_SIZE_TYPE"] && config["PANT_SIDE_POCKETS_RIGHT_SIZE_TYPE"] === "SLNT EXPANSION POCKET 2x10x8/11") {
            document.querySelector(".pants .inside-reinforcement-right .inputs-container").children[0].style.display = 'block';
            document.querySelector(".pants .inside-reinforcement-right .inputs-container").children[1].style.display = 'none';
            document.querySelector(".pants .inside-reinforcement-right .inputs-container").children[2].style.display = 'none';
            document.querySelector(".pants .inside-reinforcement-right .inputs-container").children[0].classList.add("eRMacW");
            document.querySelector(".pants .inside-reinforcement-right .inputs-container").children[0].classList.remove("boFTdt");

            if (document.querySelector(".pants .outside-reinforcement-right .inputs-container")) {
                document.querySelector(".pants .outside-reinforcement-right .inputs-container").children[0].style.display = 'block';
                document.querySelector(".pants .outside-reinforcement-right .inputs-container").children[1].style.display = 'block';
                document.querySelector(".pants .outside-reinforcement-right .inputs-container").children[0].classList.remove("boFTdt");
                document.querySelector(".pants .outside-reinforcement-right .inputs-container").children[0].classList.add("eRMacW");
                document.querySelector(".pants .outside-reinforcement-right .inputs-container").children[0].classList.add("bhrtsi");
                document.querySelector(".pants .outside-reinforcement-right .inputs-container").children[0].classList.remove("bRtpvt");
                document.querySelector(".pants .outside-reinforcement-right .inputs-container").children[1].classList.remove("eRMacW");
                document.querySelector(".pants .outside-reinforcement-right .inputs-container").children[1].classList.add("boFTdt");
            }
        }

        if (window.location.pathname !== "/gXtreme" && config["PANT_SIDE_POCKETS_LEFT_SIZE_TYPE"] && config["PANT_SIDE_POCKETS_LEFT_SIZE_TYPE"] === "SLNT EXPANSION POCKET 2x10x8/11") {
            document.querySelector(".inside-reinforcement-left .inputs-container").children[0].style.display = 'block';
            document.querySelector(".inside-reinforcement-left .inputs-container").children[1].style.display = 'none';
            document.querySelector(".inside-reinforcement-left .inputs-container").children[2].style.display = 'none';
            document.querySelector(".inside-reinforcement-left .inputs-container").children[0].classList.add("eRMacW");
            document.querySelector(".inside-reinforcement-left .inputs-container").children[0].classList.remove("boFTdt");

            if (document.querySelector(".outside-reinforcement-left .inputs-container")) {
                document.querySelector(".outside-reinforcement-left .inputs-container").children[0].style.display = 'block';
                document.querySelector(".outside-reinforcement-left .inputs-container").children[1].style.display = 'block';
                document.querySelector(".outside-reinforcement-left .inputs-container").children[0].classList.remove("boFTdt");
                document.querySelector(".outside-reinforcement-left .inputs-container").children[0].classList.add("eRMacW");
                document.querySelector(".outside-reinforcement-left .inputs-container").children[1].classList.remove("eRMacW");
                document.querySelector(".outside-reinforcement-left .inputs-container").children[1].classList.add("boFTdt");
            }
        }

        sessionStorage.setItem("rightFlag", config["RIGHT FLAGS"] ? config["RIGHT FLAGS"] : "");
        sessionStorage.setItem("leftFlag", config["LEFT FLAGS"] ? config["LEFT FLAGS"] : "");
        sessionStorage.setItem(
            "rightPatches",
            config["RIGHT EMBROIDERY PATCHES"] ? config["RIGHT EMBROIDERY PATCHES"] : ""
        );
        sessionStorage.setItem("leftPatches", config["LEFT EMBROIDERY PATCHES"] ? config["LEFT EMBROIDERY PATCHES"] : "");

        let currentConfiguration = {};
        const configuration = await toolkit.player.getConfigurationOutput();
        if (configuration) {
            for (const property in configuration) {
                currentConfiguration[`${property}`] = configuration[property].value;
            }
        }

        if (variantConfiguration === null || JSON.stringify(variantConfiguration) !== JSON.stringify(currentConfiguration)) {
            const bodySaveConfiguration = {
                "orgId": threekitConfig.orgId,
                "productId": threekitConfig.assetId,
                "productVersion": 'v1.0',
                "variant": currentConfiguration,
                "metadata": {},
                "files": "string",
                "shortId": "",
                "customerId": ""
            };

            await saveConfiguration(bodySaveConfiguration);
            setVariantConfiguration(currentConfiguration);

            const user = JSON.parse(localStorage.getItem('user'));
            const productSelected = window.location.pathname.toLowerCase() == "/athletix" ? "Athletix" : "GX3";
            const shortId = localStorage.getItem(`shortId_${productSelected}`);
            const bodySaveConfigurationDataTable = {
                "Username": user.Username,
                "Email": user["Email Address"],
                "ShortId": shortId,
                "GearSelected": productSelected,
                "ConfigurationName": 'Latest'.toUpperCase()
            };
            await addNewConfigurationInDataTable(bodySaveConfigurationDataTable)
           
        }
    };

    const setRightFlag = () => {
        const rightFlags = document.querySelector(".right-flags .inputs-container").children;
        for (let rightFlag of rightFlags) {
            rightFlag.classList.remove("eRMacW");
            rightFlag.classList.add("boFTdt");

        }
        rightFlags[0].classList.add("eRMacW");
        rightFlags[0].classList.remove("boFTdt");
    };

    const setRightPatch = () => {
        const rightPatches = document.querySelector(".right-custom-patches .inputs-container").children;
        for (let rightPatch of rightPatches) {
            rightPatch.classList.remove("eRMacW");
            rightPatch.classList.add("boFTdt");

        }
        rightPatches[0].classList.add("eRMacW");
        rightPatches[0].classList.remove("boFTdt");
    };

    const setLeftPatch = () => {
        const leftPatches = document.querySelector(".left-custom-patches .inputs-container").children;
        for (let leftPatch of leftPatches) {
            leftPatch.classList.remove("eRMacW");
            leftPatch.classList.add("boFTdt");

        }
        leftPatches[0].classList.add("eRMacW");
        leftPatches[0].classList.remove("boFTdt");
    };

    const setLeftFlag = () => {
        const leftFlags = document.querySelector(".left-flags .inputs-container").children;
        for (let leftFlag of leftFlags) {
            leftFlag.classList.remove("eRMacW");
            leftFlag.classList.add("boFTdt");

        }
        leftFlags[0].classList.add("eRMacW");
        leftFlags[0].classList.remove("boFTdt");
    };



    const addToCart = async () => {
        const configOutput = await toolkit.player.getConfigurationOutput();
        handleAddToCart(configOutput);
    };

    return (
        <Template
            title={title}
            subtitle={subtitle}
            controller={controller}
            configuration={configuration}
            handleSetAttribute={handleSetAttribute}
        />
    );
};

export default TemplateContainer;
