import React, {createContext, useState} from "react";
import subMenusSelection from "../config/subMenusSelection";

const Provider = (props) => {
    const [state, setState] = useState({});
    state.product = window.location.pathname == '/gXtreme' ? 'GX3' : 'Athletix';
    state.primaryMenus = window.location.pathname == '/gXtreme' ? 'GX3' : 'Athletix';
    state.subMenus = 'Materials';
    state.subMenuSelections = subMenusSelection;
    state.thermalLinerOpt = 'Caldura Elite';
    state.moistureBarrierOpt = 'Crosstech Black';
    state.thermaLinerParallonClicked = false;
    state.moistureBarrierParallonClicked = false;
    state.pantBrillianceMaterialSelected = false;
    state.jacketBrillianceMaterialSelected = false;
    
    return (
        <div>
            <AppContext.Provider value={{state, setState}}>
                {props.children}
            </AppContext.Provider>
        </div>
    )
}
export default Provider;
export const AppContext = createContext();