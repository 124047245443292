import React from 'react';
import { Templates } from '../../toolkit';

import templateDataA from '../../config/athletix/template';
import sectionsDataA from '../../config/athletix/sections';
import inputsDataA from '../../config/athletix/inputs';
import threekitConfigA from '../../config/athletix/threekitConfig';
import templateDataG from '../../config/gXtreme/template';
import sectionsDataG from '../../config/gXtreme/sections';
import inputsDataG from '../../config/gXtreme/inputs';
import threekitConfigG from '../../config/gXtreme/threekitConfig';

const templateData = window.location.pathname === "/athletix" ? templateDataA : templateDataG;
const sectionsData = window.location.pathname === "/athletix" ? sectionsDataA : sectionsDataG;
const inputsData = window.location.pathname === "/athletix" ? inputsDataA : inputsDataG;
const threekitConfig = window.location.pathname === "/athletix" ? threekitConfigA : threekitConfigG;


const AthletixHome = () => {
  sessionStorage.setItem("gearSelected", "Athletix");
    return (
      <Templates
        template={templateData}
        sections={sectionsData}
        inputs={inputsData}
        threekitConfig={threekitConfig}
      />
    );
  };
  
  export default AthletixHome;