import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from "../../../assets/GLOBE_LOCKUP_LOGO-R.png";
import jacket from "../../../assets/G-Xtreme_Jacket.jpg";
import gExtreme from "../../../assets/g_extreme.png";
import athletix from "../../../assets/athletix.png";
import Modal from "react-modal";
import axios from 'axios';
import toolkit from "../../../toolkit/toolkit/player";

const HOST = process.env.REACT_APP_BASE_URL || 'http://localhost:5000';
let catalogItemProduct = '';
let confListFiltered = [];
let allConfList = [];

const Home = ({ sections, configuration, handleSetAttribute }) => {
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      textAlign: 'center'
    }
  };
  const currentProduct = window.location.pathname == "/athletix" ? "Athletix" : "GX3";
  let confList = [];
  const [configurationListModalIsOpen, setConfigurationListModalIsOpen] = useState(false);
  const [configurationList, setConfigurationList] = useState([]);
  const [buttonsConfiguration, setButtonsConfiguration] = useState(false);
  const [configurationLoaded, setConfigurationLoaded] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [confListBtnState, setConfListBtnState] = useState('disable');
  const needRefresh = localStorage.getItem('needRefresh');

  const closeConfigurationModal = () => {
    setConfigurationList(allConfList);
    setButtonsConfiguration(false);
    setConfigurationListModalIsOpen(false);
  };

  const handleSearchConfiguration = (e) => {
    let PATTERN = e.target.value.toUpperCase();
    confList = confListFiltered.filter(function (obj) { return obj.value['Configuration Name'].toUpperCase().indexOf(PATTERN) !== -1; });
    setConfigurationList(confList);
  }

  const goToExistingConfiguration = (configId) => {
    const shortId = configId;
    localStorage.setItem(`shortId_${currentProduct}`, shortId);
    localStorage.setItem('needRefresh', 'false');

    setConfigurationListModalIsOpen(false);
    setButtonsConfiguration(false);
    setConfigurationLoaded(true);
    
    if (shortId !== null && shortId !== "" && shortId !== undefined) {
      const url = window.location.origin + window.location.pathname + catalogItemProduct;
      const newUrl = url + '?tkcsid=' + shortId;
      window.location.href = newUrl;
    }
  };

  const goToNewConfiguration = (page) => {
    localStorage.setItem('needRefresh', 'true');
    
    const url = window.location.origin + window.location.pathname;
    const newUrl = url + page;
    window.location.href = newUrl;
  };

  const loadConfigurationModal = async (product, loadConfigurationList) => {
    if(confListBtnState !== 'disable') {
      catalogItemProduct = product;
      setConfigurationListModalIsOpen(true);
      const user = JSON.parse(localStorage.getItem('user'));
      const list = configurationList.filter(element => {
        if(element.value["User Name"] === user.Username && element.value["Gear Selected"] === (catalogItemProduct === 'gXtreme' ? 'GX3' : 'Athletix')) {
          return true;
        }
        return false;
      })
      confListFiltered = list;
      setConfigurationList(list);
      if(configurationList.length > 0) {
        setModalMessage('Do you want to load a previous configuration?')
        setButtonsConfiguration(true)
      }
    }
  }

  const athletixTarget = {
    pathname: "/athletix",
    key: Date.now(), // we could use Math.random, but that's not guaranteed unique.
    state: {
      applied: true
    }
  };
  const gx3Target = {
    pathname: "/gXtreme",
    key: Date.now(), // we could use Math.random, but that's not guaranteed unique.
    state: {
      applied: true
    }
  };

  useEffect(() => {
    const shortId = localStorage.getItem(`shortId_${currentProduct}`);
    const urlSearch = window.location.search;
    confList = [];
    if(!configurationLoaded) {
      const getDataTable = async () => {
        await axios
          .get(`${HOST}/getDataTable`)
          .then((res) => {
            // if (urlSearch !== '?tkcsid=' + shortId && shortId !== null && shortId !== "") {
              const user = JSON.parse(localStorage.getItem('user'));
              confList = res.data.rows;
            // }
            setConfigurationLoaded(true);
            setConfigurationList(confList);
            setConfListBtnState('enable');
            allConfList = confList;
            // triggerEventClick();
          })
          .catch((error) => {
            return error;
          });
      }
      getDataTable();
    }
  });
  
  return (
    <div className="homepage">
      <div style={{ backgroundColor:'black', position:'relative' }} >
          <div className="logo">
              <img src={logo}  />
          </div>
          <div className="sign-in-container" style={{ position: 'absolute', right: '5%', top: '30%', fontSize: '1.6rem', textTransform: 'uppercase', color: '#fff' }}>
              {<Link to="#">
                  SIGN IN
              </Link> }
          </div>
          <div className="logo">
              <a href="#"></a>
          </div>
      </div>
      <section id="pdpTopContent" className="container-fluid"> 
          <div className="row"> <h2>Turnout <span className="green-highlight">Gear</span> Configurator</h2> </div> 
      </section>
      
      <div className='menu'>
        <div className='msa-product-item'>
          {
            <div style={{height:'max-content', display: "flex", justifyContent: "center", color: "grey", fontSize: "1.5em", fontWeight: "bolder", flexDirection: "column"}}>
              <img style={{maxHeight: "320px", maxWidth: "320px", margin : "0 auto"}} src={gExtreme}/>
              <div className='msa-product-details'>
                  <div className="head3">G-Xtreme 3.0 <br/> Jacket with Globe Pant system</div>
              </div>
              <div onClick={e => { goToNewConfiguration('gXtreme') }} className='configure-btn'> 
                  START NEW CONFIGURATION
              </div>
              <div onClick={e => { loadConfigurationModal('gXtreme', 'true') }} className={`configure-btn ` + confListBtnState }> 
                  MODIFY EXISTING CONFIGURATION
              </div>
            </div>
          }
        </div>

        <div className='msa-product-item'>
          <div style={{height:'max-content', backgroundColor:'white', display: "flex", justifyContent: "center", color: "grey", fontSize: "1.5em", fontWeight: "bolder", flexDirection: "column"}}>
            <img style={{maxHeight: "320px", maxWidth: "320px", margin : "0 auto"}}  src={athletix}/>
            <div className='msa-product-details'>
                <div className="head1">ATHLETIX</div>
            </div>
            <div onClick={e => { goToNewConfiguration('athletix') }}  className='configure-btn'> 
                START NEW CONFIGURATION
            </div>
            <div onClick={e => { loadConfigurationModal('Athletix', 'true') }}  className={`configure-btn ` + confListBtnState }> 
                MODIFY EXISTING CONFIGURATION
            </div>
          </div>  
        </div>

    </div>
      <div className='save-configuration-wrapper'>
        <Modal
          ariaHideApp={false}
          isOpen={configurationListModalIsOpen}
          onRequestClose={closeConfigurationModal}
          style = {customStyles}
          contentLabel = ""
        >
          <div className='modal-message modal-message-configuration-list'>
            <div className='modal-title'>{modalMessage}</div>
            <div className="search-configuration">
              <input 
                type = "search" 
                placeholder = "Search Configuration" 
                onChange = {handleSearchConfiguration}
              />
            </div>
              {buttonsConfiguration === true ?
                <ul className='configuration-list'>
                  { configurationList.map((conf, i) => {
                    return <li onClick={() => goToExistingConfiguration(conf.value["Configuration ID"]) } key={i}><a href='#'>{conf.value['Configuration Name']}</a></li>
                  })}
                </ul>
                : null}
          </div>
        </Modal>
        </div>
      </div>
  )
};

export default Home;
