import React from 'react';
import player from './../../../toolkit/player';
import {
  Wrapper,
  PrimaryBar,
  PrimaryBarItem,
  SecondaryBarItem,
} from './menuBar.styles';
import { Link } from 'react-router-dom';

const MenuBar = ({
  primaryOptions,
  primarySelected,
  secondaryOptions,
  secondarySelected,
  handleClickPrimary,
  handleClickSecondary,
  handleSetAttribute
}) => {
  if (!primaryOptions || !primaryOptions.length) return null;
  // if (window.location.pathname === "/gXtreme"){
  //   let gXtremePrimaryMenu = ["GX3", "GX3 Coat", "GPS Pant", "SUMMARY"]
  //   for(let a = 0; a < primaryOptions.length; a++){
  //     primaryOptions[a] = gXtremePrimaryMenu[a]
  //   }
  // }
  if (localStorage.getItem('clickOnPrimary') === 'true') {
    localStorage.setItem('menuSelected', secondaryOptions[0].toLowerCase().split(' ').join('-') + "-" + primarySelected);
  }
  
  return (
    <Wrapper className='main-menu-wrapper'>
      <PrimaryBar className='primary-bar-wrapper'>
        <div className="nav--friendly--message" 
            onClick={e => {
             localStorage.setItem('applyConfiguration', "true");
            }}><Link to='/'>START A NEW CONFIGURATION:</Link></div>
        {primaryOptions.map((label, i) => (
          <PrimaryBarItem
            id={window.location.pathname == "/gXtreme" ? label.toLowerCase().replace(" ", "-").split(" ")[0] + '-menu-option' : label.toLowerCase().split(" ")[0] + '-menu-option'}
            className={`primary-bar-item item-` + label}
            key={i}
            onClick={e => {
              localStorage.setItem('isClickEvent', 'false');
              localStorage.setItem('clickOnPrimary', 'true');
              handleClickPrimary(i);
              handleSetAttribute({ 'GEAR_VISIBILITY': label }, "header", label)
              e.stopPropagation();

            }}
            selected={primarySelected === i}
          >
            <div className="primary-bar-label">{i == 0 ? sessionStorage.getItem("gearSelected") : label}</div>
          </PrimaryBarItem>
        ))}
      </PrimaryBar>
    </Wrapper>
  );
};

export default MenuBar;
