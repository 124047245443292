export default {
  /**************************************************
   *************** Material Options *****************
   **************************************************/
  GEAR_VISIBILITY: {
    sortValue : 1,
    label: "GEAR",
    attribute: "GEAR_VISIBILITY",
    type: "RadioButtonsImages",
    data: "tk-platform",
    cssClass: "active",
    optionNumber: {
      Athletix: "A/B",
      Jacket: "A",
      Pants: "B"
    },
    optionGroup: {
      Athletix: "MATERIALS",
      Jacket: "MATERIALS",
      Pants: "MATERIALS"
    },
    optionType: {
      Athletix: "A/B",
      Jacket: "A",
      Pants: "B"
    },
    gear: "athletix"
  },

  materials: {
    sortValue : 2,
    label: "Materials",
    attribute: "MATERIALS",
    type: "radioButtons",
    data: "tk-platform",
    cssClass: "active",
    optionNumber: {
      "PBI Stretch": "42"
    },
    optionGroup: {
      "PBI Stretch": "MATERIALS"
    },
    optionType: {
      "PBI Stretch": "42"
    },
    gear: "athletix"
  },

  color: {
    sortValue : 3,
    label: "Color",
    attribute: "Color",
    type: "RadioButtonsImages",
    data: "tk-platform",
    cssClass: "active",
    optionNumber: {
      Black: "01",
      Gold: "10"
    },
    optionGroup: {
      Black: "MATERIALS",
      Gold: "MATERIALS"
    },
    optionType: {
      Black: "01",
      Gold: "10"
    },
    gear: "athletix"
  },

  thermalLiner: {
    sortValue : 4,
    label: "Thermal Liner",
    attribute: "THERMAL LINER",
    type: "radioButtons",
    data: "tk-platform",
    cssClass: "active",
    selected: true,
    optionNumber: {
      "Titanium™ with Nomex Nano": "N3"
    },
    optionGroup: {
      "Titanium™ with Nomex Nano": "MATERIALS"
    },
    optionType: {
      "Titanium™ with Nomex Nano": "N3"
    },
    gear: "athletix"
  },

  moistureBarrier: {
    sortValue : 5,
    label: "Moisture Barrier",
    attribute: "MOISTURE BARRIER",
    type: "radioButtons",
    data: "tk-platform",
    cssClass: "active",
    optionNumber: {
      "Gore Crosstech Black": "G"
    },
    optionGroup: {
      "Gore Crosstech Black": "MATERIALS"
    },
    optionType: {
      "Gore Crosstech Black": "G"
    },
    gear: "athletix"
  },

  /**************************************************
   *************** trim Options *****************
   **************************************************/
  coat_trim_type: {
    sortValue : 6,
    label: "Coat Trim Type",
    attribute: "COAT TRIM TYPE",
    type: "radioButtons",
    data: "tk-platform",
    selected: true,
    cssClass: "active",
    optionNumber: {
      NFPAB: "NFPAB",
      NYC: "NYC"
    },
    optionGroup: {
      NFPAB: "COAT TRIM",
      NYC: "COAT TRIM"
    },
    optionType: {
      NFPAB: "17C783",
      NYC: "17C703"
    },
    gear: "Trim Jacket"
  },

  coat_trim_color: {
    sortValue : 7,
    label: "Coat Trim Color",
    attribute: "COAT TRIM COLOR",
    type: "radioButtons",
    data: "tk-platform",
    selected: true,
    cssClass: "active",
    optionNumber: {
      Orange: "2",
      Yellow: "4"
    },
    optionGroup: {
      Orange: "COAT TRIM",
      Yellow: "COAT TRIM"
    },
    optionType: {
      Orange: "R/O",
      Yellow: "L/Y"
    },
    gear: "Trim Jacket"
  },
  pants_trim_type: {
    sortValue : 8,
    label: "Pants Trim Type",
    attribute: "PANTS TRIM TYPE",
    type: "radioButtons",
    data: "tk-platform",
    selected: true,
    cssClass: "active",
    optionNumber: {
      '3" AROUND CUFFS': "NA",
      NYC: "NA"
    },
    optionGroup: {
      '3" AROUND CUFFS': "PANT TRIM",
      NYC: "PANT TRIM"
    },
    optionType: {
      '3" AROUND CUFFS': "27C03"
    },
    gear: "Trim Pants"
  },

  pants_trim_color: {
    sortValue : 9,
    label: "Pants Trim Color",
    attribute: "PANTS TRIM COLOR",
    type: "radioButtons",
    data: "tk-platform",
    selected: true,
    cssClass: "active",
    optionNumber: {
      Orange: "2",
      Yellow: "4"
    },
    optionGroup: {
      Orange: "PANT TRIM",
      Yellow: "PANT TRIM"
    },
    optionType: {
      Orange: "R/O",
      Yellow: "L/Y"
    },
    gear: "Trim Pants "
  },

  /**************************************************
   *************** JACKET Options *****************
   **************************************************/

  /*************** FRONT **********************/
  JACKET_FRONT_STORMFLAP_CLOSURE: {
    sortValue : 10,
    label: "Closure",
    attribute: "JACKET_FRONT_STORMFLAP_CLOSURE",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "VISLON ZIPPER IN VELCRO OUT": "N1STCL03"
    },
    optionGroup: {
      "VISLON ZIPPER IN VELCRO OUT": "STORMFLAP"
    },
    optionType: {
      "VISLON ZIPPER IN VELCRO OUT": "N1STCL03"
    },
    gear: "Jacket"
  },

  JACKET_FRONT_STORMFLAP_ZIPPER_PULL: {
    sortValue : 11,
    label: "Zipper Pull",
    attribute: "JACKET_FRONT_STORMFLAP_ZIPPER_PULL",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      ZIPGRIP: "ZIPGRIP"
    },
    optionGroup: {
      ZIPGRIP: "STORMFLAP"
    },
    optionType: {
      ZIPGRIP: "ZIPGRIP"
    },
    gear: "Jacket"
  },

  JACKET_FRONT_HOLDER_STORMFLAP: {
    sortValue : 12,
    label: "Holder - Stormflap",
    attribute: "FRONT HOLDERS",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "HANG STRAP WITH D-RING": "1942590S",
      "HELMET SNAP W/SLF STRAP": "N142190S",
      "MICROPHONE STRAP 1X3": "1942564S"
    },
    optionGroup: {
      "HANG STRAP WITH D-RING": "STORMFLAP",
      "HELMET SNAP W/SLF STRAP": "STORMFLAP",
      "MICROPHONE STRAP 1X3": "STORMFLAP"
    },
    optionType: {
      "HANG STRAP WITH D-RING": "1942590S",
      "HELMET SNAP W/SLF STRAP": "N142190S",
      "MICROPHONE STRAP 1X3": "1942564S"
    },
    gear: "Jacket"
  },

  JACKET_FRONT_HOLDER_ABOVE_TRIM: {
    sortValue : 13,
    label: "Holder - Above Trim",
    attribute: "FRONT HOLDERS ABOVE TRIM",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "MICROPHONE STRAP 1X3": "1942564S"
    },
    optionGroup: {
      "MICROPHONE STRAP 1X3": "STORMFLAP"
    },
    optionType: {
      "MICROPHONE STRAP 1X3": "1942564S"
    },
    gear: "Jacket"
  },


  sizeAndType: {
    sortValue : 15,
    label: "Size and type",
    attribute: "SIZE & TYPE",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "EXPPKT 2X8X8 W/FLC HANDWARMER": "N1STPK03"
    },
    optionGroup: {
      "EXPPKT 2X8X8 W/FLC HANDWARMER": "SIDE POCKETS LEFT & RIGHT"
    },
    optionType: {
      "EXPPKT 2X8X8 W/FLC HANDWARMER": "N1STPK03"
    },
    gear: "Jacket"
  },

  outsideReinforcement: {
    sortValue : 16,
    label: "Outside Reinforcements",
    attribute: "OUTSIDE REINFORCEMENTS",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "DRAGONHIDE FRONT EXPANSION POCKET 2X8X8": "19428DH"
    },
    optionGroup: {
      "DRAGONHIDE FRONT EXPANSION POCKET 2X8X8": "SIDE POCKETS LEFT & RIGHT"
    },
    optionType: {
      "DRAGONHIDE FRONT EXPANSION POCKET 2X8X8": "19428DH"
    },
    gear: "Jacket"
  },

  insideReinforcement: {
    sortValue : 17,
    label: "Inside Reinforcements",
    attribute: "INSIDE REINFORCEMENTS",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "KEV POUCH HANDWARMER 2X8X8": "N1STPRA1"
    },
    optionGroup: {
      "KEV POUCH HANDWARMER 2X8X8": "SIDE POCKETS LEFT & RIGHT"
    },
    optionType: {
      "KEV POUCH HANDWARMER 2X8X8": "N1STPRA1"
    },
    gear: "Jacket"
  },

  pullTab: {
    sortValue : 18,
    label: "Pull Tab",
    attribute: "PULL TAB",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "BLK ARASHIELD POCKET PULL TAB": "N1STPTA1"
    },
    optionGroup: {
      "BLK ARASHIELD POCKET PULL TAB": "SIDE POCKETS LEFT & RIGHT"
    },
    optionType: {
      "BLK ARASHIELD POCKET PULL TAB": "N1STPTA1"
    },
    gear: "Jacket"
  },

  rightSidePocketDivider: {
    sortValue : 19,
    label: "Right Side Pocket Divider",
    attribute: "RIGHT SIDE POCKET DIVIDER",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      'DIVIDER KEVLAR EXP/SEMI 8" HIGH': "19KT1208"
    },
    optionGroup: {
      'DIVIDER KEVLAR EXP/SEMI 8" HIGH': "SIDE POCKETS LEFT & RIGHT"
    },
    optionType: {
      'DIVIDER KEVLAR EXP/SEMI 8" HIGH': "19KT1208"
    },
    gear: "Jacket"
  },

  leftSidePocketDivider: {
    sortValue : 20,
    label: "Left Side Pocket Divider",
    attribute: "LEFT SIDE POCKET DIVIDER",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      'DIVIDER KEVLAR EXP/SEMI 8" HIGH': "19KT1208"
    },
    optionGroup: {
      'DIVIDER KEVLAR EXP/SEMI 8" HIGH': "SIDE POCKETS LEFT & RIGHT"
    },
    optionType: {
      'DIVIDER KEVLAR EXP/SEMI 8" HIGH': "19KT1208"
    },
    gear: "Jacket"
  },

  rightTopCollar: {
    sortValue : 21,
    label: "Right Top Collar",
    attribute: "RIGHT TOP COLLAR",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "MICROPHONE STRAP 1X3": "1942564C"
    },
    optionGroup: {
      "MICROPHONE STRAP 1X3": "COLLAR OPTIONS"
    },
    optionType: {
      "MICROPHONE STRAP 1X3": "1942564C"
    },
    gear: "Jacket"
  },

  leftTopCollar: {
    sortValue : 22,
    label: "Left Top Collar",
    attribute: "LEFT TOP COLLAR",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "MICROPHONE STRAP 1X3": "1942564C"
    },
    optionGroup: {
      "MICROPHONE STRAP 1X3": "COLLAR OPTIONS"
    },
    optionType: {
      "MICROPHONE STRAP 1X3": "1942564C"
    },
    gear: "Jacket"
  },

  

  /*************** RIGHT CHEST **********************/
  

  rightChestSFPockets: {
    sortValue : 23,
    label: "Right Pockets",
    attribute: "RIGHT POCKETS",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "RADIO POCKET 3X3.5X9": "1942413B",
      "RADIO POCKET 2X3.5X8": "1942413C",
      "RADIO POCKET 3X2.5X7": "1942413D"
    },
    optionGroup: {
      "RADIO POCKET 3X3.5X9": "RIGHT CHEST NEXT TO STORMFLAP",
      "RADIO POCKET 2X3.5X8": "RIGHT CHEST NEXT TO STORMFLAP",
      "RADIO POCKET 3X2.5X7": "RIGHT CHEST NEXT TO STORMFLAP"
    },
    optionType: {
      "RADIO POCKET 3X3.5X9": "1942413B",
      "RADIO POCKET 2X3.5X8": "1942413C",
      "RADIO POCKET 3X2.5X7": "1942413D"
    },
    gear: "Jacket"
  },

  // rightChestSFTrimOverPocket: {
  //   label: "Right Trim Over Pocket",
  //   attribute: "TRIM OVER POCKET",
  //   type: "radioButtons",
  //   data: "tk-platform",
  //   optionNumber: "NA",
  //   optionGroup: "PCKT",
  //   optionType: "RADIO"
  // },

  rightChestSFMic: {
    sortValue : 24,
    label: "Right Holder Microphone",
    attribute: "RIGHT CHEST MIC",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "MICROPHONE STRAP 1X3": "1942564"
    },
    optionGroup: {
      "MICROPHONE STRAP 1X3": "RIGHT CHEST NEXT TO STORMFLAP"
    },
    optionType: {
      "MICROPHONE STRAP 1X3": "1942564"
    },
    gear: "Jacket"
  },

  rightChestSFHoldersStormFlap: {
    sortValue : 25,
    label: "Right Holder",
    attribute: "RIGHT CHEST HOLDERS STORMFLAP",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "MICROPHONE STRAP 1X3": "1942564",
      "HANGING STRAP WITH D-RING": "1942590H",
      "STRAP 1X12 W/1X4 VELCRO": "N142109",
      "HELMET SNAP W/SLF &VLC STRAP": "N142187",
      "SELF HSNP/MIC/2.5X9": "N142267",
      "FLASHLIGHT STRAP 2.5X9 WITH VELCRO": "N142298",
      "HELMET SNAP W/SLF STRAP": "N142190",
      "SURVIVOR STRAP": "1942548",
      "SUNLANCE W/SLF HELMET SNAP ABOVE": "1942546C"
    },
    optionGroup: {
      "MICROPHONE STRAP 1X3": "RIGHT CHEST NEXT TO STORMFLAP",
      "HANGING STRAP WITH D-RING": "RIGHT CHEST NEXT TO STORMFLAP",
      "STRAP 1X12 W/1X4 VELCRO": "RIGHT CHEST NEXT TO STORMFLAP",
      "HELMET SNAP W/SLF &VLC STRAP": "RIGHT CHEST NEXT TO STORMFLAP",
      "SELF HSNP/MIC/2.5X9": "RIGHT CHEST NEXT TO STORMFLAP",
      "FLASHLIGHT STRAP 2.5X9 WITH VELCRO": "RIGHT CHEST NEXT TO STORMFLAP",
      "HELMET SNAP W/SLF STRAP": "RIGHT CHEST NEXT TO STORMFLAP",
      "SURVIVOR STRAP": "RIGHT CHEST NEXT TO STORMFLAP",
      "SUNLANCE W/SLF HELMET SNAP ABOVE": "RIGHT CHEST NEXT TO STORMFLAP"
    },
    optionType: {
      "MICROPHONE STRAP 1X3": "1942564",
      "HANGING STRAP WITH D-RING": "1942590H",
      "STRAP 1X12 W/1X4 VELCRO": "N142109",
      "HELMET SNAP W/SLF &VLC STRAP": "N142187",
      "SELF HSNP/MIC/2.5X9": "N142267",
      "FLASHLIGHT STRAP 2.5X9 WITH VELCRO": "N142298",
      "HELMET SNAP W/SLF STRAP": "N142190",
      "SURVIVOR STRAP": "1942548",
      "SUNLANCE W/SLF HELMET SNAP ABOVE": "1942546C"
    },
    gear: "Jacket"
  },

  rightChestSFAntennaNotch: {
    sortValue : 26,
    label: "Right Antenna Notch",
    attribute: "ANTENNA NOTCH",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "RADIO FLAP DUAL NOTCH TAB 3": "N142505",
      "RADIO FLAP NOTCH TAB 3": "N1425035"
    },
    optionGroup: {
      "RADIO FLAP DUAL NOTCH TAB 3": "RIGHT CHEST NEXT TO STORMFLAP",
      "RADIO FLAP NOTCH TAB 3": "RIGHT CHEST NEXT TO STORMFLAP"
    },
    optionType: {
      "RADIO FLAP DUAL NOTCH TAB 3": "N142505",
      "RADIO FLAP NOTCH TAB 3": "N1425035"
    },
    gear: "Jacket"
  },

  rightChestSleeveHolders: {
    sortValue : 27,
    label: "Right Pockets",
    attribute: "RIGHT TWD SLEEVE HOLDERS",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "MICROPHONE STRAP 1X3": "1942564",
      "HANGING STRAP WITH D-RING": "1942590H",
      "STRAP 1X12 W/1X4 VELCRO": "N142109",
      "HELMET SNAP W/SLF &VLC STRAP": "N142187",
      "FLASHLIGHT STRAP 2.5X9 WITH VELCRO": "N142298",
      "HANGING STRAP WITH D-RING": "N142190"
    },
    optionGroup: {
      "MICROPHONE STRAP 1X3": "RIGHT CHEST NEXT TO STORMFLAP",
      "HANGING STRAP WITH D-RING": "RIGHT CHEST NEXT TO STORMFLAP",
      "STRAP 1X12 W/1X4 VELCRO": "RIGHT CHEST NEXT TO STORMFLAP",
      "HELMET SNAP W/SLF &VLC STRAP": "RIGHT CHEST NEXT TO STORMFLAP",
      "FLASHLIGHT STRAP 2.5X9 WITH VELCRO": "RIGHT CHEST NEXT TO STORMFLAP",
      "HANGING STRAP WITH D-RING": "RIGHT CHEST NEXT TO STORMFLAP"
    },
    optionType: {
      "MICROPHONE STRAP 1X3": "1942564",
      "HANGING STRAP WITH D-RING": "1942590H",
      "STRAP 1X12 W/1X4 VELCRO": "N142109",
      "HELMET SNAP W/SLF &VLC STRAP": "N142187",
      "FLASHLIGHT STRAP 2.5X9 WITH VELCRO": "N142298",
      "HANGING STRAP WITH D-RING": "HELMET N142190"
    },
    gear: "Jacket"
  },

  leftChestSFPockets: {
    sortValue : 28,
    label: "Left Pockets",
    attribute: "LEFT POCKETS",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "RADIO POCKET 3X3.5X9": "1942413B",
      "RADIO POCKET 2X3.5X8": "1942413C",
      "RADIO POCKET 3X2.5X7": "1942413D"
    },
    optionGroup: {
      "RADIO POCKET 3X3.5X9": "LEFT CHEST NEXT TO STORMFLAP",
      "RADIO POCKET 2X3.5X8": "LEFT CHEST NEXT TO STORMFLAP",
      "RADIO POCKET 3X2.5X7": "LEFT CHEST NEXT TO STORMFLAP"
    },
    optionType: {
      "RADIO POCKET 3X3.5X9": "1942413B",
      "RADIO POCKET 2X3.5X8": "1942413C",
      "RADIO POCKET 3X2.5X7": "1942413D"
    },
    gear: "Jacket"
  },

  leftChestSFTrimOverPocket: {
    sortValue : 29,
    label: "Left Trim Over Pocket",
    attribute: "LEFT TRIM OVER POCKET",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: "NA",
    optionGroup: "LEFT CHEST NEXT TO STORMFLAP",
    optionType: "NA"
  },

  leftChestSFMic: {
    sortValue : 30,
    label: "Left Holder Microphone",
    attribute: "LEFT CHEST MIC",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "MICROPHONE STRAP 1X3": "1942564"
    },
    optionGroup: {
      "MICROPHONE STRAP 1X3": "LEFT CHEST NEXT TO STORMFLAP"
    },
    optionType: {
      "MICROPHONE STRAP 1X3": "1942564"
    },
    gear: "Jacket"
  },

  leftChestSFHoldersStormFlap: {
    sortValue : 31,
    label: "Left Holders",
    attribute: "LEFT HOLDERS",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "MICROPHONE STRAP 1X3": "1942564",
      "HANGING STRAP WITH D-RING": "1942590H",
      "STRAP 1X12 W/1X4 VELCRO": "N142109",
      "HELMET SNAP W/SLF &VLC STRAP": "N142187",
      "SELF HSNP/MIC/2.5X9": "N142267",
      "FLASHLIGHT STRAP 2.5X9 WITH VELCRO": "N142298",
      "HELMET SNAP W/SLF STRAP": "N142190",
      "SURVIVOR STRAP": "1942548",
      "SUNLANCE FLASHLIGHT HOLDER": "1942546",
      "SUNLANCE W/SLF HELMET SNAP ABOVE": "1942546C"
    },
    optionGroup: {
      "MICROPHONE STRAP 1X3": "LEFT CHEST NEXT TO STORMFLAP",
      "HANGING STRAP WITH D-RING": "LEFT CHEST NEXT TO STORMFLAP",
      "STRAP 1X12 W/1X4 VELCRO": "LEFT CHEST NEXT TO STORMFLAP",
      "HELMET SNAP W/SLF &VLC STRAP": "LEFT CHEST NEXT TO STORMFLAP",
      "SELF HSNP/MIC/2.5X9": "LEFT CHEST NEXT TO STORMFLAP",
      "FLASHLIGHT STRAP 2.5X9 WITH VELCRO": "LEFT CHEST NEXT TO STORMFLAP",
      "HELMET SNAP W/SLF STRAP": "LEFT CHEST NEXT TO STORMFLAP",
      "SURVIVOR STRAP": "LEFT CHEST NEXT TO STORMFLAP",
      "SUNLANCE FLASHLIGHT HOLDER": "LEFT CHEST NEXT TO STORMFLAP",
      "SUNLANCE W/SLF HELMET SNAP ABOVE": "LEFT CHEST NEXT TO STORMFLAP"
    },
    optionType: {
      "MICROPHONE STRAP 1X3": "1942564",
      "HANGING STRAP WITH D-RING": "1942590H",
      "STRAP 1X12 W/1X4 VELCRO": "N142109",
      "HELMET SNAP W/SLF &VLC STRAP": "N142187",
      "SELF HSNP/MIC/2.5X9": "N142267",
      "FLASHLIGHT STRAP 2.5X9 WITH VELCRO": "N142298",
      "HELMET SNAP W/SLF STRAP": "N142190",
      "SURVIVOR STRAP": "1942548",
      "SUNLANCE FLASHLIGHT HOLDER": "1942546",
      "SUNLANCE W/SLF HELMET SNAP ABOVE": "1942546C"
    },
    gear: "Jacket"
  },

  leftChestSFAntennaNotch: {
    sortValue : 32,
    label: "Left Antenna Notch",
    attribute: "LEFT ANTENNA NOTCH",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "RADIO FLAP DUAL NOTCH TAB 3": "N142505",
      "RADIO FLAP NOTCH TAB 3": "N1425035"
    },
    optionGroup: {
      "RADIO FLAP DUAL NOTCH TAB 3": "LEFT CHEST NEXT TO STORMFLAP",
      "RADIO FLAP NOTCH TAB 3": "LEFT CHEST NEXT TO STORMFLAP"
    },
    optionType: {
      "RADIO FLAP DUAL NOTCH TAB 3": "N142505",
      "RADIO FLAP NOTCH TAB 3": "N1425035"
    },
    gear: "Jacket"
  },

  leftChestSleeveHolders: {
    sortValue : 33,
    label: "Pockets",
    attribute: "LEFT TWD SLEEVE HOLDERS",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "MICROPHONE STRAP 1X3": "1942564",
      "HANGING STRAP WITH D-RING": "1942590H",
      "STRAP 1X12 W/1X4 VELCRO": "N142109",
      "HELMET SNAP W/SLF &VLC STRAP": "N142187",
      "FLASHLIGHT STRAP 2.5X9 WITH VELCRO": "N142298",
      "SURVIVOR STRAP": "1942548"
    },
    optionGroup: {
      "MICROPHONE STRAP 1X3": "LEFT CHEST NEXT TO STORMFLAP",
      "HANGING STRAP WITH D-RING": "LEFT CHEST NEXT TO STORMFLAP",
      "STRAP 1X12 W/1X4 VELCRO": "LEFT CHEST NEXT TO STORMFLAP",
      "HELMET SNAP W/SLF &VLC STRAP": "LEFT CHEST NEXT TO STORMFLAP",
      "FLASHLIGHT STRAP 2.5X9 WITH VELCRO": "LEFT CHEST NEXT TO STORMFLAP",
      "SURVIVOR STRAP": "LEFT CHEST NEXT TO STORMFLAP"
    },
    optionType: {
      "MICROPHONE STRAP 1X3": "1942564",
      "HANGING STRAP WITH D-RING": "1942590H",
      "STRAP 1X12 W/1X4 VELCRO": "N142109",
      "HELMET SNAP W/SLF &VLC STRAP": "N142187",
      "FLASHLIGHT STRAP 2.5X9 WITH VELCRO": "N142298",
      "SURVIVOR STRAP": "1942548"
    },
    gear: "Jacket"
  },

  rightFrontNextSFHolders: {
    sortValue : 34,
    label: "Left Holders",
    cssClass: "Right Holders",
    attribute: "RIGHT FRONT NEXT TO STORMFLAP",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "HELMET SNAP W/SLF STRAP": "N142190",
      "HANGING STRAP WITH D-RING": "1942590H"
    },
    optionGroup: {
      "HELMET SNAP W/SLF STRAP": "RIGHT FRONT NEXT TO STORMFLAP",
      "HANGING STRAP WITH D-RING": "RIGHT FRONT NEXT TO STORMFLAP"
    },
    optionType: {
      "HELMET SNAP W/SLF STRAP": "N142190",
      "HANGING STRAP WITH D-RING": "1942590H"
    },
    gear: "Jacket"
  },

  leftFrontNextSFHolders: {
    sortValue : 35,
    label: "Right Holders",
    cssClass: "Left Holders",
    attribute: "LEFT FRONT NEXT TO STORMFLAP",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "HELMET SNAP W/SLF STRAP": "N142190",
      "HANGING STRAP WITH D-RING": "1942590H"
    },
    optionGroup: {
      "HELMET SNAP W/SLF STRAP": "LEFT FRONT NEXT TO STORMFLAP",
      "HANGING STRAP WITH D-RING": "LEFT FRONT NEXT TO STORMFLAP"
    },
    optionType: {
      "HELMET SNAP W/SLF STRAP": "N142190",
      "HANGING STRAP WITH D-RING": "1942590H"
    },
    gear: "Jacket"
  },

  rightFlags: {
    sortValue : 36,
    label: "Right Flags",
    attribute: "RIGHT FLAGS",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "U.S. FLAG EMBROIDERY RIGHT SLEEVE": "N100107E",
      "CANADIAN FLAG EMBROIDERY RIGHT SLEEVE": "N100107E",
    },
    optionGroup: {
      "U.S. FLAG EMBROIDERY RIGHT SLEEVE": "RIGHT SLEEVE",
      "CANADIAN FLAG EMBROIDERY RIGHT SLEEVE": "RIGHT SLEEVE",
    },
    optionType: {
      "U.S. FLAG EMBROIDERY RIGHT SLEEVE": "N100107E",
      "CANADIAN FLAG EMBROIDERY RIGHT SLEEVE": "N100107E",
    },
    gear: "Jacket"
  },

  rightEmbroidery: {
    sortValue : 37,
    label: "Right Custom Patches",
    attribute: "RIGHT EMBROIDERY PATCHES",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "CUSTOM SILVER PRINT PATCH SLEEVE": "CPPS",
      "CUSTOM BLACK PRINT PATCH SLEEVE": "CPPS",
      "CUSTOM GOLD PRINT PATCH SLEEVE": "CPPS",
      "CUSTOM YELLOW PRINT PATCH SLEEVE": "CPPS"
    },
    optionGroup: {
      "CUSTOM SILVER PRINT PATCH SLEEVE": "RIGHT SLEEVE",
      "CUSTOM BLACK PRINT PATCH SLEEVE": "RIGHT SLEEVE",
      "CUSTOM GOLD PRINT PATCH SLEEVE": "RIGHT SLEEVE",
      "CUSTOM YELLOW PRINT PATCH SLEEVE": "RIGHT SLEEVE"
    },
    optionType: {
      "CUSTOM SILVER PRINT PATCH SLEEVE": "CPPS",
      "CUSTOM BLACK PRINT PATCH SLEEVE": "CPPS",
      "CUSTOM GOLD PRINT PATCH SLEEVE": "CPPS",
      "CUSTOM YELLOW PRINT PATCH SLEEVE": "CPPS"
    },
    gear: "Jacket"
  },

  rightEmbroideryPadding: {
    sortValue : 38,
    label: "Right Custom Printed Patch Padding",
    attribute: "RIGHT EMBROIDERY PADDING",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "RSLV CPPS": "1975R615"
    },
    optionGroup: {
      "RSLV CPPS": "RIGHT SLEEVE"
    },
    optionType: {
      "RSLV CPPS": "1975R615"
    },
    gear: "Jacket"
  },

  leftFlags: {
    sortValue : 39,
    label: "Left Flags",
    attribute: "LEFT FLAGS",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "U.S. FLAG EMBROIDERY LEFT SLEEVE": "N100107L",
      "CANADIAN FLAG EMBROIDERY LEFT SLEEVE": "N100107L",
    },
    optionGroup: {
      "U.S. FLAG EMBROIDERY LEFT SLEEVE": "LEFT SLEEVE",
      "CANADIAN FLAG EMBROIDERY LEFT SLEEVE": "LEFT SLEEVE",
    },
    optionType: {
      "U.S. FLAG EMBROIDERY LEFT SLEEVE": "N100107L",
      "CANADIAN FLAG EMBROIDERY LEFT SLEEVE":"N100107L",
    },
    gear: "Jacket"
  },

  leftEmbroidery: {
    sortValue : 40,
    label: "Left Custom Patches",
    attribute: "LEFT EMBROIDERY PATCHES",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "CUSTOM SILVER PRINT PATCH SLEEVE": "CPPS",
      "CUSTOM BLACK PRINT PATCH SLEEVE": "CPPS",
      "CUSTOM GOLD PRINT PATCH SLEEVE": "CPPS",
      "CUSTOM YELLOW PRINT PATCH SLEEVE": "CPPS"
    },
    optionGroup: {
      "CUSTOM SILVER PRINT PATCH SLEEVE": "LEFT SLEEVE",
      "CUSTOM BLACK PRINT PATCH SLEEVE": "LEFT SLEEVE",
      "CUSTOM GOLD PRINT PATCH SLEEVE": "LEFT SLEEVE",
      "CUSTOM YELLOW PRINT PATCH SLEEVE": "LEFT SLEEVE"
    },
    optionType: {
      "CUSTOM SILVER PRINT PATCH SLEEVE": "CPPS",
      "CUSTOM BLACK PRINT PATCH SLEEVE": "CPPS",
      "CUSTOM GOLD PRINT PATCH SLEEVE": "CPPS",
      "CUSTOM YELLOW PRINT PATCH SLEEVE": "CPPS"
    },
    gear: "Jacket"
  },
  
  leftEmbroideryPadding: {
    sortValue : 41,
    label: "Left Custom Printed Patch Padding",
    attribute: "LEFT EMBROIDERY PADDING",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "LSLV CPPS": "1975L615"
    },
    optionGroup: {
      "LSLV CPPS": "LEFT SLEEVE"
    },
    optionType: {
      "LSLV CPPS": "1975L615"
    },
    gear: "Jacket"
  },

 

  /* LETTERING */
  lettersAText: {
    sortValue : 42,
    label: "A Letters Text",
    attribute: "Row A Lettering Input",
    type: "textInput",
    data: "tk-platform"
  },
  letterASize: {
    sortValue : 43,
    label: "Letters Size",
    attribute: "Row A Size Input",
    type: "radioButtons",
    data: "tk-platform"
  },
  lettersAColor: {
    sortValue : 44,
    label: "Letters Color",
    attribute: "Row A Color Input",
    type: "radioButtons",
    data: "tk-platform"
  },

  letterBSize: {
    sortValue : 45,
    label: "B Letters Size",
    attribute: "Row B Size Input",
    type: "radioButtons",
    data: "tk-platform"
  },

  lettersBColor: {
    sortValue : 46,
    label: "B Letters Color",
    attribute: "Row B Color Input",
    type: "radioButtons",
    data: "tk-platform"
  },

  lettersBText: {
    sortValue : 47,
    label: "B Letters Text",
    attribute: "Row B Lettering Input",
    type: "textInput",
    data: "tk-platform"
  },

  letterCSize: {
    sortValue : 48,
    label: "C Letters Size",
    attribute: "Row C Size Input",
    type: "radioButtons",
    data: "tk-platform"
  },

  lettersCColor: {
    sortValue : 49,
    label: "C Letters Color",
    attribute: "Row C Color Input",
    type: "radioButtons",
    data: "tk-platform"
  },

  lettersCText: {
    sortValue : 50,
    label: "C Letters Text",
    attribute: "Row C Lettering Input",
    type: "textInput",
    data: "tk-platform"
  },

  letterDSize: {
    sortValue : 51,
    label: "D Letters Size",
    attribute: "Row D Size Input",
    type: "radioButtons",
    data: "tk-platform"
  },

  // lettersDColor: {
  //   label: 'D Letters Color',
  //   attribute: 'Row D Color Input',
  //   type: 'radioButtons',
  //   data: 'tk-platform',
  // },

  // lettersDText: {
  //   label: 'D Letters Text',
  //   attribute: 'Row D Lettering Input',
  //   type: 'textInput',
  //   data: 'tk-platform',

  // },

  letterESize: {
    sortValue : 52,
    label: "E Letters Size",
    attribute: "Row E Size Input",
    type: "radioButtons",
    data: "tk-platform"
  },

  lettersEColor: {
    sortValue : 53,
    label: "E Letters Color",
    attribute: "Row E Color Input",
    type: "radioButtons",
    data: "tk-platform"
  },

  lettersEText: {
    sortValue : 54,
    label: "E Letters Text",
    attribute: "Row E Lettering Input",
    type: "textInput",
    data: "tk-platform"
  },

  letterFSize: {
    sortValue : 55,
    label: "F Letters Size",
    attribute: "Row F Size Input",
    type: "radioButtons",
    data: "tk-platform"
  },

  lettersFColor: {
    sortValue : 56,
    label: "F Letters Color",
    attribute: "Row F Color Input",
    type: "radioButtons",
    data: "tk-platform"
  },

  lettersFText: {
    sortValue : 57,
    label: "F Letters Text",
    attribute: "Row F Lettering Input",
    type: "textInput",
    data: "tk-platform"
  },
  /*
        letterGSize: {
          label: 'G Letters Size',
          attribute: 'G LETTERS SIZE',
          type: 'radioButtons',
          data: 'tk-platform',
          optionNumber: '00005',
          optionGroup:'Group3',
          optionType:'Type 1'
        },
      
        lettersGColor: {
          label: 'G Letters Color',
          attribute: 'G LETTERS COLOR',
          type: 'radioButtons',
          data: 'tk-platform',
          optionNumber: '00006',
          optionGroup:'Group3',
          optionType:'Type 1'
        },
      
        lettersGText: {
          label: 'G Letters Text',
          attribute: 'G LETTERS TEXT',
          type: 'radioButtons',
          data: 'tk-platform',
          optionNumber: '00006',
          optionGroup:'Group3',
          optionType:'Type 1'
        },
      */
  letterPatch: {
    sortValue : 58,
    label: "PATCH",
    attribute: "HANGING LETTER PATCH",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "LETTER PATCH SNP/VLC HANG 5X20": "N142132"
    },
    optionGroup: {
      "LETTER PATCH SNP/VLC HANG 5X20": "HANGING LETTER PATCH"
    },
    optionType: {
      "LETTER PATCH SNP/VLC HANG 5X20": "N142132"
    },
    gear: "Jacket"
  },

  letterSize: {
    sortValue : 59,
    label: "Letters Size",
    attribute: "Patch Lettering Size Input",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      '2" SCOTCHLITE LETTERS SEWN': "19720",
      '3" SCOTCHLITE LETTERS SEWN': "19721"
    },
    optionGroup: {
      '2" SCOTCHLITE LETTERS SEWN': "LTRG",
      '3" SCOTCHLITE LETTERS SEWN': "LTRG"
    },
    optionType: {
      '2" SCOTCHLITE LETTERS SEWN': "2IN",
      '3" SCOTCHLITE LETTERS SEWN': "3IN"
    },
    gear: "Jacket"
  },

  lettersColor: {
    sortValue : 60,
    label: "Letters Color",
    attribute: "Patch Lettering Color Input",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "Lime/Yellow": "NA",
      "Red/Orange": "NA"
    },
    optionGroup: {
      "Lime/Yellow": "NA",
      "Red/Orange": "NA"
    },
    optionType: {
      "Lime/Yellow": "NA",
      "Red/Orange": "NA"
    },
    gear: "Jacket"
  },

  lettersText: {
    sortValue : 61,
    label: "Letters Text",
    attribute: "Patch Lettering Input",
    type: "textInput",
    data: "tk-platform",
    optionNumber: "NA",
    optionGroup: "NA",
    optionType: "NA"
  },

  sleeveWellConfiguration: {
    sortValue : 62,
    label: "Sleeve Well",
    attribute: "SLEEVE WELL",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "SLVWL W/GRYNMX WRISTER ATX": "N1STWRA1"
    },
    optionGroup: {
      "SLVWL W/GRYNMX WRISTER ATX": "OTHER"
    },
    optionType: {
      "SLVWL W/GRYNMX WRISTER ATX": "N1STWRA1"
    },
    gear: "Jacket"
  },

  wristerLiner: {
    sortValue : 63,
    label: "Wrister (Liner)",
    attribute: "WRISTER (LINER)",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "GRY NOMEX WRISTER LINER": "N1STWLA1",
      "GRY NOMEX HAND AND WRIST GUARD LINER": "1905G9NL"
    },
    optionGroup: {
      "GRY NOMEX WRISTER LINER": "OTHER",
      "GRY NOMEX HAND AND WRIST GUARD LINER": "OTHER"
    },
    optionType: {
      "GRY NOMEX WRISTER LINER": "N1STWLA1",
      "GRY NOMEX HAND AND WRIST GUARD LINER": "1905G9NL"
    },
    gear: "Jacket"
  },

  wristerLinerThumbHoleLoop: {
    sortValue : 64,
    label: "Wrister (Liner) Thumb Hole Loop",
    attribute: "WRISTER (LINER) THUMB HOLE LOOP",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "THUMB HOLE LOOP LINER": "N100321L"
    },
    optionGroup: {
      "THUMB HOLE LOOP LINER": "OTHER"
    },
    optionType: {
      "THUMB HOLE LOOP LINER": "N100321L"
    },
    gear: "Jacket"
  },

  sleeveCuffs: {
    sortValue : 65,
    label: "Sleeve Cuffs",
    attribute: "SLEEVE CUFFS",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "CUFF REINFORCEMENT DRAGONHIDE": "N1STCRA1",
      "CUFF REINFORCEMENT ARASHIELD": "1988507"
    },
    optionGroup: {
      "CUFF REINFORCEMENT DRAGONHIDE": "OTHER",
      "CUFF REINFORCEMENT ARASHIELD": "OTHER"
    },
    optionType: {
      "CUFF REINFORCEMENT DRAGONHIDE": "N1STCRA1",
      "CUFF REINFORCEMENT ARASHIELD": "1988507"
    },
    gear: "Jacket"
  },

  shoulderPadding: {
    sortValue : 66,
    label: "Shoulder Padding",
    attribute: "SHOULDER PADDING",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "TH SHOULDER 2LYR ATX": "N1STCCHA"
    },
    optionGroup: {
      "TH SHOULDER 2LYR ATX": "OTHER"
    },
    optionType: {
      "TH SHOULDER 2LYR ATX": "N1STCCHA"
    },
    gear: "Jacket"
  },

  globeguard: {
    sortValue : 67,
    label: "Globe Guard",
    attribute: "GLOBE GUARD",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "COAT GLOBE GUARD GMB": "N100GGG",
      "COAT GLOBE GUARD NEOP": "N100GGN"
    },
    optionGroup: {
      "COAT GLOBE GUARD GMB": "OTHER",
      "COAT GLOBE GUARD NEOP": "OTHER"
    },
    optionType: {
      "COAT GLOBE GUARD GMB": "N100GGG",
      "COAT GLOBE GUARD NEOP": "N100GGN"
    },
    gear: "Jacket"
  },

  neckHangerLoop: {
    sortValue : 68,
    label: "Neck Hanger Loop",
    attribute: "NECK HANGER LOOP",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "CLR LOOP": "N1STCH01"
    },
    optionGroup: {
      "CLR LOOP": "OTHER"
    },
    optionType: {
      "CLR LOOP": "N1STCH01"
    },
    gear: "Jacket"
  },

  hemSnapTabsSell: {
    sortValue : 69,
    label: "Hem Snap Tabs (Shell)",
    attribute: "HEM SNAP TABS (SHELL)",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "HEM SNAP TABS ATX (2)": "N100140A",
      "HEM SNAP TABS (3) SHELL": "190625"
    },
    optionGroup: {
      "HEM SNAP TABS ATX (2)": "OTHER",
      "HEM SNAP TABS (3) SHELL": "OTHER"
    },
    optionType: {
      "HEM SNAP TABS ATX (2)": "N100140A",
      "HEM SNAP TABS (3) SHELL": "190625"
    },
    gear: "Jacket"
  },

  hemSnapTabsLiner: {
    sortValue : 70,
    label: "Hem Snap Tabs (Liner)",
    attribute: "HEM SNAP TABS (LINER)",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "HEM SNAP TABS ATX (2) LINER": "N100L40A",
      "HEM SNAP TABS (3) LINER": "190L25"
    },
    optionGroup: {
      "HEM SNAP TABS ATX (2) LINER": "OTHER",
      "HEM SNAP TABS (3) LINER": "OTHER"
    },
    optionType: {
      "HEM SNAP TABS ATX (2) LINER": "N100L40A",
      "HEM SNAP TABS (3) LINER": "190L25"
    },
    gear: "Jacket"
  },

  thermalLinerPocket: {
    sortValue : 71,
    label: "Thermal Liner Pocket",
    attribute: "THERMAL LINER POCKET",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "TH SQUARE POCKET": "N1STLP02"
    },
    optionGroup: {
      "TH SQUARE POCKET": "OTHER"
    },
    optionType: {
      "TH SQUARE POCKET": "N1STLP02"
    },
    gear: "Jacket"
  },

  linerAttachmentShell: {
    sortValue : 72,
    label: "Liner Attachment (Shell)",
    attribute: "LINER ATTACHMENT (SHELL)",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "NECK SNAPS COLLAR": "N1STNSS1"
    },
    optionGroup: {
      "NECK SNAPS COLLAR": "OTHER"
    },
    optionType: {
      "NECK SNAPS COLLAR": "N1STNSS1"
    },
    gear: "Jacket"
  },

  linerAttachmentLiner: {
    sortValue : 73,
    label: "Liner Attachment (Liner)",
    attribute: "LINER ATTACHMENT (LINER)",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "NECK SNAPS LINER": "N1STNSL1"
    },
    optionGroup: {
      "NECK SNAPS LINER": "OTHER"
    },
    optionType: {
      "NECK SNAPS LINER": "N1STNSL1"
    },
    gear: "Jacket"
  },

  drdStrap: {
    sortValue : 74,
    label: "DRD Strap",
    attribute: "DRD STRAP",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      DRD: "N1STDRD"
    },
    optionGroup: {
      DRD: "OTHER"
    },
    optionType: {
      DRD: "N1STDRD"
    },
    gear: "Jacket"
  },

  deletePiping: {
    sortValue : 75,
    label: "Piping",
    attribute: "PIPING",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "Show Piping": "N100DPIP",
      "Hide Piping": "N100DPIP"
    },
    optionGroup: {
      "Show Piping": "OTHER",
      "Hide Piping": "OTHER"
    },
    optionType: {
      "Show Piping": "N100DPIP",
      "Hide Piping": "N100DPIP"
    },
    gear: "Jacket"
  },

  /**************************************************
   *************** Pants Options ********************
   **************************************************/

  /*************** FRONT ****************************/
  PANT_FRONT_FRONTCLOSURE_CLOSURE: {
    sortValue : 76,
    label: "CLOSURE",
    attribute: "PANT_FRONT_FRONTCLOSURE_CLOSURE",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "BLTLPS WIDE ATX": "N2STWC09"
    },
    optionGroup: {
      "BLTLPS WIDE ATX": "MISCELLANEOUS"
    },
    optionType: {
      "BLTLPS WIDE ATX": "LOOP"
    },
    gear: "Pants"
  },

  PANT_FRONT_FRONTCLOSURE_BELT_LOOPS: {
    sortValue : 77,
    label: "Belt Loops",
    attribute: "PANT_FRONT_FRONTCLOSURE_BELT_LOOPS",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "BLTLPS WIDE ATX": "N2STWC09"
    },
    optionGroup: {
      "BLTLPS WIDE ATX": "FRONT"
    },
    optionType: {
      "BLTLPS WIDE ATX": "N2STWC09"
    },
    gear: "Pants"
  },

  PANT_FRONT_FRONTCLOSURE_BELT: {
    sortValue : 78,
    label: "Belt",
    attribute: "PANT_FRONT_FRONTCLOSURE_BELT",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "BLK NOMEX BELT": "N2STWC02",
      "ESCAPE BELT": "N200301",
      "SERIES 1 BUCKLE": "N200289",
      "SERIES 2 HOOK AND DEE": "N200285D"
    },
    optionGroup: {
      "BLK NOMEX BELT": "FRONT",
      "ESCAPE BELT": "FRONT",
      "SERIES 1 BUCKLE": "FRONT",
      "SERIES 2 HOOK AND DEE": "FRONT"
    },
    optionType: {
      "BLK NOMEX BELT": "N2STWC02",
      "ESCAPE BELT": "N200301",
      "SERIES 1 BUCKLE": "N200289",
      "SERIES 2 HOOK AND DEE": "N200285D"
    },
    gear: "Pants"
  },

  PANT_FRONT_FRONTCLOSURE_CARABINER: {
    sortValue : 79,
    label: "Carabiner",
    attribute: "PANT_FRONT_FRONTCLOSURE_CARABINER",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "CMC CARABINER": "N2CBCMC",
      "YATES CARABINER": "N2CBYATE"
    },
    optionGroup: {
      "CMC CARABINER": "FRONT",
      "YATES CARABINER": "FRONT"
    },
    optionType: {
      "CMC CARABINER": "N2CBCMC",
      "YATES CARABINER": "N2CBYATE"
    },
    gear: "Pants"
  },

  PANT_FRONT_FRONTCLOSURE_RAPPELLING: {
    sortValue : 80,
    label: "Rappelling Loops",
    attribute: "PANT_FRONT_FRONTCLOSURE_RAPPELLING LOOPS",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "9 ARASHIELD RAPLPS": "2988602V"
    },
    optionGroup: {
      "9 ARASHIELD RAPLPS": "FRONT"
    },
    optionType: {
      "9 ARASHIELD RAPLPS": "2988602V"
    },
    gear: "Pants"
  },

  /*************** SIDE POCKETS RIGHT **********************/
  PANT_SIDE_POCKETS_RIGHT_SIZE_TYPE: {
    sortValue : 81,
    label: "Size & Type Right",
    attribute: "PANT_SIDE_POCKETS_RIGHT_SIZE_TYPE",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "SLNT EXPANSION POCKET 2x10x8/11": "N2STPKA1",
      "ESCAPE SYSTEM POCKET CROSBY 2X7X8 RPKT": "N242285",
      "ESCAPE SYSTEM POCKET CMC 2x8x9 RPKT": "N242389R"
    },
    optionGroup: {
      "SLNT EXPANSION POCKET 2x10x8/11": "SIDE POCKETS (RIGHT)",
      "ESCAPE SYSTEM POCKET CROSBY 2X7X8 RPKT": "SIDE POCKETS (RIGHT)",
      "ESCAPE SYSTEM POCKET CMC 2x8x9 RPKT": "SIDE POCKETS (RIGHT)"
    },
    optionType: {
      "SLNT EXPANSION POCKET 2x10x8/11": "N2STPKA1",
      "ESCAPE SYSTEM POCKET CROSBY 2X7X8 RPKT": "N242285",
      "ESCAPE SYSTEM POCKET CMC 2x8x9 RPKT": "N242389R"
    },
    gear: "Pants"
  },

  PANT_SIDE_POCKETS_RIGHT_INSIDE_REINFORCEMENT: {
    sortValue : 82,
    label: "Inside Reinforcement Right",
    attribute: "PANT_SIDE_POCKETS_RIGHT_INSIDE_REINFORCEMENT",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "KEV POUCH EXPANSION SLANT POCKET": "N2STPRA1",
      "KEV POUCH 2x7x8": "N2KT334",
      "KEV POUCH 2x8x9": "N2KT389"
    },
    optionGroup: {
      "KEV POUCH EXPANSION SLANT POCKET": "SIDE POCKETS (RIGHT)",
      "KEV POUCH 2x7x8": "SIDE POCKETS (RIGHT)",
      "KEV POUCH 2x8x9": "SIDE POCKETS (RIGHT)"
    },
    optionType: {
      "KEV POUCH EXPANSION SLANT POCKET": "N2STPRA1",
      "KEV POUCH 2x7x8": "N2KT334",
      "KEV POUCH 2x8x9": "N2KT389"
    },
    gear: "Pants"
  },

  PANT_SIDE_POCKETS_RIGHT_OUTSITE_REINFORCEMENT: {
    sortValue : 83,
    label: "Outside Reinforcement Right",
    attribute: "PANT_SIDE_POCKETS_RIGHT_OUTSITE_REINFORCEMENT",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "DRAGONHIDE FRONT EXPANSION 2X10X8/11": "294SLDH"
    },
    optionGroup: {
      "DRAGONHIDE FRONT EXPANSION 2X10X8/11": "SIDE POCKETS (RIGHT)"
    },
    optionType: {
      "DRAGONHIDE FRONT EXPANSION 2X10X8/11": "294SLDH"
    },
    gear: "Pants"
  },

  PANT_SIDE_POCKETS_RIGHT_PULL_TAB: {
    sortValue : 84,
    label: "Pull Tab Right",
    attribute: "PANT_SIDE_POCKETS_RIGHT_PULL_TAB",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "BLK ARASHIELD POCKET PULL TAB": "N1STPTA1"
    },
    optionGroup: {
      "BLK ARASHIELD POCKET PULL TAB": "SIDE POCKETS (RIGHT)"
    },
    optionType: {
      "BLK ARASHIELD POCKET PULL TAB": "N1STPTA1"
    },
    gear: "Pants"
  },

  PANT_SIDE_POCKETS_RIGHT_POCKET_DIVIDER: {
    sortValue : 85,
    label: "Pocket Divider Right",
    attribute: "PANT_SIDE_POCKETS_RIGHT_POCKET_DIVIDER",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      'DIVIDER KEVLAR EXP/SEMI 9" HIGH': "N2KT239"
    },
    optionGroup: {
      'DIVIDER KEVLAR EXP/SEMI 9" HIGH': "SIDE POCKETS (RIGHT)"
    },
    optionType: {
      'DIVIDER KEVLAR EXP/SEMI 9" HIGH': "N2KT239"
    },
    gear: "Pants"
  },

  PANT_SIDE_POCKETS_RIGHT_TOOL_COMPARTMENT: {
    sortValue : 86,
    label: "Tool Compartment Right",
    attribute: "PANT_SIDE_POCKETS_RIGHT_TOOL_COMPARTMENT",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "ARASHIELD TOOL 6 COMPARTMENT 2X10X10": "N288267"
    },
    optionGroup: {
      "ARASHIELD TOOL 6 COMPARTMENT 2X10X10": "SIDE POCKETS (RIGHT)"
    },
    optionType: {
      "ARASHIELD TOOL 6 COMPARTMENT 2X10X10": "N288267"
    },
    gear: "Pants"
  },

  /*************** SIDE POCKETS LEFT **********************/
  PANT_SIDE_POCKETS_LEFT_SIZE_TYPE: {
    sortValue : 87,
    label: "Size & Type Left",
    attribute: "PANT_SIDE_POCKETS_LEFT_SIZE_TYPE",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "SLNT EXPANSION POCKET 2X10X8/11": "N2STPKA1",
      "ESCAPE SYSTEM POCKET CROSBY 2X7X8 LPKT": "N242285L",
      "ESCAPE SYSTEM POCKET CMC 2X8X9 LPKT": "N242389"
    },
    optionGroup: {
      "SLNT EXPANSION POCKET 2X10X8/11": "SIDE POCKETS (LEFT)",
      "ESCAPE SYSTEM POCKET CROSBY 2X7X8 LPKT": "SIDE POCKETS (LEFT)",
      "ESCAPE SYSTEM POCKET CMC 2X8X9 LPKT": "SIDE POCKETS (LEFT)"
    },
    optionType: {
      "SLNT EXPANSION POCKET 2X10X8/11": "N2STPKA1",
      "ESCAPE SYSTEM POCKET CROSBY 2X7X8 LPKT": "N242285L",
      "ESCAPE SYSTEM POCKET CMC 2X8X9 LPKT": "N242389"
    },
    gear: "Pants"
  },

  PANT_SIDE_POCKETS_LEFT_INSIDE_REINFORCEMENT: {
    sortValue : 88,
    label: "Inside Reinforcement Left",
    attribute: "PANT_SIDE_POCKETS_LEFT_INSIDE_REINFORCEMENT",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "KEV POUCH EXPANSION SLANT POCKET": "N2STPRA1",
      "KEV POUCH 2x7x8": "N2KT334",
      "KEV POUCH 2x8x9": "N2KT389"
    },
    optionGroup: {
      "KEV POUCH EXPANSION SLANT POCKET": "SIDE POCKETS (LEFT)",
      "KEV POUCH 2x7x8": "SIDE POCKETS (LEFT)",
      "KEV POUCH 2x8x9": "SIDE POCKETS (LEFT)"
    },
    optionType: {
      "KEV POUCH EXPANSION SLANT POCKET": "N2STPRA1",
      "KEV POUCH 2x7x8": "N2KT334",
      "KEV POUCH 2x8x9": "N2KT389"
    },
    gear: "Pants"
  },

  PANT_SIDE_POCKETS_LEFT_OUTSIDE_REINFORCEMENT: {
    sortValue : 89,
    label: "Outside Reinforcement Left",
    attribute: "PANT_SIDE_POCKETS_LEFT_OUTSIDE_REINFORCEMENT",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "DRAGONHIDE FRONT EXPANSION 2X10X8/11": "294SLDH"
    },
    optionGroup: {
      "DRAGONHIDE FRONT EXPANSION 2X10X8/11": "SIDE POCKETS (LEFT)"
    },
    optionType: {
      "DRAGONHIDE FRONT EXPANSION 2X10X8/11": "294SLDH"
    },
    gear: "Pants"
  },

  PANT_SIDE_POCKETS_LEFT_PULL_TAB: {
    sortValue : 90,
    label: "Pull Tab Left",
    attribute: "PANT_SIDE_POCKETS_LEFT_PULL_TAB",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "BLK ARASHIELD POCKET PULL TAB": "N2STPTA1"
    },
    optionGroup: {
      "BLK ARASHIELD POCKET PULL TAB": "SIDE POCKETS (LEFT)"
    },
    optionType: {
      "BLK ARASHIELD POCKET PULL TAB": "N2STPTA1"
    },
    gear: "Pants"
  },

  PANT_SIDE_POCKETS_LEFT_POCKET_DIVIDER: {
    sortValue : 91,
    label: "Pocket Divider Left",
    attribute: "PANT_SIDE_POCKETS_LEFT_POCKET_DIVIDER",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      'DIVIDER KEVLAR EXP/SEMI 9" HIGH': "N2KT237"
    },
    optionGroup: {
      'DIVIDER KEVLAR EXP/SEMI 9" HIGH': "SIDE POCKETS (LEFT)"
    },
    optionType: {
      'DIVIDER KEVLAR EXP/SEMI 9" HIGH': "N2KT237"
    },
    gear: "Pants"
  },

  PANT_SIDE_POCKETS_LEFT_TOOL_COMPARTMENT: {
    sortValue : 92,
    label: "Tool Compartment Left",
    attribute: "PANT_SIDE_POCKETS_LEFT_TOOL_COMPARTMENT",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "ARASHIELD TOOL 6 COMPARTMENT 2X10X10": "N288267"
    },
    optionGroup: {
      "ARASHIELD TOOL 6 COMPARTMENT 2X10X10": "SIDE POCKETS (LEFT)"
    },
    optionType: {
      "ARASHIELD TOOL 6 COMPARTMENT 2X10X10": "N288267"
    },
    gear: "Pants"
  },

  /*************** OTHER ****************************/
  PANT_OTHER_PANTS_CUFF: {
    sortValue : 93,
    label: "",
    attribute: "PANT_OTHER_PANTS_CUFF",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "CUFF REINFORCEMENT DRAGONHIDE": "N2STCRA1",
      "CUFF REINFORCEMENT ARASHIELD": "2988109"
    },
    optionGroup: {
      "CUFF REINFORCEMENT DRAGONHIDE": "OTHER PANTS",
      "CUFF REINFORCEMENT ARASHIELD": "OTHER PANTS"
    },
    optionType: {
      "CUFF REINFORCEMENT DRAGONHIDE": "N2STCRA1",
      "CUFF REINFORCEMENT ARASHIELD": "2988109"
    },
    gear: "Pants"
  },

  PANT_OTHER_KNEES_REINFORCEMENT: {
    sortValue : 94,
    label: "",
    attribute: "PANT_OTHER_KNEES_REINFORCEMENT",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "KNEE SHELL DRAGONHIDE ATX": "N2STKRA1",
      "KNEE SHELL BLACK SUEDE CATHEDRAL": "290105BC"
    },
    optionGroup: {
      "KNEE SHELL DRAGONHIDE ATX": "OTHER PANTS",
      "KNEE SHELL BLACK SUEDE CATHEDRAL": "OTHER PANTS"
    },
    optionType: {
      "KNEE SHELL DRAGONHIDE ATX": "N2STKRA1",
      "KNEE SHELL BLACK SUEDE CATHEDRAL": "290105BC"
    },
    gear: "Pants"
  },

  PANT_OTHER_KNEES_PADDING_LINING: {
    sortValue : 95,
    label: "",
    attribute: "PANT_OTHER_KNEES_PADDING_LINING",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "KNEE LINER SILIZONE": "N2STLKA1",
      "KNEELNR 2LYR SILIZONE ATX": "29SLZ627"
    },
    optionGroup: {
      "KNEE LINER SILIZONE": "OTHER PANTS",
      "KNEELNR 2LYR SILIZONE ATX": "OTHER PANTS"
    },
    optionType: {
      "KNEE LINER SILIZONE": "N2STLKA1",
      "KNEELNR 2LYR SILIZONE ATX": "29SLZ627"
    },
    gear: "Pants"
  },

  PANT_OTHER_GLOBEGUARD: {
    sortValue : 96,
    label: "",
    attribute: "PANT_OTHER_GLOBEGUARD",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "PANT GLOBE GUARD GMB": "N200GGG",
      "PANT GLOBE GUARD NEOP": "N200GGN"
    },
    optionGroup: {
      "PANT GLOBE GUARD GMB": "OTHER PANTS",
      "PANT GLOBE GUARD NEOP": "OTHER PANTS"
    },
    optionType: {
      "PANT GLOBE GUARD GMB": "N200GGG",
      "PANT GLOBE GUARD NEOP": "N200GGN"
    },
    gear: "Pants"
  },

  PANT_OTHER_SUSPENDERS: {
    sortValue : 97,
    label: "",
    attribute: "PANT_OTHER_SUSPENDERS",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "REG BLACK PADDED RIPCORD HBK": "N2STSU01",
      "REGULAR BLACK PDRIP LY1.5 IN PLACE OF": "I83315T2"
    },
    optionGroup: {
      "REG BLACK PADDED RIPCORD HBK": "OTHER PANTS",
      "REGULAR BLACK PDRIP LY1.5 IN PLACE OF": "OTHER PANTS"
    },
    optionType: {
      "REG BLACK PADDED RIPCORD HBK": "N2STSU01",
      "REGULAR BLACK PDRIP LY1.5 IN PLACE OF": "I83315T2"
    },
    gear: "Pants"
  },

  PANT_OTHER_MANDATORY_CCHR: {
    sortValue : 98,
    label: "",
    attribute: "PANT_OTHER_MANDATORY_CCHR",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "KNEE LINER 2LYR ATX": "N2STCCHA"
    },
    optionGroup: {
      "KNEE LINER 2LYR ATX": "OTHER PANTS"
    },
    optionType: {
      "KNEE LINER 2LYR ATX": "N2STCCHA"
    },
    gear: "Pants"
  }
};
