// const authToken =
//   process.env.REACT_APP_PUBLIC_TOKEN || '097e1ac1-aa7d-4678-aec5-ab5083b551f7';
const orgId =
  process.env.REACT_APP_ORG_ID || '7a6c31ed-73a1-49de-8c93-51e2b91dc762';

export default {
  authToken:'671616b6-79d9-4efe-80e8-d533149531e1',  
  assetId: '6b7bdb94-44eb-4713-b192-91c78c2dd967',
  orgId,
};
