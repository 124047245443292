export default {
  /**************************************************
   *************** Material Options *****************
   **************************************************/
  GEAR_VISIBILITY: {
    sortValue : 1,
    label: "GEAR",
    attribute: "GEAR_VISIBILITY",
    type: "RadioButtonsImages",
    data: "tk-platform",
    cssClass: "active",
    optionNumber: {
      "GX3": "1/F",
      "GX3 Coat": "1",
      "GPS Pant": "F"
    },
    optionGroup: {
      "GX3": "MATERIALS",
      "GX3 Coat": "MATERIALS",
      "GPS Pant": "MATERIALS"
    },
    optionType: {
      "GX3": "1/F",
      "GX3 Coat": "1",
      "GPS Pant": "F"
    },
    gear: "GX3"
  },

  materials: {
    sortValue : 2,
    label: "Materials",
    attribute: "MATERIALS",
    type: "radioButtons",
    data: "tk-platform",
    cssClass: "active",
    optionNumber: {
      "Pioneer":"C7",
      "Agility": "G7",
      "Kombat Flex": "D2",
      "PBI Max": "C3",
      "Peak 5": "F3",
      "PBI Max LP": "58",
      "Armor AP": "D4" 
    },
    optionGroup: {
      "Pioneer":"MATERIALS",
      "Agility": "MATERIALS",
      "Kombat Flex": "MATERIALS",
      "PBI Max": "MATERIALS",
      "Peak 5": "MATERIALS",
      "PBI Max LP": "MATERIALS",
      "Armor AP": "MATERIALS" 
    },
    optionType: {
      "Pioneer":"C7",
      "Agility": "G7",
      "Kombat Flex": "D2",
      "PBI Max": "C3",
      "Peak 5": "F3",
      "PBI Max LP": "58",
      "Armor AP": "D4" 
    },
    gear: "GX3",
    tooltipContent: {
      Pioneer:{
        headImage: "TenCate",
        tooltipDescription: "This KEVLAR®/NOMEX® blend outer shell is even lighter and more flexible but still delivers the strength and durability you need due to TenCate’s innovative ENFORCE™ Technology."  
      },
      Agility:{
        headImage: "TenCate",
        tooltipDescription: "This KEVLAR®/NOMEX®/PBO blend outer shell from TenCate provides a step up in performance from their ULTRA™ outer shell due to the innovative ENFORCE™ Technology, while being lighter and more flexible."  
      },
      "Kombat Flex": {
        headImage: "tencate",
        tooltipDescription: "Using a blend of PBI® and KEVLAR® fibers, it provides exceptional performance and durability."
      },/*
      "PBI Max":{
        headImage: "tencate",
        tooltipDescription: "A blend of PBI® and KEVLAR® fibers, along with ENFORCE™ technology, allows the new PBI® PEAK 5 to maintain its strength and durability in a lighter package."
      },*/
      "Peak 5":{
        headImage: "tencate",
        tooltipDescription: "A blend of PBI® and KEVLAR® fibers, along with ENFORCE™ technology, allows the new PBI® PEAK 5 to maintain its strength and durability in a lighter package."
      },
    },
  },

  color: {
    sortValue : 3,
    label: "Color",
    attribute: "Color",
    type: "RadioButtonsImages",
    data: "tk-platform",
    cssClass: "active",
    optionNumber: {
      "Black": "01",
      "Yellow": "02",
      "Tan": "05",
      "Gold": "10",
      "Light Gold":"30",
      "Dark Gold": "20"
    },
    optionGroup: {
      "Black": "MATERIALS",
      "Yellow": "MATERIALS",
      "Tan": "MATERIALS",
      "Gold": "MATERIALS",
      "Light Gold": "MATERIALS",
      "Dark Gold": "MATERIALS"
    },
    optionType: {
      "Black": "01",
      "Yellow": "02",
      "Tan": "05",
      "Gold": "10",
      "Light Gold":"30",
      "Dark Gold": "20"
    },
    gear: "GX3"
  },

  thermalLiner: {
    sortValue : 4,
    label: "Thermal Liner",
    attribute: "THERMAL LINER",
    type: "radioButtons",
    data: "tk-platform",
    cssClass: "active",
    selected: true,
    optionNumber: {
      "Caldura Elite": "57",
      "Glide Ice (2-Layer)": "62",
      "Quantum": "53",
      "Defender (NP Brass)": "74",
      "Defender (Camo)": "81",
      "Titanium SL2": "45",
      "Titanium w/Nomex Nano": "N3",
      "Glide Ice w/Nomex Nano": "N1",
      "Parallon X1M2": "XM"
    },
    optionGroup: {
      "Caldura Elite": "MATERIALS",
      "Glide Ice (2-Layer)": "MATERIALS",
      "Quantum": "MATERIALS",
      "Defender (NP Brass)": "MATERIALS",
      "Defender (Camo)": "MATERIALS",
      "Titanium SL2": "MATERIALS",
      "Titanium w/Nomex Nano": "MATERIALS",
      "Glide Ice w/Nomex Nano": "MATERIALS",
      "Parallon X1M2": "MATERIALS"
    },
    optionType: {
      "Caldura Elite": "57",
      "Glide Ice (2-Layer)": "62",
      "Quantum": "53",
      "Defender (NP Brass)": "74",
      "Defender (Camo)": "81",
      "Titanium SL2": "45",
      "Titanium w/Nomex Nano": "N3",
      "Glide Ice w/Nomex Nano": "N1",
      "Parallon X1M2": "XM"
    },
    gear: "GX3",
    tooltipContent: {
      "Caldura Elite":{
        headImage: "TenCate",
        tooltipDescription: "This thermal liner has been our most popular, featuring a new face cloth helps you slide easily in and out of your turnout gear and the quilted liner is remarkably light and flexible."  
      },
      "Glide Ice (2-Layer)":{
        headImage: "TenCate",
        tooltipDescription: "This KEVLAR®/NOMEX®/PBO blend outer shell from TenCate provides a step up in performance from their ULTRA™ outer shell due to the innovative ENFORCE™ Technology, while being lighter and more flexible."  
      },
      "Quantum": {
        headImage: "TenCate",
        tooltipDescription: "A special waffle design is built into one of the batting layers to trap more air without adding weight, and the new face cloth weave is inherently wicking.."
      },
      "Defender (NP Brass)":{
        headImage: "TenCate",
        tooltipDescription: "Combines the superior moisture management and comfort of the DEFENDER® M face cloth with the proven protection and value of a single layer KEVLAR®/NOMEX® needlepunch blend batting."
      },
      "Defender (Camo)":{
        headImage: "tencate",
        tooltipDescription: "DEFENDER® M SL2 provides superior moisture management and comfort. The face cloth utilizes the same fiber blend as used in uniforms worn by the military."
      },
      "Titanium SL2": {
        headImage: "TenCate",
        tooltipDescription: "This new thermal liner incorporates COOLDERM™ Technology to provide integrated wicking for enhanced moisture management."
      },
      "Titanium w/Nomex Nano":{
        headImage: "TenCate",
        tooltipDescription: "NOMEX® NANO dramatically reduces the weight and bulk of current turnout gear systems, helping to give firefighters increased mobility and better range of motion. ."
      },
      "Parallon X1M2":{
        headImage: "WL Gore",
        tooltipDescription: "The new level of breathability and the enhanced thermal protection are only achievable with this unique multi-barrier liner system that replaces the moisture barrier and thermal liner in conventional gear."

      }
    },
  },

  moistureBarrier: {
    sortValue : 5,
    label: "Moisture Barrier",
    attribute: "MOISTURE BARRIER",
    type: "radioButtons",
    data: "tk-platform",
    cssClass: "active",
    optionNumber: {
      "Crosstech Black": "G",
      "Stedair 3000": "E",
      "Stedair Gold": "J",
      "Parallon B2": "B"
    },
    optionGroup: {
      "Crosstech Black": "MATERIALS",
      "Stedair 3000": "MATERIALS",
      "Stedair Gold": "MATERIALS",
      "Parallon B2": "MATERIALS"
    },
    optionType: {
      "Crosstech Black": "G",
      "Stedair 3000": "E",
      "Stedair Gold": "J",
      "Parallon B2": "B"
    },
    gear: "GX3",
    tooltipContent: {
      "Crosstech Black":{
        headImage: "WL Gore",
        tooltipDescription: "Widely recognized for being the most protective and the most breathable in the widest range of conditions, BLACK is the latest and most durable GORE® CROSSTECH® barrier yet."
      },
      "Parallon B2":{
        headImage: "WL Gore",
        tooltipDescription: "The new level of breathability and the enhanced thermal protection are only achievable with this unique multi-barrier liner system that replaces the moisture barrier and thermal liner in conventional gear."

      }
    },
  },

  /**************************************************
   *************** trim Options *****************
   **************************************************/
  coat_trim_type: {
    sortValue : 6,
    label: "Coat Trim Type",
    attribute: "COAT TRIM TYPE",
    type: "radioButtons",
    data: "tk-platform",
    selected: true,
    cssClass: "active",
    optionNumber: {
      "NFPA B": "NFPAB",
      NYC: "NYC",
      "NFPA VERT": "NFPA VERT"
    },
    optionGroup: {
      "NFPA B": "COAT TRIM",
      NYC: "COAT TRIM",
      "NFPA VERT": "COAT TRIM"
    },
    optionType: {
      "NFPA B": "17(T)703",
      NYC: "17(T)783",
      "NFPA VERT": "17(T)753"
    },
    gear: "Jacket"
  },

  coat_trim_color: {
    sortValue : 7,
    label: "Coat Trim Color",
    attribute: "COAT TRIM COLOR",
    type: "radioButtons",
    data: "tk-platform",
    selected: true,
    cssClass: "active",
    optionNumber: {
      "Red/Orange": "2",
      "Lime/Yellow": "4"
    },
    optionGroup: {
      "Red/Orange": "COAT TRIM",
      "Lime/Yellow": "COAT TRIM"
    },
    optionType: {
      "Red/Orange": "R/O",
      "Lime/Yellow": "L/Y"
    },
    gear: "Jacket"
  },

  coat_trim_material: {
    sortValue : 8,
    label: "Coat Trim Material",
    attribute: "Coat Trim Material",
    type: "radioButtons",
    data: "tk-platform",
    selected: true,
    cssClass: "active",
    optionNumber: {
      "Triple Trim": "triple trim",
      "Comfort Trim": "comfort trim",
      "Brilliance w/Stripe": "Brilliance w/Stripe"
    },
    optionGroup: {
      "Triple Trim": "COAT TRIM",
      "Comfort Trim": "COAT TRIM",
      "Brilliance w/Stripe": "COAT TRIM"
    },
    optionType: {
      "Triple Trim": "triple trim",
      "Comfort Trim": "comfort trim",
      "Brilliance w/Stripe": "Brilliance w/Stripe"
    },
    gear: "Jacket"
  },

  pants_trim_type: {
    sortValue : 9,
    label: "Pants Trim Type",
    attribute: "PANTS TRIM TYPE",
    type: "radioButtons",
    data: "tk-platform",
    selected: true,
    cssClass: "active",
    optionNumber: {
      '3" AROUND CUFFS': "27(T)03",
    },
    optionGroup: {
      '3" AROUND CUFFS': "PANT TRIM",
    },
    optionType: {
      '3" AROUND CUFFS': "27(T)03"
    },
    gear: "Pants"
  },

  pants_trim_color: {
    sortValue : 10,
    label: "Pants Trim Color",
    attribute: "PANTS TRIM COLOR",
    type: "radioButtons",
    data: "tk-platform",
    selected: true,
    cssClass: "active",
    optionNumber: {
      "Red/Orange": "R/O",
      "Lime/Yellow": "L/Y"
    },
    optionGroup: {
      "Red/Orange": "PANT TRIM",
      "Lime/Yellow": "PANT TRIM"
    },
    optionType: {
      "Red/Orange": "R/O",
      "Lime/Yellow": "L/Y"
    },
    gear: "Pants"
  },
  pants_trim_material: {
    sortValue : 11,
    label: "Pant Trim Material",
    attribute: "Pant Trim Material",
    type: "radioButtons",
    data: "tk-platform",
    selected: true,
    cssClass: "active",
    optionNumber: {
      "Triple Trim": "triple trim",
      "Comfort Trim": "comfort trim",
      "Brilliance w/Stripe": "brilliance w/stripe"
    },
    optionGroup: {
      "Triple Trim": "PANT TRIM",
      "Comfort Trim": "PANT TRIM",
      "Brilliance w/Stripe": "PANT TRIM"
    },
    optionType: {
      "Triple Trim": "triple trim",
      "Comfort Trim": "comfort trim",
      "Brilliance w/Stripe": "brilliance w/stripe"
    },
    gear: "Pants"
  },
  /**************************************************
   *************** JACKET Options *****************
   **************************************************/

  /*************** FRONT **********************/
  JACKET_FRONT_STORMFLAP_CLOSURE: {
    sortValue : 12,
    label: "Closure",
    attribute: "JACKET_FRONT_STORMFLAP_CLOSURE",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "*VISLON ZIP IN-VLC OUT": "N1STCL03",
      "HK/D IN VLC OUT GX3": "19(XX)307",
      "TC VISZIP IN VLCOUT": "19(XX)T269"
    },
    optionGroup: {
      "*VISLON ZIP IN-VLC OUT": "STORMFLAP",
      "HK/D IN VLC OUT GX3": "STORMFLAP",
      "TC VISZIP IN VLCOUT": "STORMFLAP"
    },
    optionType: {
      "*VISLON ZIP IN-VLC OUT": "N1STCL03",
      "HK/D IN VLC OUT GX3": "19(XX)307",
      "TC VISZIP IN VLCOUT": "19(XX)T269"
    },
    gear: "Jacket"
  },

  JACKET_FRONT_STORMFLAP_ZIPPER_PULL: {
    sortValue : 13,
    label: "Zipper Pull",
    attribute: "JACKET_FRONT_STORMFLAP_ZIPPER_PULL",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      ZIPPERGRIPPER: "ZIPGRIP"
    },
    optionGroup: {
      ZIPPERGRIPPER: "STORMFLAP"
    },
    optionType: {
      ZIPPERGRIPPER: "ZIPGRIP"
    },
    gear: "Jacket"
  },

  JACKET_FRONT_HOLDER_STORMFLAP: {
    sortValue : 14,
    label: "Holder - Stormflap",
    attribute: "FRONT HOLDERS",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "HANG STRAP WITH D-RING": "19(XX)590S",
      "HELMET SNAP W/SLF STRAP": "N1(XX)190S",
      "MICROPHONE STRAP 1X3": "19(XX)564S"
    },
    optionGroup: {
      "HANG STRAP WITH D-RING": "STORMFLAP",
      "HELMET SNAP W/SLF STRAP": "STORMFLAP",
      "MICROPHONE STRAP 1X3": "STORMFLAP"
    },
    optionType: {
      "HANG STRAP WITH D-RING": "19(XX)590S",
      "HELMET SNAP W/SLF STRAP": "N1(XX)190S",
      "MICROPHONE STRAP 1X3": "19(XX)564S"
    },
    gear: "Jacket"
  },

  JACKET_FRONT_HOLDER_ABOVE_TRIM: {
    sortValue : 15,
    label: "Holder - Above Trim",
    attribute: "FRONT HOLDERS ABOVE TRIM",
    type: "radioButtons",
    data: "tk-platform",
    cssClass:"right-holder",
    optionNumber: {
      "MICROPHONE STRAP 1X3": "19(XX)564S"
    },
    optionGroup: {
      "MICROPHONE STRAP 1X3": "STORMFLAP"
    },
    optionType: {
      "MICROPHONE STRAP 1X3": "19(XX)564S"
    },
    gear: "Jacket"
  },

  sizeAndType: {
    sortValue : 16,
    label: "Size and type",
    attribute: "SIZE & TYPE",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "EXPPKT 2X8X8 W/FLC HANDWARMER": "N1STPK03"
    },
    optionGroup: {
      "EXPPKT 2X8X8 W/FLC HANDWARMER": "SIDE POCKETS LEFT & RIGHT"
    },
    optionType: {
      "EXPPKT 2X8X8 W/FLC HANDWARMER": "N1STPK03"
    },
    gear: "Jacket"
  },

  outsideReinforcement: {
    sortValue : 17,
    label: "Outside Reinforcements",
    attribute: "OUTSIDE REINFORCEMENTS",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "DRAGONHIDE FRONT EXPANSION POCKET 2X8X8": "19428DH"
    },
    optionGroup: {
      "DRAGONHIDE FRONT EXPANSION POCKET 2X8X8": "SIDE POCKETS LEFT & RIGHT"
    },
    optionType: {
      "DRAGONHIDE FRONT EXPANSION POCKET 2X8X8": "19428DH"
    },
    gear: "Jacket"
  },

  insideReinforcement: {
    sortValue : 18,
    label: "Inside Reinforcements",
    attribute: "INSIDE REINFORCEMENTS",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "KEV POUCH HANDWARMER 2X8X8": "N1STPR01"
    },
    optionGroup: {
      "KEV POUCH HANDWARMER 2X8X8": "SIDE POCKETS LEFT & RIGHT"
    },
    optionType: {
      "KEV POUCH HANDWARMER 2X8X8": "N1STPR01"
    },
    gear: "Jacket"
  },

  rightTopCollar: {
    sortValue : 19,
    label: "Right Top Collar",
    attribute: "RIGHT TOP COLLAR",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "MICROPHONE STRAP 1X3": "19(XX)564C"
    },
    optionGroup: {
      "MICROPHONE STRAP 1X3": "COLLAR OPTIONS"
    },
    optionType: {
      "MICROPHONE STRAP 1X3": "19(XX)564C"
    },
    gear: "Jacket"
  },

  leftTopCollar: {
    sortValue : 20,
    label: "Left Top Collar",
    attribute: "LEFT TOP COLLAR",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "MICROPHONE STRAP 1X3": "19(XX)564C"
    },
    optionGroup: {
      "MICROPHONE STRAP 1X3": "COLLAR OPTIONS"
    },
    optionType: {
      "MICROPHONE STRAP 1X3": "19(XX)564C"
    },
    gear: "Jacket"
  },
  /*************** RIGHT CHEST **********************/
  rightChestSFPockets: {
    sortValue : 21,
    label: "Right Pockets",
    attribute: "RIGHT POCKETS",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "RADIO POCKET 3X3.5X9": "19(XX)413B",
      "RADIO POCKET 2X3.5X8": "19(XX)413C",
      "RADIO POCKET 3X2.5X7": "19(XX)413D"
    },
    optionGroup: {
      "RADIO POCKET 3X3.5X9": "RIGHT CHEST NEXT TO STORMFLAP",
      "RADIO POCKET 2X3.5X8": "RIGHT CHEST NEXT TO STORMFLAP",
      "RADIO POCKET 3X2.5X7": "RIGHT CHEST NEXT TO STORMFLAP"
    },
    optionType: {
      "RADIO POCKET 3X3.5X9": "19(XX)413B",
      "RADIO POCKET 2X3.5X8": "19(XX)413C",
      "RADIO POCKET 3X2.5X7": "19(XX)413D"
    },
    gear: "Jacket"
  },

  rightChestSFMic: {
    sortValue : 22,
    label: "Right Holder Microphone",
    attribute: "RIGHT CHEST MIC",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "MICROPHONE STRAP 1X3": "19(XX)564"
    },
    optionGroup: {
      "MICROPHONE STRAP 1X3": "RIGHT CHEST NEXT TO STORMFLAP"
    },
    optionType: {
      "MICROPHONE STRAP 1X3": "19(XX)564"
    },
    gear: "Jacket"
  },

  rightChestSFHoldersStormFlap: {
    sortValue : 23,
    label: "Right Holder",
    attribute: "RIGHT CHEST HOLDERS STORMFLAP",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "HANGING STRAP WITH D-RING": "19(XX)590H",
      "STRAP 1X12 W/1X4 VELCRO": "N1(XX)109",
      "HELMET SNAP W/SLF &VLC STRAP": "N1(XX)187",
      "SELF HSNP/MIC/2.5X9": "N1(XX)267",
      "FLASHLIGHT STRAP 2.5X9 WITH VELCRO": "N1(XX)298",
      "HELMET SNAP W/SLF STRAP": "N1(XX)190S",
      "SURVIVOR STRAP": "19(XX)548",
      "SUNLANCE W/SLF HELMET SNAP ABOVE": "19(XX)546C"
    },
    optionGroup: {
      "HANGING STRAP WITH D-RING": "RIGHT CHEST NEXT TO STORMFLAP",
      "STRAP 1X12 W/1X4 VELCRO": "RIGHT CHEST NEXT TO STORMFLAP",
      "HELMET SNAP W/SLF &VLC STRAP": "RIGHT CHEST NEXT TO STORMFLAP",
      "SELF HSNP/MIC/2.5X9": "RIGHT CHEST NEXT TO STORMFLAP",
      "FLASHLIGHT STRAP 2.5X9 WITH VELCRO": "RIGHT CHEST NEXT TO STORMFLAP",
      "HELMET SNAP W/SLF STRAP": "RIGHT CHEST NEXT TO STORMFLAP",
      "SURVIVOR STRAP": "RIGHT CHEST NEXT TO STORMFLAP",
      "SUNLANCE W/SLF HELMET SNAP ABOVE": "RIGHT CHEST NEXT TO STORMFLAP"
    },
    optionType: {
      "HANGING STRAP WITH D-RING": "19(XX)590H",
      "STRAP 1X12 W/1X4 VELCRO": "N1(XX)109",
      "HELMET SNAP W/SLF &VLC STRAP": "N1(XX)187",
      "SELF HSNP/MIC/2.5X9": "N1(XX)267",
      "FLASHLIGHT STRAP 2.5X9 WITH VELCRO": "N1(XX)298",
      "HELMET SNAP W/SLF STRAP": "N1(XX)190S",
      "SURVIVOR STRAP": "19(XX)548",
      "SUNLANCE W/SLF HELMET SNAP ABOVE": "19(XX)546C"
    },
    gear: "Jacket"
  },

  rightChestSFAntennaNotch: {
    sortValue : 24,
    label: "Right Antenna Notch",
    attribute: "ANTENNA NOTCH",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "RADIO FLAP DUAL NOTCH TAB 3": "N1(XX)505",
      "RADIO FLAP NOTCH TAB 3": "N1(XX)5035"
    },
    optionGroup: {
      "RADIO FLAP DUAL NOTCH TAB 3": "RIGHT CHEST NEXT TO STORMFLAP",
      "RADIO FLAP NOTCH TAB 3": "RIGHT CHEST NEXT TO STORMFLAP"
    },
    optionType: {
      "RADIO FLAP DUAL NOTCH TAB 3": "N1(XX)505",
      "RADIO FLAP NOTCH TAB 3": "N1(XX)5035"
    },
    gear: "Jacket"
  },

  leftChestSFPockets: {
    sortValue : 25,
    label: "Left Pockets",
    attribute: "LEFT POCKETS",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "RADIO POCKET 3X3.5X9": "19(XX)413B",
      "RADIO POCKET 2X3.5X8": "19(XX)413C",
      "RADIO POCKET 3X2.5X7": "19(XX)413D"
    },
    optionGroup: {
      "RADIO POCKET 3X3.5X9": "LEFT CHEST NEXT TO STORMFLAP",
      "RADIO POCKET 2X3.5X8": "LEFT CHEST NEXT TO STORMFLAP",
      "RADIO POCKET 3X2.5X7": "LEFT CHEST NEXT TO STORMFLAP"
    },
    optionType: {
      "RADIO POCKET 3X3.5X9": "19(XX)413B",
      "RADIO POCKET 2X3.5X8": "19(XX)413C",
      "RADIO POCKET 3X2.5X7": "19(XX)413D"
    },
    gear: "Jacket"
  },

  leftChestSFMic: {
    sortValue : 26,
    label: "Left Holder Microphone",
    attribute: "LEFT CHEST MIC",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "MICROPHONE STRAP 1X3": "19(XX)564"
    },
    optionGroup: {
      "MICROPHONE STRAP 1X3": "LEFT CHEST NEXT TO STORMFLAP"
    },
    optionType: {
      "MICROPHONE STRAP 1X3": "19(XX)564"
    },
    gear: "Jacket"
  },

  leftChestSFAntennaNotch: {
    sortValue : 27,
    label: "Left Antenna Notch",
    attribute: "LEFT ANTENNA NOTCH",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "RADIO FLAP DUAL NOTCH TAB 3": "N1(XX)505",
      "RADIO FLAP NOTCH TAB 3": "N1(XX)5035"
    },
    optionGroup: {
      "RADIO FLAP DUAL NOTCH TAB 3": "LEFT CHEST NEXT TO STORMFLAP",
      "RADIO FLAP NOTCH TAB 3": "LEFT CHEST NEXT TO STORMFLAP"
    },
    optionType: {
      "RADIO FLAP DUAL NOTCH TAB 3": "N1(XX)505",
      "RADIO FLAP NOTCH TAB 3": "N1(XX)5035"
    },
    gear: "Jacket"
  },

  leftChestSFHoldersStormFlap: {
    sortValue : 28,
    label: "Left Holders",
    attribute: "LEFT HOLDERS",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "HANGING STRAP WITH D-RING": "19(XX)590H",
      "STRAP 1X12 W/1X4 VELCRO": "N1(XX)109",
      "HELMET SNAP W/SLF &VLC STRAP": "N1(XX)187",
      "SELF HSNP/MIC/2.5X9": "N1(XX)267",
      "FLASHLIGHT STRAP 2.5X9 WITH VELCRO": "N1(XX)298",
      "HELMET SNAP W/SLF STRAP": "N1(XX)190S",
      "SURVIVOR STRAP": "19(XX)548",
      "SUNLANCE W/SLF HELMET SNAP ABOVE": "19(XX)546C"
    },
    optionGroup: {
      "HANGING STRAP WITH D-RING": "LEFT CHEST NEXT TO STORMFLAP",
      "STRAP 1X12 W/1X4 VELCRO": "LEFT CHEST NEXT TO STORMFLAP",
      "HELMET SNAP W/SLF &VLC STRAP": "LEFT CHEST NEXT TO STORMFLAP",
      "SELF HSNP/MIC/2.5X9": "LEFT CHEST NEXT TO STORMFLAP",
      "FLASHLIGHT STRAP 2.5X9 WITH VELCRO": "LEFT CHEST NEXT TO STORMFLAP",
      "HELMET SNAP W/SLF STRAP": "LEFT CHEST NEXT TO STORMFLAP",
      "SURVIVOR STRAP": "LEFT CHEST NEXT TO STORMFLAP",
      "SUNLANCE W/SLF HELMET SNAP ABOVE": "LEFT CHEST NEXT TO STORMFLAP"
    },
    optionType: {
      "HANGING STRAP WITH D-RING": "19(XX)590H",
      "STRAP 1X12 W/1X4 VELCRO": "N1(XX)109",
      "HELMET SNAP W/SLF &VLC STRAP": "N1(XX)187",
      "SELF HSNP/MIC/2.5X9": "N1(XX)267",
      "FLASHLIGHT STRAP 2.5X9 WITH VELCRO": "N1(XX)298",
      "HELMET SNAP W/SLF STRAP": "N1(XX)190S",
      "SURVIVOR STRAP": "19(XX)548",
      "SUNLANCE W/SLF HELMET SNAP ABOVE": "19(XX)546C"
    },
    gear: "Jacket"
  },

  rightFrontNextSFHolders: {
    sortValue : 29,
    label: "Right Front Holders",
    attribute: "RIGHT FRONT NEXT TO STORMFLAP",
    type: "radioButtons",
    data: "tk-platform",
    cssClass: "right-front-storm",
    optionNumber: {
      "HELMET SNAP W/SLF STRAP": "N1(XX)190",
      "HANGING STRAP WITH D-RING": "19(XX)590H"
    },
    optionGroup: {
      "HELMET SNAP W/SLF STRAP": "RIGHT FRONT NEXT TO STORMFLAP",
      "HANGING STRAP WITH D-RING": "RIGHT FRONT NEXT TO STORMFLAP"
    },
    optionType: {
      "HELMET SNAP W/SLF STRAP": "N1(XX)190",
      "HANGING STRAP WITH D-RING": "19(XX)590H"
    },
    gear: "Jacket"
  },

  leftFrontNextSFHolders: {
    sortValue : 30,
    label: "Left Front Holders",
    attribute: "LEFT FRONT NEXT TO STORMFLAP",
    type: "radioButtons",
    data: "tk-platform",
    cssClass: "left-front-storm",
    optionNumber: {
      "HELMET SNAP W/SLF STRAP": "N1(XX)190",
      "HANGING STRAP WITH D-RING": "19(XX)590H"
    },
    optionGroup: {
      "HELMET SNAP W/SLF STRAP": "LEFT FRONT NEXT TO STORMFLAP",
      "HANGING STRAP WITH D-RING": "LEFT FRONT NEXT TO STORMFLAP"
    },
    optionType: {
      "HELMET SNAP W/SLF STRAP": "N142190",
      "HANGING STRAP WITH D-RING": "19(XX)590H"
    },
    gear: "Jacket"
  },

  rightFlags: {
    sortValue : 31,
    label: "Right Flags",
    attribute: "RIGHT FLAGS",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "U.S. FLAG EMBROIDERY RIGHT SLEEVE": "N100107E",
      "CANADIAN FLAG EMBROIDERY RIGHT SLEEVE": "N100107F"
    },
    optionGroup: {
      "U.S. FLAG EMBROIDERY RIGHT SLEEVE": "RIGHT SLEEVE",
      "CANADIAN FLAG EMBROIDERY RIGHT SLEEVE": "RIGHT SLEEVE"
    },
    optionType: {
      "U.S. FLAG EMBROIDERY RIGHT SLEEVE": "N100107E",
      "CANADIAN FLAG EMBROIDERY RIGHT SLEEVE": "N100107F"
    },
    gear: "Jacket"
  },

  rightEmbroidery: {
    sortValue : 32,
    label: "Right Custom Patches",
    attribute: "RIGHT EMBROIDERY PATCHES",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "CUSTOM SILVER PRINT PATCH SLEEVE": "CPPS",
      "CUSTOM BLACK PRINT PATCH SLEEVE": "CPPSBLK",
      "CUSTOM GOLD PRINT PATCH SLEEVE": "CPPSGLD",
      "CUSTOM YELLOW PRINT PATCH SLEEVE": "CPPSYEL"
    },
    optionGroup: {
      "CUSTOM SILVER PRINT PATCH SLEEVE": "RIGHT SLEEVE",
      "CUSTOM BLACK PRINT PATCH SLEEVE": "RIGHT SLEEVE",
      "CUSTOM GOLD PRINT PATCH SLEEVE": "RIGHT SLEEVE",
      "CUSTOM YELLOW PRINT PATCH SLEEVE": "RIGHT SLEEVE"
    },
    optionType: {
      "CUSTOM SILVER PRINT PATCH SLEEVE": "CPPS",
      "CUSTOM BLACK PRINT PATCH SLEEVE": "CPPSBLK",
      "CUSTOM GOLD PRINT PATCH SLEEVE": "CPPSGLD",
      "CUSTOM YELLOW PRINT PATCH SLEEVE": "CPPSYEL"
    },
    gear: "Jacket"
  },


  rightEmbroideryPadding: {
    sortValue : 33,
    label: "Right Patch Padding",
    attribute: "RIGHT EMBROIDERY PADDING",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "RSLV CPPS": "1975R615"
    },
    optionGroup: {
      "RSLV CPPS": "RIGHT SLEEVE"
    },
    optionType: {
      "RSLV CPPS": "1975R615"
    },
    gear: "Jacket"
  },

  leftFlags: {
    sortValue : 34,
    label: "Left Flags",
    attribute: "LEFT FLAGS",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "U.S. FLAG EMBROIDERY LEFT SLEEVE": "N100107E",
      "CANADIAN FLAG EMBROIDERY LEFT SLEEVE": "N100107F"
    },
    optionGroup: {
      "U.S. FLAG EMBROIDERY LEFT SLEEVE": "LEFT SLEEVE",
      "CANADIAN FLAG EMBROIDERY LEFT SLEEVE": "LEFT SLEEVE"
    },
    optionType: {
      "U.S. FLAG EMBROIDERY LEFT SLEEVE": "N100107E",
      "CANADIAN FLAG EMBROIDERY LEFT SLEEVE": "N100107F"
    },
    gear: "Jacket"
  },

  leftEmbroidery: {
    sortValue : 35,
    label: "Left Custom Patches",
    attribute: "LEFT EMBROIDERY PATCHES",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "CUSTOM SILVER PRINT PATCH SLEEVE": "CPPS",
      "CUSTOM BLACK PRINT PATCH SLEEVE": "CPPSBLK",
      "CUSTOM GOLD PRINT PATCH SLEEVE": "CPPSGLD",
      "CUSTOM YELLOW PRINT PATCH SLEEVE": "CPPSYEL"
    },
    optionGroup: {
      "CUSTOM SILVER PRINT PATCH SLEEVE": "LEFT SLEEVE",
      "CUSTOM BLACK PRINT PATCH SLEEVE": "LEFT SLEEVE",
      "CUSTOM GOLD PRINT PATCH SLEEVE": "LEFT SLEEVE",
      "CUSTOM YELLOW PRINT PATCH SLEEVE": "LEFT SLEEVE"
    },
    optionType: {
      "CUSTOM SILVER PRINT PATCH SLEEVE": "CPPS",
      "CUSTOM BLACK PRINT PATCH SLEEVE": "CPPSBLK",
      "CUSTOM GOLD PRINT PATCH SLEEVE": "CPPSGLD",
      "CUSTOM YELLOW PRINT PATCH SLEEVE": "CPPSYEL"
    },
    gear: "Jacket"
  },

  leftEmbroideryPadding: {
    sortValue : 36,
    label: "Left Patch Padding",
    attribute: "LEFT EMBROIDERY PADDING",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "LSLV CPPS": "1975L615"
    },
    optionGroup: {
      "LSLV CPPS": "LEFT SLEEVE"
    },
    optionType: {
      "LSLV CPPS": "1975L615"
    },
    gear: "Jacket"
  },



  /* LETTERING */
  lettersAText: {
    sortValue : 37,
    label: "A Letters Text",
    attribute: "Row A Lettering Input",
    type: "textInput",
    data: "tk-platform"
  },
  letterASize: {
    sortValue : 38,
    label: "A Letters Size",
    attribute: "Row A Size Input",
    type: "radioButtons",
    data: "tk-platform"
  },
  lettersAColor: {
    sortValue : 39,
    label: "A Letters Color",
    attribute: "Row A Color Input",
    type: "radioButtons",
    data: "tk-platform"
  },
  rowAArcheting:{
    sortValue : 40,
    label: "Row A Archeting",
    attribute: "Row A Archeting",
    type: "radioButtons",
    data: "tk-platform"
  },

  lettersBText: {
    sortValue : 41,
    label: "B Letters Text",
    attribute: "Row B Lettering Input",
    type: "textInput",
    data: "tk-platform"
  },
  letterBSize: {
    sortValue : 42,
    label: "B Letters Size",
    attribute: "Row B Size Input",
    type: "radioButtons",
    data: "tk-platform"
  },
  lettersBColor: {
    sortValue : 43,
    label: "B Letters Color",
    attribute: "Row B Color Input",
    type: "radioButtons",
    data: "tk-platform"
  },

  rowBArcheting:{
    sortValue : 44,
    label: "Row B Archeting",
    attribute: "Row B Archeting",
    type: "radioButtons",
    data: "tk-platform"
  },

  lettersCText: {
    sortValue : 45,
    label: "C Letters Text",
    attribute: "Row C Lettering Input",
    type: "textInput",
    data: "tk-platform"
  },
  letterCSize: {
    sortValue : 46,
    label: "C Letters Size",
    attribute: "Row C Size Input",
    type: "radioButtons",
    data: "tk-platform"
  },
  lettersCColor: {
    sortValue : 47,
    label: "C Letters Color",
    attribute: "Row C Color Input",
    type: "radioButtons",
    data: "tk-platform"
  },

  lettersDText: {
    sortValue : 48,
    label: "D Letters Text",
    attribute: "Row D Lettering Input",
    type: "textInput",
    data: "tk-platform"
  },
  letterDSize: {
    sortValue : 49,
    label: "D Letters Size",
    attribute: "Row D Size Input",
    type: "radioButtons",
    data: "tk-platform"
  },
  lettersDColor: {
    sortValue : 50,
    label: "D Letters Color",
    attribute: "Row D Color Input",
    type: "radioButtons",
    data: "tk-platform"
  },

  lettersEText: {
    sortValue : 51,
    label: "E Letters Text",
    attribute: "Row E Lettering Input",
    type: "textInput",
    data: "tk-platform"
  },
  letterESize: {
    sortValue : 52,
    label: "E Letters Size",
    attribute: "Row E Size Input",
    type: "radioButtons",
    data: "tk-platform"
  },
  lettersEColor: {
    sortValue : 53,
    label: "E Letters Color",
    attribute: "Row E Color Input",
    type: "radioButtons",
    data: "tk-platform"
  },

  rowEArcheting:{
    sortValue : 54,
    label: "Row E Archeting",
    attribute: "Row E Archeting",
    type: "radioButtons",
    data: "tk-platform"
  },

  letterDSize: {
    sortValue : 55,
    label: "D Letters Size",
    attribute: "Row D Size Input",
    type: "radioButtons",
    data: "tk-platform"
  },

  lettersFText: {
    sortValue : 56,
    label: "F Letters Text",
    attribute: "Row F Lettering Input",
    type: "textInput",
    data: "tk-platform"
  },
  letterFSize: {
    sortValue : 57,
    label: "F Letters Size",
    attribute: "Row F Size Input",
    type: "radioButtons",
    data: "tk-platform"
  },
  lettersFColor: {
    sortValue : 58,
    label: "F Letters Color",
    attribute: "Row F Color Input",
    type: "radioButtons",
    data: "tk-platform"
  },

  letterPatch: {
    sortValue : 59,
    label: "PATCH",
    attribute: "HANGING LETTER PATCH",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "LETTER PATCH SNP/VLC HANG 5X20": "N1(XX)132"
    },
    optionGroup: {
      "LETTER PATCH SNP/VLC HANG 5X20": "HANGING LETTER PATCH"
    },
    optionType: {
      "LETTER PATCH SNP/VLC HANG 5X20": "N1(XX)132"
    },
    gear: "Jacket"
  },

  letterSize: {
    sortValue : 60,
    label: "Letters Size",
    attribute: "Patch Lettering Size Input",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      '2" SCOTCHLITE LETTERS SEWN': "19720",
      '3" SCOTCHLITE LETTERS SEWN': "19721"
    },
    optionGroup: {
      '2" SCOTCHLITE LETTERS SEWN': "LTRG",
      '3" SCOTCHLITE LETTERS SEWN': "LTRG"
    },
    optionType: {
      '2" SCOTCHLITE LETTERS SEWN': "2IN",
      '3" SCOTCHLITE LETTERS SEWN': "3IN"
    },
    gear: "Jacket"
  },

  lettersColor: {
    sortValue : 61,
    label: "Letters Color",
    attribute: "Patch Lettering Color Input",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "Lime/Yellow": "NA",
      "Red/Orange": "NA"
    },
    optionGroup: {
      "Lime/Yellow": "NA",
      "Red/Orange": "NA"
    },
    optionType: {
      "Lime/Yellow": "NA",
      "Red/Orange": "NA"
    },
    gear: "Jacket"
  },

  lettersText: {
    sortValue : 62,
    label: "Letters Text",
    attribute: "Patch Lettering Input",
    type: "textInput",
    data: "tk-platform",
    optionNumber: "NA",
    optionGroup: "NA",
    optionType: "NA"
  },

  sleeveWellConfiguration: {
    sortValue : 63,
    label: "Sleeve Well",
    attribute: "SLEEVE WELL",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "Std Adjust Neop SlvWell": "N1STSW01"
    },
    optionGroup: {
      "Std Adjust Neop SlvWell": "OTHER"
    },
    optionType: {
      "Std Adjust Neop SlvWell": "N1STSW01"
    },
    gear: "Jacket"
  },

  wristerLiner: {
    sortValue : 64,
    label: "Wrister (Liner)",
    attribute: "WRISTER (LINER)",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "NOMEX WRISTER LINER": "N1STWLA1",
      "GRY NOMEX WRISTER LINER": "1905G5NX",
      "NOMEX HAND AND WRIST GUARD LINER": "190549NX",
      "GRY NOMEX HAND AND WRIST GUARD LINER": "1905G9NX"
    },
    optionGroup: {
      "NOMEX WRISTER LINER": "OTHER",
      "GRY NOMEX WRISTER LINER": "OTHER",
      "NOMEX HAND AND WRIST GUARD LINER": "OTHER",
      "GRY NOMEX HAND AND WRIST GUARD LINER": "OTHER"
    },
    optionType: {
      "NOMEX WRISTER LINER": "N1STWLA1",
      "GRY NOMEX WRISTER LINER": "1905G5NX",
      "NOMEX HAND AND WRIST GUARD LINER": "190549NX",
      "GRY NOMEX HAND AND WRIST GUARD LINER": "1905G9NX"
    },
    gear: "Jacket"
  },

  // wristerLinerThumbHoleLoop: {
  //   label: "Wrister (Liner) Thumb Hole Loop",
  //   attribute: "WRISTER (LINER) THUMB HOLE LOOP",
  //   type: "radioButtons",
  //   data: "tk-platform",
  //   optionNumber: {
  //     "THUMB HOLE LOOP LINER": "N100321L"
  //   },
  //   optionGroup: {
  //     "THUMB HOLE LOOP LINER": "OTHER"
  //   },
  //   optionType: {
  //     "THUMB HOLE LOOP LINER": "N100321L"
  //   },
  //   gear: "Jacket"
  // },

  sleeveCuffs: {
    sortValue : 65,
    label: "Sleeve Cuffs",
    attribute: "SLEEVE CUFFS",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "STD SUEDE CUFFS": "N1STCR01",
      "CUFF REINFORCEMENT DRAGONHIDE": "19DH506",
      "CUFF REINFORCEMENT ARASHIELD": "1988507"
    },
    optionGroup: {
      "STD SUEDE CUFFS": "OTHER",
      "CUFF REINFORCEMENT DRAGONHIDE": "OTHER",
      "CUFF REINFORCEMENT ARASHIELD": "OTHER"
    },
    optionType: {
      "STD SUEDE CUFFS": "N1STCR01",
      "CUFF REINFORCEMENT DRAGONHIDE": "19DH506",
      "CUFF REINFORCEMENT ARASHIELD": "1988507"
    },
    gear: "Jacket"
  },

  globeguard: {
    sortValue : 66,
    label: "Globe Guard",
    attribute: "GLOBE GUARD",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "COAT GLOBE GUARD GMB": "N100GGG",
      "COAT GLOBE GUARD NEOP": "N100GGN"
    },
    optionGroup: {
      "COAT GLOBE GUARD GMB": "OTHER",
      "COAT GLOBE GUARD NEOP": "OTHER"
    },
    optionType: {
      "COAT GLOBE GUARD GMB": "N100GGG",
      "COAT GLOBE GUARD NEOP": "N100GGN"
    },
    gear: "Jacket"
  },

  neckHangerLoop: {
    sortValue : 67,
    label: "Neck Hanger Loop",
    attribute: "NECK HANGER LOOP",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "CLR LOOP": "N1STCH01"
    },
    optionGroup: {
      "CLR LOOP": "OTHER"
    },
    optionType: {
      "CLR LOOP": "N1STCH01"
    },
    gear: "Jacket"
  },

  thermalLinerPocket: {
    sortValue : 68,
    label: "Thermal Liner Pocket",
    attribute: "THERMAL LINER POCKET",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "Std. Liner Pkt - Left": "N1STLP02"
    },
    optionGroup: {
      "Std. Liner Pkt - Left": "OTHER"
    },
    optionType: {
      "Std. Liner Pkt - Left": "N1STLP02"
    },
    gear: "Jacket"
  },

  linerAttachmentShell: {
    sortValue : 69,
    label: "Liner Attachment (Shell)",
    attribute: "LINER ATTACHMENT (SHELL)",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "NECK SNAPS COLLAR": "N1STNSS1"
    },
    optionGroup: {
      "NECK SNAPS COLLAR": "OTHER"
    },
    optionType: {
      "NECK SNAPS COLLAR": "N1STNSS1"
    },
    gear: "Jacket"
  },

  linerAttachmentLiner: {
    sortValue : 70,
    label: "Liner Attachment (Liner)",
    attribute: "LINER ATTACHMENT (LINER)",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "NECK SNAPS LINER": "N1STNSL1"
    },
    optionGroup: {
      "NECK SNAPS LINER": "OTHER"
    },
    optionType: {
      "NECK SNAPS LINER": "N1STNSL1"
    },
    gear: "Jacket"
  },

  drdStrap: {
    sortValue : 71,
    label: "DRD Strap",
    attribute: "DRD STRAP",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      DRD: "N1STDRD"
    },
    optionGroup: {
      DRD: "OTHER"
    },
    optionType: {
      DRD: "N1STDRD"
    },
    gear: "Jacket"
  },

  /**************************************************
   *************** Pants Options ********************
   **************************************************/

  /*************** FRONT ****************************/
  
  PANT_FRONT_FRONTCLOSURE_BELT_LOOPS: {
    sortValue : 72,
    label: "Belt Loops",
    attribute: "PANT_FRONT_FRONTCLOSURE_BELT_LOOPS",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "*NOMEX BLT/TUNNEL": "N2STWC05",
      "BLTLPS WD GPS": "N200276G"
    },
    optionGroup: {
      "*NOMEX BLT/TUNNEL" : "FRONT",
      "BLTLPS WD GPS": "FRONT"
    },
    optionType: {
      "*NOMEX BLT/TUNNEL": "N2STWC05",
      "BLTLPS WD GPS": "N200276G"
    },
    gear: "Pants"
  },

  PANT_FRONT_FRONTCLOSURE_BELT: {
    sortValue : 73,
    label: "Belt",
    attribute: "PANT_FRONT_FRONTCLOSURE_BELT",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "BLK NOMEX BLT GPS": "N200255G",
      "ESCAPE BLT": "N200301",
    },
    optionGroup: {
      "BLK NOMEX BLT GPS" : "FRONT",
      "ESCAPE BLT"        : "FRONT",
    },
    optionType: {
      "BLK NOMEX BLT GPS": "N200255G",
      "ESCAPE BLT": "N200301",
    },
    gear: "Pants"
  },
  PANT_OTHER_KNEES_PADDING_LINING: {
    sortValue : 95,
    label: "Knees Padding - Lining",
    attribute: "PANT_OTHER_KNEES_PADDING_LINING",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "KNEE LINER SILIZONE": "N2STLKA1",
      "KNEELNR 2LYR SILIZONE ATX": "29SLZ627"
    },
    optionGroup: {
      "KNEE LINER SILIZONE": "OTHER PANTS",
      "KNEELNR 2LYR SILIZONE ATX": "OTHER PANTS"
    },
    optionType: {
      "KNEE LINER SILIZONE": "N2STLKA1",
      "KNEELNR 2LYR SILIZONE ATX": "29SLZ627"
    },
    gear: "Pants"
  },
  PANT_FRONT_FRONTCLOSURE_CARABINER: {
    sortValue : 74,
    label: "Carabiner",
    attribute: "PANT_FRONT_FRONTCLOSURE_CARABINER",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "CMC CARABINER": "N2CBCMC",
      "YATES CARABINER": "N2CBYATE"
    },
    optionGroup: {
      "CMC CARABINER": "FRONT",
      "YATES CARABINER": "FRONT"
    },
    optionType: {
      "CMC CARABINER": "N2CBCMC",
      "YATES CARABINER": "N2CBYATE"
    },
    gear: "Pants"
  },


  /*************** SIDE POCKETS RIGHT **********************/
  PANT_SIDE_POCKETS_RIGHT_SIZE_TYPE: {
    sortValue : 75,
    label: "Size & Type Right",
    attribute: "PANT_SIDE_POCKETS_RIGHT_SIZE_TYPE",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "*EXP PKT 2X10X10": "N2STPK01",
      "ESCAPE SYSTEM POCKET CROSBY 2X7X8 RPKT": "N2(XX)285",
      "ESCAPE SYSTEM POCKET CMC 2X8X9 RPKT": "N2(XX)389R"
    },
    optionGroup: {
      "*EXP PKT 2X10X10": "SIDE POCKETS (RIGHT)",
      "ESCAPE SYSTEM POCKET CROSBY 2X7X8 RPKT": "SIDE POCKETS (RIGHT)",
      "ESCAPE SYSTEM POCKET CMC 2X8X9 RPKT": "SIDE POCKETS (RIGHT)"
    },
    optionType: {
      "*EXP PKT 2X10X10": "N2STPK01",
      "ESCAPE SYSTEM POCKET CROSBY 2X7X8 RPKT": "N2(XX)285",
      "ESCAPE SYSTEM POCKET CMC 2X8X9 RPKT": "N2(XX)389R"
    },
    gear: "Pants"
  },

  PANT_SIDE_POCKETS_RIGHT_INSIDE_REINFORCEMENT: {
    sortValue : 76,
    label: "Inside Reinforcement",
    attribute: "PANT_SIDE_POCKETS_RIGHT_INSIDE_REINFORCEMENT",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "Kevlar": "N2STPR01",
    },
    optionGroup: {
      "Kevlar": "SIDE POCKETS (RIGHT)",
    },
    optionType: {
      "Kevlar": "N2STPR01",
    },
    gear: "Pants"
  },

  /*************** SIDE POCKETS LEFT **********************/
  PANT_SIDE_POCKETS_LEFT_SIZE_TYPE: {
    sortValue : 77,
    label: "Size & Type Left",
    attribute: "PANT_SIDE_POCKETS_LEFT_SIZE_TYPE",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "*EXP PKT 2X10X10": "N2STPK01",
      "ESCAPE SYSTEM POCKET CROSBY 2X7X8 LPKT": "N2(XX)285L",
      "ESCAPE SYSTEM POCKET CMC 2X8X9 LPKT": "N2(XX)389R"
    },
    optionGroup: {
      "*EXP PKT 2X10X10": "SIDE POCKETS (LEFT)",
      "ESCAPE SYSTEM POCKET CROSBY 2X7X8 LPKT": "SIDE POCKETS (LEFT)",
      "ESCAPE SYSTEM POCKET CMC 2X8X9 LPKT": "SIDE POCKETS (LEFT)"
    },
    optionType: {
      "*EXP PKT 2X10X10": "N2STPK01",
      "ESCAPE SYSTEM POCKET CROSBY 2X7X8 LPKT": "N2(XX)285L",
      "ESCAPE SYSTEM POCKET CMC 2X8X9 LPKT": "N2(XX)389R"
    },
    gear: "Pants"
  },

  PANT_SIDE_POCKETS_LEFT_INSIDE_REINFORCEMENT: {
    sortValue : 78,
    label: "Inside Reinforcement",
    attribute: "PANT_SIDE_POCKETS_LEFT_INSIDE_REINFORCEMENT",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "Kevlar": "N2STPR01",
    },
    optionGroup: {
      "Kevlar": "SIDE POCKETS (LEFT)",
    },
    optionType: {
      "Kevlar": "N2STPR01",
    },
    gear: "Pants"
  },

  /*************** OTHER ****************************/
  PANT_OTHER_PANTS_CUFF: {
    sortValue : 79,
    label: "Pant Cuffs",
    attribute: "PANT_OTHER_PANTS_CUFF",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "STD SUEDE CUFFS": "N2STCR01",
      "CUFF REINFORCEMENT DRAGONHIDE": "29DH109",
      "CUFF REINFORCEMENT ARASHIELD": "2988109"
    },
    optionGroup: {
      "STD SUEDE CUFFS": "OTHER PANTS",
      "CUFF REINFORCEMENT DRAGONHIDE": "OTHER PANTS",
      "CUFF REINFORCEMENT ARASHIELD": "OTHER PANTS"
    },
    optionType: {
      "STD SUEDE CUFFS": "N2STCR01",
      "CUFF REINFORCEMENT DRAGONHIDE": "29DH109",
      "CUFF REINFORCEMENT ARASHIELD": "2988109"
    },
    gear: "Pants"
  },

  PANT_OTHER_KNEES_REINFORCEMENT: {
    sortValue : 80,
    label: "Knees Reinforcement",
    attribute: "PANT_OTHER_KNEES_REINFORCEMENT",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "KNEE SHELL DRAGONHIDE CATHEDRAL": "N2DH104C",
      "KNEE SHELL BLACK SUEDE CATHEDRAL": "290105BC",
      "KNEE SHELL ARASHIELD CATHEDRAL":"N288104C"

    },
    optionGroup: {
      "KNEE SHELL DRAGONHIDE CATHEDRAL": "OTHER PANTS",
      "KNEE SHELL BLACK SUEDE CATHEDRAL": "OTHER PANTS",
      "KNEE SHELL ARASHIELD CATHEDRAL":"OTHER PANTS"
    },
    optionType: {
      "KNEE SHELL DRAGONHIDE CATHEDRAL": "N2DH104C",
      "KNEE SHELL BLACK SUEDE CATHEDRAL": "290105BC",
      "KNEE SHELL ARASHIELD CATHEDRAL":"N288104C"
    },
    gear: "Pants"
  },

  PANT_OTHER_GLOBEGUARD: {
    sortValue : 81,
    label: "Pant Globe Guard System",
    attribute: "PANT_OTHER_GLOBEGUARD",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "PANT GLOBE GUARD GMB": "N200GGG",
      "PANT GLOBE GUARD NEOP": "N200GGN"
    },
    optionGroup: {
      "PANT GLOBE GUARD GMB": "OTHER PANTS",
      "PANT GLOBE GUARD NEOP": "OTHER PANTS"
    },
    optionType: {
      "PANT GLOBE GUARD GMB": "N200GGG",
      "PANT GLOBE GUARD NEOP": "N200GGN"
    },
    gear: "Pants"
  },

  PANT_OTHER_SUSPENDERS: {
    sortValue : 82,
    label: "Suspenders",
    attribute: "PANT_OTHER_SUSPENDERS",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "REG BLACK PADDED RIPCORD HBK": "N2STSU01",
      "REGULAR BLACK PDRIP LY1.5 IN PLACE OF": "I83315T2"
    },
    optionGroup: {
      "REG BLACK PADDED RIPCORD HBK": "OTHER PANTS",
      "REGULAR BLACK PDRIP LY1.5 IN PLACE OF": "OTHER PANTS"
    },
    optionType: {
      "REG BLACK PADDED RIPCORD HBK": "N2STSU01",
      "REGULAR BLACK PDRIP LY1.5 IN PLACE OF": "I83315T2"
    },
    gear: "Pants"
  },

  PANT_OTHER_MANDATORY_CCHR: {
    sortValue : 83,
    label: "Mandatory CCHR",
    attribute: "PANT_OTHER_MANDATORY_CCHR",
    type: "radioButtons",
    data: "tk-platform",
    optionNumber: {
      "Additional CCHR Req.": "2975615C"
    },
    optionGroup: {
      "Additional CCHR Req.": "OTHER PANTS"
    },
    optionType: {
      "Additional CCHR Req.": "2975615C"
    },
    gear: "Pants"
  }
};
