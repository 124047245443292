import React from "react";
import { Templates } from '../../toolkit';


import templateData from '../../config/gXtreme/template';
import sectionsData from '../../config/gXtreme/sections';
import inputsData from '../../config/gXtreme/inputs';
import threekitConfig from '../../config/gXtreme/threekitConfig';

const Gxtreme = () => {
  sessionStorage.setItem("suitPieceSelected", "")
  sessionStorage.setItem("gearSelected", "GX3")
    return (
        <Templates
          template={templateData}
          sections={sectionsData}
          inputs={inputsData}
          threekitConfig={threekitConfig}
        />
      );
}
export default Gxtreme;