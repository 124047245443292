export default {
    'GX3': {
        Materials: true,
        Trim: false,
    },
    Athletix: {
        Materials: true,
        Trim: false,
    },
    jacket:{
        STORMFLAP: true,
        "SIDE POCKETS": false,
        COLLAR: false,
        CHEST: false,
        FRONT: false,
        SLEEVES: false,
        "BACK LETTERING": false,
        OTHER: false,
    },
    pants:{
        FRONT: true,
        "SIDE POCKETS": false,
        OTHER: false
    }
    
}