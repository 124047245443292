import React, {useEffect} from 'react';
import {
  Wrapper,
  ButtonsRow,
  ButtonWrapper,
  ButtonGearWrapper,
} from './radioButtonsImages.styles';
import icnImgMissing from '../assets/no-image.webp';
import dkgold from '../../../assets/dk_gold.jpg';
import ltgold from '../../../assets/lt_gold.jpg';
import tan from '../../../assets/tan.jpg';
import yellow from '../../../assets/yellow.jpg';

import blackSwatch from '../../../assets/swatches/black-swatch.png';
import yellowSwatch from '../../../assets/swatches/yellow-swatch.png';
import tanSwatch from '../../../assets/swatches/tan-swatch.png';
import goldSwatch from '../../../assets/swatches/gold-swatch.png';
import lightGoldSwatch from '../../../assets/swatches/light-gold-swatch.png';
import darkGoldSwatch from '../../../assets/swatches/dark-gold-swatch.png';
import limeYellowSwatch from '../../../assets/swatches/lime-yellow-swatch.png';
import orangeSwatch from '../../../assets/swatches/orange-swatch.png';

import gXtreme from   '../../../assets/g_extreme.png'
import gx3coat from   '../../../assets/gx3_coat.png';
import gx3pants from  '../../../assets/gx3_pants.png';

import athletix from  '../../../assets/athletix.png'
import athletixCoat from '../../../assets/jacket.png';
import athletixPants from '../../../assets/pants.png';

const RadioButtonsImages = ({ inputLabel, options, value, onChange }) => {
  let colorClasses = [];
  if (!options || options.length === 0) return null;
  const THUMBNAIL_MAP_ATX = {
    'Athletix': athletix,//'https://source360-msa.azurewebsites.net/assets/athletix.png',
    'Jacket': athletixCoat,//'https://source360-msa.azurewebsites.net/assets/jacket.png',
    'Pants': athletixPants//"https://source360-msa.azurewebsites.net/assets/pants.png"

  };

  const THUMBNAIL_COLORMAP_ATX = {
    'Black': blackSwatch, //'https://source360-msa.azurewebsites.net/assets/pbi-black.png',
    'Gold': goldSwatch, //'https://source360-msa.azurewebsites.net/assets/pbi-gold.png',
    'Lime/Yellow': limeYellowSwatch, //"https://source360-msa.azurewebsites.net/assets/lime_lettering.png",
    'Red/Orange': orangeSwatch, //"https://source360-msa.azurewebsites.net/assets/orange_lettering.png",
    'Yellow': limeYellowSwatch, //"https://source360-msa.azurewebsites.net/assets/lime_lettering.png",
    'Orange': orangeSwatch, //"https://source360-msa.azurewebsites.net/assets/orange_lettering.png"
  };
  const colorClassesAtx = ["img--black", "img--gold"];
  const colorClassesGx = ["img--black gx3__non-visible", "img--yellow gx3__non-visible", "img--tan gx3__non-visible", "img--gold gx3__non-visible", "img--lt-gold gx3__non-visible", "img--dk-gold gx3__non-visible"]
  const THUMBNAIL_MAP_GX = {
    'GX3': gXtreme,
    "GX3 Coat": gx3coat,
    "GPS Pant": gx3pants
  };

  const THUMBNAIL_COLORMAP_GX = {
    'Black': blackSwatch, //'https://source360-msa.azurewebsites.net/assets/pbi-black.png',
    "Yellow": yellowSwatch, //"https://source360-msa.azurewebsites.net/assets/yellow.jpg",
    "Tan": tanSwatch,
    'Gold': goldSwatch, //'https://source360-msa.azurewebsites.net/assets/pbi-gold.png',
    "Light Gold": lightGoldSwatch,
    "Dark Gold": darkGoldSwatch,
    "yellow": yellowSwatch,
    'Lime/Yellow': limeYellowSwatch, //"https://source360-msa.azurewebsites.net/assets/lime_lettering.png",
    'Red/Orange': orangeSwatch, //"https://source360-msa.azurewebsites.net/assets/orange_lettering.png",
    'Yellow': yellowSwatch, //"https://source360-msa.azurewebsites.net/assets/lime_lettering.png",
    'Orange': orangeSwatch //"https://source360-msa.azurewebsites.net/assets/orange_lettering.png"
  };
  
  let THUMBNAIL_MAP
  let THUMBNAIL_COLORMAP 
  if (window.location.pathname == "/gXtreme"){
    THUMBNAIL_MAP= THUMBNAIL_MAP_GX;
    THUMBNAIL_COLORMAP = THUMBNAIL_COLORMAP_GX;
    colorClasses = colorClassesGx;
  }
  else{
    THUMBNAIL_MAP= THUMBNAIL_MAP_ATX;
    THUMBNAIL_COLORMAP = THUMBNAIL_COLORMAP_ATX;
    colorClasses = colorClassesAtx;
  }
  return (
    <Wrapper>
      <ButtonsRow>
        {options.map(({ label, img, value: val }, i) => (
          <div className={ "image-swatch-container gear-" + label.toLowerCase().replace(" ", "-") } key={label+i}>
            {((val === 'Pants') || (val === 'Jacket') || (val === 'Athletix') || (val === 'GX3') ) || (val === "GX3 Coat") || (val === "GPS Pant") ?
              <ButtonGearWrapper
                id={ "gear-" + label.toLowerCase().replace(" ", "-") }
                key={i}
                selected={(!value || value === undefined) && i === 0 ? true : val === value}
                onClick={(e) => {
                  if ((val === 'Pants') || (val === 'Jacket') || (val === 'Athletix') || (val === 'GX3') || (val === 'GX3 Coat') || (val === 'GPS Pant') ) {
                    localStorage.setItem('gearSelected', val);
                  }
                  localStorage.setItem('isClickEvent', 'true');
                  onChange(val)
                }}
              >
                <div className="gear-img">
                  <img src={THUMBNAIL_MAP[label] || icnImgMissing} alt={label} />
                </div>
                <div className="gear-label" style={{textAlign:'center', textTransform:'uppercase'}}>{label}</div>
              </ButtonGearWrapper> :

              <ButtonWrapper
                className={colorClasses[i] ? colorClasses[i] : "no--img"}
                key={i}
                selected={(!value || value === undefined) && i === 0 ? true : val === value}
                onClick={(e) => {
                  if ((val === 'Pants') || (val === 'Jacket') || (val === 'Athletix') || (val === 'GX3') || (val === 'GX3 Coat') || (val === 'GPS Pant') ) {
                    localStorage.setItem('gearSelected', val);
                  }
                  localStorage.setItem('isClickEvent', 'true');
                  onChange(val)
                }}
              >
                <div >
                  <img src={THUMBNAIL_COLORMAP[label] || icnImgMissing} alt={label} />
                </div>
                <div className={label} style={{textAlign:'center'}}>{label}</div>
              </ButtonWrapper>
            }
          </div>

        ))}
      </ButtonsRow>
    </Wrapper>
  );
};

export default RadioButtonsImages;
