import React, { useEffect, useContext, useState } from 'react';
import ReactTooltip from "react-tooltip";

import { validate as isUuid } from 'uuid';
import { Wrapper, ButtonWrapper } from './radioButtons1.styles';
import { CheckCircleFilled } from '@ant-design/icons';
import { Tooltip } from 'antd';
import inputs from "../../../../config/gXtreme/inputs.js";
import Provider from '../../../../provider/provider';
import { AppContext } from '../../../../provider/provider';

import supplierTenCate from  "../../../../assets/tencate-logo.png";
import supplierWLGore from "../../../../assets/wl-gore-logo.png";

let gXmaterials = [ "Pioneer", "Agility", "Kombat Flex", "PBI Max", "Peak 5", "PBI Max LP", "Armor AP", "Caldura Elite", "Quantum", "Defender (NP Brass)", "Defender (Camo)", "Titanium SL2", "Titanium w/Nomex Nano", "Parallon X1M2", "Crosstech Black", "Parallon B2" ];
const suppliers = {
  "Pioneer" : supplierTenCate,
  "Agility" : supplierTenCate,
  "Kombat Flex" : supplierTenCate,
  "PBI Max" : supplierTenCate,
  "Peak 5" : supplierTenCate,
  "PBI Max LP" : supplierTenCate,
  "Armor AP" : supplierTenCate,

  "Caldura Elite" : supplierTenCate,
  "Quantum" : supplierTenCate,
  "Defender (NP Brass)" : supplierTenCate,
  "Defender (Camo)" : supplierTenCate,
  "Titanium SL2" : supplierTenCate,
  "Titanium w/Nomex Nano" : supplierTenCate,
  "Parallon X1M2" : supplierWLGore,
  "Crosstech Black" : supplierWLGore,
  "Parallon B2" : supplierWLGore,
}
const RadioButtons1 = ({ tooltipContent, inputLabel, options, value, onChange, disabled, cssClass }) => {
  const {state} = useContext(AppContext);

  useEffect(() => {
    if (window.location.pathname === "/gXtreme") {
      if (inputLabel == 'Materials' && value == 'Agility'){
        document.querySelector('.Black').innerHTML = 'Black Gold';
      }
      else if(inputLabel == 'Materials' && value !== 'Agility'){
        document.querySelector('.Black').innerHTML = 'Black';
      }
     
      if(value == 'HK/D IN VLC OUT GX3'){
        document.getElementById('zipper-pull').style.display = 'none';
      }else if(value == '*VISLON ZIP IN-VLC OUT' || value == 'TC VISZIP IN VLCOUT'){
        document.getElementById('zipper-pull').style.display = 'block';
      }
      if(value === 'ESCAPE BLT'){
        window.threekitApi.configurator.setConfiguration({'PANT_FRONT_FRONTCLOSURE_BELT_LOOPS': 'BLTLPS WD GPS'})
        document.getElementById('*nomex-blt-tunnel').style.display = 'none';
        document.getElementById('bltlps-wd-gps').classList.add('gHfsxy');
        document.getElementById('bltlps-wd-gps').classList.remove('eLmVGq');
      }
      else if(value === 'BLK NOMEX BLT GPS'){
        document.getElementById('*nomex-blt-tunnel').style.display = 'block';
      }
      if (inputLabel == "Right Pockets" && value === "None" || inputLabel == "Right Pockets" && value === "NONE") {
        document.querySelector(".right-antenna-notch").style.display = "none";
        document.querySelector(".right-holder").style.display = "block";
        document.querySelector(".right-antenna-notch").style.display = "none";
      }
      else if (inputLabel == "Right Pockets" && value !== "None" || inputLabel == "Right Pockets" && value !== "NONE") {
        document.querySelector(".right-antenna-notch").style.display = "block";
        document.querySelector(".right-holder").style.display = "none";
      }
      else if (inputLabel == "Left Pockets" && value === "None" || inputLabel == "Left Pockets" && value === "NONE") {
        document.querySelector(".left-antenna-notch").style.display = "none";
        document.querySelector(".left-holders").style.display = "block";

      }
      else if (inputLabel == "Left Pockets" && value !== "None" || inputLabel == "Left Pockets" && value !== "NONE") {
        document.querySelector(".left-antenna-notch").style.display = "block";
        document.querySelector(".left-holders").style.display = "none";
      }
      else if (inputLabel == "Holders" && value !== "NONE" && cssClass == "right-front-storm") {
        window.threekitApi.configurator.setConfiguration({"RIGHT POCKETS": "NONE"})
      }
      else if(inputLabel == "Right Holder" && value === "None" || inputLabel == "Right Holder" && value === "NONE"){
        document.querySelector(".right-pockets").style.display = "block";
        document.querySelector('.right-holder-microphone').style.display = 'block'
      }
      else if(inputLabel == "Right Holder" && value !== "None" || inputLabel == "Right Holder" && value !== "NONE"){
        document.querySelector(".right-pockets").style.display = "none";
        document.querySelector('.right-holder-microphone').style.display = 'none'
      }
      else if(inputLabel == "Left Holders" && value == "None" || inputLabel == "Left Holders" && value == "NONE"){
        document.querySelector(".left-pockets").style.display = "block";
        document.querySelector('.left-holder-microphone').style.display = 'block'
      }
      else if(inputLabel == "Left Holders" && value !== "None" || inputLabel == "Left Holders" && value !== "NONE"){
        document.querySelector(".left-pockets").style.display = "none";
        document.querySelector('.left-holder-microphone').style.display = 'none'
      }
      else if (inputLabel == "Holders" && value !== "NONE" && cssClass == "left-front-storm" ) {
        window.gurator.setConfiguration({"LEFT POCKETS": "NONE"});

      }
      else if(inputLabel == "PATCH" && value !== "NONE"){
        document.querySelector(".patch").parentElement.parentElement.style.borderBottom = "block";
        document.querySelector(".letters-text").style.display = "block";
        document.querySelector(".letters-text").parentElement.previousSibling.previousSibling.previousSibling.style.display = "block";
        document.querySelector(".letters-text").parentElement.parentElement.style.borderBottom = "1px solid #d3d4d3;";

      }
      else if(inputLabel == "PATCH" && value == "NONE"){
        document.querySelector(".patch").parentElement.parentElement.style.borderBottom = "block";
        document.querySelector(".letters-text").parentElement.parentElement.style.borderBottom = "none";
        document.querySelector(".letters-text").parentElement.previousSibling.previousSibling.previousSibling.style.display = "none";
        document.querySelector(".letters-text").style.display = "none"
      }
      else if(inputLabel == "Right Flags" && value == "NONE" || inputLabel == "Right Flags" && value == "None"){
        document.querySelector(".right-flags").style.display = "block";
        document.querySelector(".right-custom-patches").style.display = "block";
      }
      else if(inputLabel == "Left Flags" && value == "NONE" || inputLabel == "Left Flags" && value == "None"){
        document.querySelector(".left-custom-patches").style.display = "block";
        document.querySelector(".left-flags").style.display = "block";
      }
      else if(inputLabel == 'Right Custom Patches' && value == 'None'){
        document.querySelector('.input-component.right-patch-padding').style.display = 'none';
      }
      else if(inputLabel == 'Right Custom Patches' && value !== 'None'){
        document.querySelector('.input-component.right-patch-padding').style.display = 'block';
      }
      if(inputLabel == 'Left Custom Patches' && value == 'None'){
        document.querySelector('.input-component.left-patch-padding').style.display = 'none';
      }
      else if(inputLabel == 'Left Custom Patches' && value !== 'None'){
        document.querySelector('.input-component.left-patch-padding').style.display = 'block';
      }
      else if(inputLabel == "Materials"){
        if(!value){value = "Pioneer"}
        let imagesToNotVisible = document.querySelectorAll(".gx3__non-visible");
        for(let a = 0; a < imagesToNotVisible.length; a++){
          imagesToNotVisible[a].style.display = "none"
        }
        let colorMaterialCombinations = {
          "Pioneer":["img--black", "img--yellow", "img--tan", "img--gold"],
          "Agility":["img--black", "img--gold", "img--dk-gold"],
          "Kombat Flex": ["img--black", "img--gold"],
          "PBI Max":["img--black", "img--gold"],
          "Peak 5":["img--black", "img--gold"],
          "PBI Max LP":["img--black", "img--gold"],
          "Armor AP":["img--black",  "img--tan", "img--gold"]
        };
        for(let b = 0; b < colorMaterialCombinations[value].length; b++){
          document.querySelector(`.${colorMaterialCombinations[value][b]}`).style.display = "block"
        }
      }
    }
    else if(window.location.pathname === "/athletix"){
      let rowD = document.querySelector('.d-letters-text');
      if(rowD){
        rowD.children[0].innerHTML = 'ROW D LETTERING (NOT AVAILABLE)';
        rowD.children[1].style.display = 'none';
      }
    }
  })
  if (!options || options.length === 0) return null;

  return (
    <Wrapper className="inputs-container">
      {options.map(({ selected, label, value: val }, i) => {
        let isSelected = (!value || value === '') && i === 0 ? true : isUuid(val) ? val === value?.assetId : val === value;
        if(inputLabel == 'Thermal Liner' || inputLabel == 'Moisture Barrier'){
          if(state.thermalLinerOpt == val){
            isSelected = true;
          }else if(inputLabel == 'Thermal Liner' && state.thermalLinerOpt !== val && state.thermaLinerParallonClicked){
            isSelected = false;
          }
          if(state.moistureBarrierOpt == val){
            isSelected = true;
          }else if(inputLabel == "Moisture Barrier" && state.moistureBarrierOpt !== val && state.moistureBarrierParallonClicked){
            isSelected = false;
          }
        }
        if(inputLabel == 'Coat Trim Material' && val == 'Brilliance w/Stripe' && state.jacketBrillianceMaterialSelected){
          isSelected = true;
        }else if(inputLabel == 'Pant Trim Material' && val == 'Brilliance w/Stripe' && state.pantBrillianceMaterialSelected){
          isSelected = true;
        }
        return (
          <ButtonWrapper
            className='options-item'
            data-for={`${label}'`}
            data-tip={i}
            key={i}
            onClick={() => onChange(val)}
            selected={isSelected}
            disabled={!!disabled}
            id={`${label.split(' ').join('-').replaceAll('(', '').replaceAll(')', '').toLowerCase().replaceAll('"', '').replaceAll('/', '-')}`}
          >
            <span>{isSelected ? <CheckCircleFilled /> : <i className="fa fa-circle-o" aria-hidden="true" style={{ color: 'lightgrey' }}></i>}</span>
            {label}
            { gXmaterials.includes(label) && tooltipContent[label] ?
                <ReactTooltip
                  id={`${label}'`}
                  effect='solid'
                  delayHide={200}
                  delayShow={200}
                  place={'right'}
                  border={true}
                  type={'light'}>
                  <div style={{ maxWidth: '350px' }}>
                    <img style={{ width: '150px' }} src={suppliers[label]} alt={label} />
                    <p style={{ fontWeight: 'bold' }}>{ label }</p>
                    <p style={{ textTransform: 'initial' }}>{tooltipContent[label].tooltipDescription}</p>
                  </div>
                </ReactTooltip> : ''
            }
          </ButtonWrapper>
        )
      })}
    </Wrapper>
  );
};

export default RadioButtons1;
